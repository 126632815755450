import React from 'react';
import { Button } from '@mui/material';

const CustomButton = ({
  buttonText,
  buttonVariant,
  customButtonStyle = {},
  onClickHandler,
  disabled = false,
  buttonType = null,
  startIcon = null,
  endIcon = null,
}) => {
  return (
    <Button
      aria-label="buttonText"
      type={buttonType}
      variant={buttonVariant || 'contained'}
      onClick={onClickHandler}
      disabled={disabled}
      style={{
        padding: '8px 16px',
        boxSizing: 'border-box',
        font: 'normal normal medium 14px/16px Roboto',
        background: '#0B1E36',
        color: '#FFFFFF',
        letterSpacing: '1.25px',
        height: '35px',
        borderRadius: '4px',
        ...customButtonStyle,
      }}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {buttonText}
    </Button>
  );
};

export default CustomButton;
