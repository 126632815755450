/* eslint-disable max-len */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Checkbox,
  ListItemText,
  OutlinedInput,
  ListSubheader,
} from '@mui/material';

const VisualizationLibraryFilter = ({ data, setFilteredVisuals }) => {
  const donutChartTypes = ['donut-pie-sentiment', 'donut-pie-emotion'];
  const tableChartTypes = ['table'];

  const allDataSources = useMemo(() => {
    const sources = data
      .map((item) => item.chart_data?.data_source)
      .filter(Boolean);
    return [...new Set(sources)];
  }, [data]);

  const [selectedDataSources, setSelectedDataSources] = useState([]);
  const [selectedChartTypes, setSelectedChartTypes] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState({});

  useEffect(() => {
    setSelectedDataSources(allDataSources);
  }, [data]);

  const availableChartTypes = useMemo(() => {
    if (selectedDataSources.length === 0) return [];
    const chartTypes = data
      .filter((item) => {
        const ds = item.chart_data?.data_source;
        return ds ? selectedDataSources.includes(ds) : true;
      })
      .map((item) => item.chart_type);

    return [...new Set(chartTypes)];
  }, [selectedDataSources, data]);

  useEffect(() => {
    setSelectedChartTypes((prevSelected) => {
      const newSelected = availableChartTypes;

      if (
        newSelected.length === prevSelected.length
        && newSelected.every((v, i) => v === prevSelected[i])
      ) {
        return prevSelected; // No change
      }
      return newSelected;
    });
  }, [availableChartTypes, selectedDataSources]);

  const selectedDonutCharts = useMemo(
    () => selectedChartTypes.filter((chartType) => donutChartTypes.includes(chartType)),
    [selectedChartTypes],
  );

  const availableTopics = useMemo(() => {
    if (selectedDonutCharts.length === 0) return {};
    const topicsByChartType = {};
    selectedDonutCharts.forEach((chartType) => {
      const items = data.filter((item) => {
        const ds = item.chart_data?.data_source;
        const dsMatch = ds ? selectedDataSources.includes(ds) : true;
        return item.chart_type === chartType && dsMatch;
      });
      const topics = items.map((item) => item.topic).filter(Boolean);
      topicsByChartType[chartType] = [...new Set(topics)];
    });
    return topicsByChartType;
  }, [selectedDonutCharts, selectedDataSources, data]);

  useEffect(() => {
    setSelectedTopics((prevSelected) => {
      const newSelected = { ...prevSelected };
      let changed = false;

      Object.keys(availableTopics).forEach((chartType) => {
        const oldTopics = prevSelected[chartType] || [];
        const filtered = oldTopics.filter((topic) => availableTopics[chartType].includes(topic));
        const finalTopics = filtered.length > 0 ? filtered : [...availableTopics[chartType]];

        if (
          finalTopics.length !== oldTopics.length
          || !finalTopics.every((t, i) => t === oldTopics[i])
        ) {
          newSelected[chartType] = finalTopics;
          changed = true;
        }
      });

      return changed ? newSelected : prevSelected;
    });
  }, [availableTopics]);

  const isTableChartSelected = useMemo(
    () => selectedChartTypes.some((chartType) => tableChartTypes.includes(chartType)),
    [selectedChartTypes],
  );

  const handleDataSourceChange = (event) => {
    const { value } = event.target;
    setSelectedDataSources(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChartTypeChange = (event) => {
    const { value } = event.target;
    setSelectedChartTypes(typeof value === 'string' ? value.split(',') : value);
  };

  const handleTopicCheckboxChange = (chartType, topic) => (event) => {
    const { checked } = event.target;
    setSelectedTopics((prevTopics) => {
      const prevSelected = prevTopics[chartType] || [];
      const newSelected = checked
        ? [...prevSelected, topic]
        : prevSelected.filter((t) => t !== topic);

      // Only set if actually changed
      if (
        newSelected.length === prevSelected.length
        && newSelected.every((v, i) => v === prevSelected[i])
      ) {
        return prevTopics;
      }

      return { ...prevTopics, [chartType]: newSelected };
    });
  };

  const handleFilter = () => {
    const filteredData = data
      .filter((item) => {
        const ds = item.chart_data?.data_source;
        return ds ? selectedDataSources.includes(ds) : true;
      })
      .filter((item) => selectedChartTypes.includes(item.chart_type))
      .filter((item) => {
        if (donutChartTypes.includes(item.chart_type)) {
          const selectedTopicsForChart = selectedTopics[item.chart_type] || [];
          if (selectedTopicsForChart.length > 0 && item.topic) {
            return selectedTopicsForChart.includes(item.topic);
          }
        }
        return true;
      });

    setFilteredVisuals(filteredData);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: { maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP, width: 200 },
    },
  };

  const menuItemStyles = {
    padding: '0 4px',
    '&.Mui-selected': { backgroundColor: 'transparent !important' },
    '&.Mui-selected:hover': { backgroundColor: 'transparent !important' },
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
  };

  const listItemTextStyles = {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    fontSize: '16px',
    lineHeight: 'normal',
  };

  const isTopicsEnabled = selectedDonutCharts.length > 0;

  const isFilterButtonDisabled = selectedDataSources.length === 0
    || selectedChartTypes.length === 0
    || (isTopicsEnabled
      && Object.keys(availableTopics).length > 0
      && Object.keys(availableTopics).some(
        (chartType) => !selectedTopics[chartType] || selectedTopics[chartType].length === 0,
      ));

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
        width: '-webkit-fill-available',
        padding: '16px',
        borderRadius: '8px 8px 0 0',
        marginBottom: '24px',
        boxShadow: '0px 3px 6px 0px #00000029',
        backgroundColor: '#FFFFFF',
      }}
    >
      {/* Data Source */}
      <FormControl sx={{ minWidth: '140px', width: '25%' }} size="small">
        <InputLabel>Data Source</InputLabel>
        <Select
          multiple
          value={selectedDataSources}
          onChange={handleDataSourceChange}
          input={<OutlinedInput label="Data Source" />}
          renderValue={(selected) => `${selected[0].slice(0, 12)}, ...`}
          MenuProps={MenuProps}
        >
          {allDataSources.map((source) => (
            <MenuItem key={source} value={source} sx={menuItemStyles}>
              <Checkbox checked={selectedDataSources.includes(source)} />
              <ListItemText primary={source} primaryTypographyProps={{ style: listItemTextStyles }} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Chart Type */}
      <FormControl
        sx={{ minWidth: '140px', width: '25%' }}
        size="small"
        disabled={selectedDataSources.length === 0}
      >
        <InputLabel>Chart Type</InputLabel>
        <Select
          multiple
          value={selectedChartTypes}
          onChange={handleChartTypeChange}
          input={<OutlinedInput label="Chart Type" />}
          renderValue={(selected) => `${selected[0].slice(0, 12)}, ...`}
          MenuProps={MenuProps}
        >
          {availableChartTypes.map((chartType) => (
            <MenuItem key={chartType} value={chartType} sx={menuItemStyles}>
              <Checkbox checked={selectedChartTypes.includes(chartType)} />
              <ListItemText
                primary={chartType}
                primaryTypographyProps={{ style: listItemTextStyles }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Topics */}
      <FormControl
        sx={{ minWidth: '140px', width: '25%' }}
        size="small"
        disabled={!isTopicsEnabled || Object.keys(availableTopics).length === 0}
      >
        <InputLabel>Topics</InputLabel>
        <Select
          multiple
          input={<OutlinedInput label="Topics" />}
          renderValue={(selected) => `${selected[0].slice(0, 12)}, ...`}
          MenuProps={MenuProps}
          value={Object.values(selectedTopics).flat()}
        >
          <Box>
            {Object.keys(availableTopics).map((chartType) => [
              <ListSubheader key={`${chartType}-header`} disableSticky>
                {chartType}
              </ListSubheader>,
              <MenuItem
                key={`${chartType}-select-all`}
                value="all"
                sx={menuItemStyles}
                onClick={(event) => {
                  event.stopPropagation();
                  const allSelected = selectedTopics[chartType]?.length === availableTopics[chartType].length;
                  const newSelectedTopics = allSelected
                    ? []
                    : [...availableTopics[chartType]];

                  setSelectedTopics((prevTopics) => ({
                    ...prevTopics,
                    [chartType]: newSelectedTopics,
                  }));
                }}
              >
                <Checkbox
                  checked={
                    selectedTopics[chartType]?.length === availableTopics[chartType].length
                  }
                  indeterminate={
                    selectedTopics[chartType]?.length > 0
                    && selectedTopics[chartType].length < availableTopics[chartType].length
                  }
                />
                <ListItemText
                  primary="Select All"
                  primaryTypographyProps={{
                    style: { fontSize: '16px', lineHeight: 'normal' },
                  }}
                />
              </MenuItem>,
              ...availableTopics[chartType].map((topic) => (
                <MenuItem
                  key={`${chartType}-${topic}`}
                  value={topic}
                  onClick={(event) => event.stopPropagation()}
                  sx={{ ...menuItemStyles, backgroundColor: 'white' }}
                >
                  <Checkbox
                    checked={selectedTopics[chartType]?.includes(topic) || false}
                    onChange={handleTopicCheckboxChange(chartType, topic)}
                    value={topic}
                  />
                  <ListItemText
                    primary={topic}
                    primaryTypographyProps={{ style: listItemTextStyles }}
                  />
                </MenuItem>
              )),
            ])}
          </Box>
        </Select>
      </FormControl>

      {/* Filter Button */}
      <Button
        variant="contained"
        color="primary"
        size="small"
        sx={{
          width: '25%',
          padding: '8px 24px',
          fontWeight: 'bold',
          borderColor: '#3988C6',
          backgroundColor: '#3988C6',
          '&:hover': {
            borderColor: '#3988C6',
            backgroundColor: '#3988C6',
          },
        }}
        onClick={handleFilter}
        disabled={isFilterButtonDisabled}
      >
        FILTER
      </Button>
    </Box>
  );
};

export default VisualizationLibraryFilter;
