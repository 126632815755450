import './App.css';
import React, { useEffect, useState } from 'react';
import {
  Routes, Route, Navigate, useLocation,
} from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { Alert } from '@mui/material';
import Layout from './layout/Layout';
import Dashboard from './pages/Dashboard';
import ReportData from './pages/ReportData';
import Login from './pages/Login';
import PrivateRoute from './Auth/PrivateRoute';
import { useAuth } from './hooks/useAuth';
import ForgotPassword from './Auth/ForgotPassword';
import UserPool from './Auth/UserPool';
import ReportAnalysis from './pages/ReportAnalysis';

const App = () => {
  const location = useLocation();
  const { authDetails, handleLogoutSuccess } = useAuth();
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (authDetails?.isAuthenticated) {
      const token_exp_at = jwt_decode(authDetails?.idToken)?.exp;
      const current_time = new Date().getTime();
      if (token_exp_at * 1000 < current_time) {
        const cognitoUser = UserPool.getCurrentUser();
        if (cognitoUser) {
          cognitoUser.signOut();
        }
        handleLogoutSuccess();
        setSessionExpired(true);
        setTimeout(() => {
          setSessionExpired(false);
        }, 4000);
      }
    }
  }, [location, authDetails, handleLogoutSuccess]);

  return (
    <div className="App">
      {sessionExpired && (
        <Alert
          severity="error"
          style={{
            position: 'absolute',
            left: '40%',
            zIndex: '10',
            width: '20%',
            top: '10px',
            textAlign: 'center',
          }}
        >
          Session Expired!
        </Alert>
      )}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            path="login"
            element={
              authDetails?.isAuthenticated ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="forgot-password"
            element={
              authDetails?.isAuthenticated ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <ForgotPassword />
              )
            }
          />
          <Route
            index
            element={(
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            )}
          />
          <Route
            path="dashboard"
            element={(
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            )}
          />
          <Route
            path="client-data/:client_id?"
            element={(
              <PrivateRoute>
                <ReportData />
              </PrivateRoute>
            )}
          />
          <Route
            path="client-data/:client_id/report-analysis/:report_id/:analysis_name"
            element={(
              <PrivateRoute>
                <ReportAnalysis />
              </PrivateRoute>
            )}
          />
        </Route>
        <Route
          path="*"
          element={(
            <main style={{ padding: '10rem', textAlign: 'center' }}>
              <h1>Page Not Found!</h1>
            </main>
          )}
        />
      </Routes>
    </div>
  );
};

export default App;
