import React from 'react';
import { Box } from '@mui/material';
import {
  NivoPieChart, NivoGroupedBarChart, NivoHeatmap, NivoStackedBarChart,
} from './nivoComponent';
import NivoSingleBarChart from './nivoComponent/NivoSingleBarChart';
import {
  createGroupedBarChartConfig,
  createHeatmapConfig,
  createPieChartConfig,
  createSingleBarChartConfig,
  createStackedBarChartConfig,
} from './common/chartsCustomConfig';
import { separateKeysAndColorsForStackedBar } from '../../utils/commonFunctions';

const RenderChart = ({ chartData, chart_type, customizations = {} }) => {
  const data = chartData?.data || [];

  switch (chart_type) {
    case 'bar-topic':
    case 'bar-count': {
      const indexBy = chart_type === 'bar-topic' ? 'topic' : 'sentiment';
      const keys = chart_type === 'bar-topic' ? ['sentiment'] : ['count'];
      const customConfig = createSingleBarChartConfig(chartData, customizations);

      return (
        <Box sx={{
          width: '90%', height: '380px', display: 'flex', justifySelf: 'center',
        }}
        >
          <NivoSingleBarChart
            customConfig={customConfig}
            indexBy={indexBy}
            keys={keys}
          />
        </Box>
      );
    }
    case 'stacked': {
      const { keys, colors } = separateKeysAndColorsForStackedBar(chartData?.data);
      const customConfig = createStackedBarChartConfig(chartData, customizations);
      return (
        <Box sx={{
          width: '90%', height: '380px', display: 'flex', justifySelf: 'center',
        }}
        >
          <NivoStackedBarChart
            customConfig={customConfig}
            indexBy="filename"
            keys={keys}
            colors={colors}
          />
        </Box>
      );
    }

    case 'grouped': {
      const indexBy = 'topic';
      const keys = Object.keys(data[0] || {}).filter((key) => key !== indexBy);
      const customConfig = createGroupedBarChartConfig(chartData, customizations);
      return (
        <Box sx={{
          width: '90%', height: '380px', display: 'flex', justifySelf: 'center',
        }}
        >
          <NivoGroupedBarChart
            customConfig={customConfig}
            keys={keys}
            chart_type={chart_type}
            indexBy={indexBy}
          />
        </Box>
      );
    }

    case 'heatmap-intensity':
    case 'heatmap-correlation': {
      const customConfig = createHeatmapConfig(chartData, customizations);
      return (
        <Box sx={{
          width: '100%', height: '380px', display: 'flex', justifySelf: 'center', padding: '8px', boxSizing: 'border-box',
        }}
        >
          <NivoHeatmap
            customConfig={customConfig}
            data={chartData.data}
            data_source={chartData.data_source}
          />
        </Box>
      );
    }

    case 'donut-pie-sentiment':
    case 'donut-pie-emotion': {
      const customConfig = createPieChartConfig(chartData, customizations);
      return (
        <Box sx={{
          width: '100%', height: '380px', display: 'flex', justifySelf: 'center', padding: '8px', boxSizing: 'border-box',
        }}
        >
          <NivoPieChart
            customConfig={customConfig}
            data={data}
            chart_type={chart_type}
          />
        </Box>
      );
    }

    default:
      // Handle unsupported chart types
      return (
        <p>
          Unsupported chart type:
          {chart_type}
        </p>
      );
  }
};

export default RenderChart;
