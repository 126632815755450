const useDownloadCsv = () => {
  const downloadCsv = (data, filename = 'download.csv', description = '') => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      console.error('Invalid data format for CSV download');
      return;
    }

    try {
      // Get headers from the first object's keys
      const headers = Object.keys(data[0]);

      // Create CSV content
      const csvContent = [
        headers.join(','), // Header row
        ...data.map((row) => headers.map((header) => {
          // Handle values that need escaping (contains commas, quotes, or newlines)
          const value = row[header]?.toString() ?? '';
          if (value.includes(',') || value.includes('"') || value.includes('\n')) {
            return `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        }).join(',')),
      ].join('\n');

      // Create blob and download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  return { downloadCsv };
};

export default useDownloadCsv;
