/* eslint-disable max-len */
import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import VisualCard from './VisualCard';
import RenderChart from '../charts/RenderChart';
import DynamicTable from '../charts/nivoComponent/DynamicTable';
import VisualizationLibraryFilter from '../charts/common/VisualizationLibraryFilter';
import AddCustomVisualization from './AddCustomVisualization';
import VisualLoader from '../charts/common/VisualLoader';
import { AddchartOutlinedIcon } from '../../icons';

const CustomVisualizationLibrary = ({ visualsData, isFetching }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const visualId = params.get('visual_id');

  const [filteredVisuals, setFilteredVisuals] = useState(visualsData);

  useEffect(() => {
    if (visualId) {
      setFilteredVisuals(visualsData.filter((visual) => visual.visual_id === visualId));
    } else {
      setFilteredVisuals(visualsData);
    }
  }, [visualId, visualsData]);

  if (isFetching) return <VisualLoader message="Loading custom visuals..." />;

  return (
    <Box>
      {visualsData.length === 0 ? (
        <Box
          sx={{
            backgroundColor: '#fff',
            width: '100%',
            boxShadow: '0px 3px 6px 0px #00000029',
            boxSizing: 'border-box',
            padding: '40px 24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              margin: '1.5rem 0',
            }}
          >
            <AddchartOutlinedIcon
              sx={{
                fontSize: '40px',
                color: '#A0A0A0',
                mb: 1,
              }}
            />
            <Typography
              sx={{
                color: '#00000099',
                fontSize: '16px',
              }}
            >
              There are no custom visualizations for this analysis.
              <br />
              You can customize a default visualization, or add a new custom visualization using the menu to the left.
            </Typography>
          </Box>
        </Box>
      ) : visualId ? (
        <>
          <Typography
            variant="h4"
            fontWeight="600"
            style={{ margin: '0 0 1rem 0', fontSize: '16px' }}
          >
            Edit Visualization:
            {' '}
            <Box component="span" sx={{ fontWeight: 400, fontStyle: 'italic' }}>
              {filteredVisuals[0]?.chart_data?.chart_title}
            </Box>
          </Typography>
          <AddCustomVisualization visualsData={filteredVisuals} isEditing />
        </>
      ) : (
        <>
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
          >
            <VisualizationLibraryFilter
              data={visualsData}
              setFilteredVisuals={setFilteredVisuals}
            />
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            width="100%"
          >
            {filteredVisuals.map((visual) => {
              const {
                chart_type, chart_data, pinned, visual_id, report_id, customizations,
              } = visual;

              if (!chart_data) return null;
              const { chart_title, description } = chart_data;
              const custom_chart_title = customizations?.generalSettings?.title || chart_title;
              const font_family = customizations?.generalSettings?.font;

              return (
                <VisualCard
                  key={visual_id}
                  title={custom_chart_title}
                  isSelected={false}
                  type="custom"
                  chart_type={chart_type}
                  report_id={report_id}
                  visual_id={visual_id}
                  pinned={pinned}
                  font_family={font_family}
                  chart_data={chart_data}
                  chart_description={description}
                >
                  {chart_type === 'table' ? (
                    <DynamicTable chartData={visual.chart_data} custom_table_data={visual.customizations?.custom_table_data} />
                  ) : (
                    <RenderChart
                      chartData={visual.chart_data}
                      chart_type={chart_type}
                      customizations={customizations}
                    />
                  )}
                </VisualCard>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};

export default CustomVisualizationLibrary;
