import React, { useState } from 'react';
import { positions, styled, textAlign } from '@mui/system';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import {
  Typography, useTheme, Box, CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../button/CustomButton';
import { isClientAlreadyExist, validateClientName } from '../../utils/commonFunctions';
import CustomTooltip from '../customMuiComponent/CustomTooltip';
import { useCreateNewClientMutation } from '../../services/api/clientsApi';
import { InfoIcon } from '../../icons';

const CustomDialogBox = styled(Dialog)({
  '.MuiDialog-paperWidthSm': {
    margin: 0,
    width: '420px',
    height: '280px',
    padding: '45px 46px',
    boxSizing: 'border-box',
    borderRadius: '0',
    textAlign: 'left',
  },
  '.Mui-error': {
    marginLeft: '0',
  },
});

const AddClientDialog = ({ open, setOpen, allClientData }) => {
  const theme = useTheme();
  const [newClientName, setNewClientName] = useState('');
  const [inputError, setInputError] = useState({
    error: false,
    error_message: '',
  });
  const navigate = useNavigate();
  const [createNewClient, { isLoading, isError, isSuccess }] = useCreateNewClientMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateClient = async () => {
    // Check if the client already exists
    if (isClientAlreadyExist(allClientData, newClientName)) {
      setInputError({ error: true, error_message: 'Client already exists' });
      return;
    }

    try {
      const response = await createNewClient(newClientName).unwrap();
      const client_id = response?.data?.client_id;
      if (client_id) {
        // navigate(`/client-data/${client_id}`);
        setOpen(false);
        setInputError({ error: false, error_message: '' });
      } else {
        console.log(
          'Something went wrong creating the client. Please try again later.',
        );
      }
    } catch (error) {
      console.error('Error creating client:', error);
    }
  };

  const handleNameChange = (e) => {
    setNewClientName(e.target.value);
    validateClientName(e.target.value, setInputError);
  };

  return (
    <CustomDialogBox
      open={open}
      onClose={() => (_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      disableEscapeKeyDown
    >
      <Box
        style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}
      >
        <Typography
          color={theme.palette.primary.main}
          variant="h2"
          fontWeight="bold"
        >
          Add New Client
        </Typography>
        <CustomTooltip
          title="Valid Client Name Rule"
          message={[
            'Must have at least 3 characters.',
            'Must have less than 40 characters.',
            'Only letters and periods (.) are allowed.',
          ]}
          placement="left-start"
        >
          <InfoIcon
            style={{
              display: 'inline-block',
              marginLeft: '1rem',
              color: 'gray',
            }}
          />
        </CustomTooltip>
      </Box>
      <Box sx={{ position: 'relative', width: '328px' }}>
        <TextField
          margin="dense"
          placeholder="Client Name"
          type="text"
          error={inputError?.error}
          helperText={inputError?.error_message}
          variant="outlined"
          required
          fullWidth
          onChange={handleNameChange}
          sx={{
            '& .MuiFormHelperText-root': {
              position: 'absolute',
              bottom: '-20px',
              left: '0px',
              marginLeft: '0px',
              color: inputError?.error ? 'red' : 'inherit',
            },
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: '1.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 0 0.5rem 0',
        }}
      >
        <CustomButton
          onClickHandler={handleCreateClient}
          buttonText="Create Client"
          disabled={inputError?.error || newClientName === '' || isLoading}
          customButtonStyle={
            inputError?.error || newClientName === '' || isLoading
              ? {
                opacity: '0.5',
                background: theme.palette.accent.red,
              }
              : {
                background: theme.palette.accent.red,
                color: 'white',
              }
          }
        />
        <CustomButton
          onClickHandler={handleClose}
          buttonText="Cancel"
          buttonVariant="outlined"
          customButtonStyle={{
            background: 'white',
            color: 'black',
            opacity: isLoading ? 0.6 : 1,
          }}
          disabled={isLoading}
        />
      </Box>
      {isLoading && (
        <Box style={{ textAlign: 'center', marginTop: '0.25rem' }}>
          <CircularProgress />
        </Box>
      )}
      {isError && (
        <Typography style={{ color: theme.palette.accent.red }}>
          Issue creating a client
        </Typography>
      )}
    </CustomDialogBox>
  );
};

export default AddClientDialog;
