// Update the client data in frontend when a file is uploaded
const updateInputDataAfterFileUpload = (clientInputData, file_s3_url, file_name, files_data_id, file_status, status_message = '') => {
  const clientInputDataCopy = JSON.parse(JSON.stringify(clientInputData));

  const fileObj = {
    file_name,
    file_s3_url,
    file_status,
    status_message,
  };

  if (files_data_id in clientInputDataCopy) {
    clientInputDataCopy[files_data_id] = [...clientInputDataCopy[files_data_id], fileObj];
  } else {
    clientInputDataCopy[files_data_id] = [fileObj];
  }
  return clientInputDataCopy;
};

// Update the client data in frontend when a file is deleted
const updateInputDataAfterFileDelete = (clientInputData, file_s3_url, files_data_id) => {
  const updatedClientInputData = { ...clientInputData };

  if (files_data_id in updatedClientInputData) {
    updatedClientInputData[files_data_id] = [...updatedClientInputData[files_data_id].filter(
      (file) => file.file_s3_url !== file_s3_url,
    ),
    ];
  }
  return updatedClientInputData;
};

// Updated uploading file array whose status is either 'uploading' or 'failed'
const updateUploadingFiles = (newFile, uploadingFiles) => {
  const uploadingFilesCopy = JSON.parse(JSON.stringify(uploadingFiles));
  const indexofNewFile = uploadingFilesCopy?.findIndex((file) => file.fileUrl === newFile.fileUrl);
  if (indexofNewFile === -1) {
    uploadingFilesCopy.push(newFile);
  } else {
    uploadingFilesCopy[indexofNewFile] = newFile;
  }
  return uploadingFilesCopy;
};

//  uploading files status update
const updateFileStatus = (updatedFile, uploadingFiles) => {
  const uploadingFilesCopy = JSON.parse(JSON.stringify(uploadingFiles));
  const indexOfCurrentFile = uploadingFilesCopy.findIndex(
    (file) => file.titleId === updatedFile.titleId,
  );
  uploadingFilesCopy[indexOfCurrentFile] = updatedFile;
  return uploadingFilesCopy;
};

export {
  updateInputDataAfterFileUpload,
  updateInputDataAfterFileDelete,
  updateUploadingFiles,
  updateFileStatus,
};
