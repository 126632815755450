import React, { useState, useEffect } from 'react';
import {
  Grid, Typography, TextField, Button,
} from '@mui/material';
import {
  isClientAlreadyExist,
  validateClientName,
} from '../../utils/commonFunctions';
import {
  useGetAllClientsQuery,
  useUpdateClientDetailsMutation,
} from '../../services/api/clientsApi';
import { EditOutlinedIcon } from '../../icons/index';

const EditableClientName = ({ client_id, client_data }) => {
  const [isNameEditing, setIsNameEditing] = useState(false);
  const [updateClientDetails] = useUpdateClientDetailsMutation();
  const { data: allClients } = useGetAllClientsQuery();
  const [updatedName, setUpdatedName] = useState(
    client_data?.client_name || '',
  );
  const [inputError, setInputError] = useState({
    error: false,
    error_message: '',
  });

  // Reset the updated name whenever the client data changes
  useEffect(() => {
    setUpdatedName(client_data?.client_name || '');
  }, [client_data, client_id]);

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setUpdatedName(newName);
    validateClientName(newName, setInputError);
  };

  const handleCancelEdit = () => {
    setIsNameEditing(false);
    setInputError({ error: false, error_message: '' });
    setUpdatedName(client_data?.client_name || '');
  };

  const handleNameEdit = async () => {
    if (inputError.error) return;

    if (isClientAlreadyExist(allClients?.data, updatedName)) {
      setInputError({ error: true, error_message: 'Client already exists' });
      return;
    }

    // Optimistic UI update: Assume the update will succeed
    const previousName = client_data?.client_name;
    setIsNameEditing(false);
    setUpdatedName(updatedName);

    try {
      await updateClientDetails({
        client_id,
        updatedClientData: { ...client_data, client_name: updatedName },
      }).unwrap();
    } catch (error) {
      console.error('Failed to update client details:', error);
      // Revert the optimistic update if the mutation fails
      setUpdatedName(previousName);
      setInputError({
        error: true,
        error_message: 'Failed to update client details. Please try again.',
      });
      setIsNameEditing(true);
    }
  };

  return (
    <Grid
      container
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Grid sx={{ display: 'flex', alignItems: 'center' }}>
        {isNameEditing ? (
          <>
            <TextField
              value={updatedName}
              variant="standard"
              error={inputError.error}
              helperText={inputError.error_message}
              onChange={handleNameChange}
              autoFocus
              sx={{ marginRight: '1rem' }}
            />
            <Button onClick={handleNameEdit} disabled={inputError.error}>
              Save
            </Button>
            <Button onClick={handleCancelEdit}>Cancel</Button>
          </>
        ) : (
          <>
            <Typography variant="h6" sx={{ minWidth: '140px' }}>
              {updatedName || 'Fetching Client Name...'}
            </Typography>
            <Button
              startIcon={<EditOutlinedIcon />}
              onClick={() => setIsNameEditing(true)}
              sx={{ marginLeft: '1rem', textTransform: 'none' }}
            >
              Edit client name
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default EditableClientName;
