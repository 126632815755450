import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import {
  ChartPreview,
  DataDisplaySettings, GeneralSettings, LayoutSettings, LegendSettings,
} from './common';
import FooterActions from './common/FooterActions';
import { NivoGroupedBarChart } from './nivoComponent';
import { createGroupedBarChartConfig, defaultDataColors } from './common/chartsCustomConfig';
import ColorSettings from './common/ColorSettings';

const CustomGroupedBarChart = ({ chartDataDetails, barType }) => {
  const {
    chart_data,
    customizations = {},
    visual_id,
    chart_id,
    report_id,
    chart_type,
  } = chartDataDetails;
  const indexBy = 'topic';
  const [customConfig, setCustomConfig] = useState(
    createGroupedBarChartConfig(chart_data, customizations),
  );
  useEffect(() => {
    const tempCustomConfig = createGroupedBarChartConfig(chart_data, customizations);
    setCustomConfig(tempCustomConfig);
  }, [customizations]);

  useEffect(() => {
    if (!customConfig.customData || customConfig.customData.length === 0) {
      if (chart_data.data && chart_data.data.length > 0) {
        setCustomConfig((prev) => ({
          ...prev,
          customData: chart_data.data,
        }));
      }
    }
  }, [chart_type, chart_data.data]);

  const handleGeneralSettingsChange = (key, value) => {
    if (key && value !== undefined) {
      setCustomConfig((prev) => ({
        ...prev,
        generalSettings: {
          ...prev.generalSettings,
          [key]: value,
        },
      }));
    }
  };

  const handleMarginChange = (side, value) => {
    setCustomConfig((prev) => ({
      ...prev,
      layout: { ...prev.layout, [side]: value },
    }));
  };

  const handleLegendChange = (key, value) => {
    setCustomConfig((prev) => ({
      ...prev,
      legends: { ...prev.legends, [key]: value },
    }));
  };

  const handleColorPaletteChange = (palette) => {
    setCustomConfig((prev) => ({
      ...prev,
      colorPalette: palette,
    }));
  };

  const keys = Object.keys(chart_data.data[0]).filter((key) => key !== indexBy);
  const font_family = customConfig.generalSettings.font;

  return (
    <Box>
      <ChartPreview
        chart_title={customConfig.generalSettings.title || chart_data.chart_title}
        data_dump={chart_data?.data_dump}
        font_family={font_family}
      >
        <NivoGroupedBarChart
          customConfig={customConfig}
          chart_type={barType}
          indexBy={indexBy}
          keys={keys}
        />
      </ChartPreview>
      <GeneralSettings
        chartType={barType}
        settings={{
          ...customConfig.generalSettings,
          title: customConfig.generalSettings.title || chart_data.chart_title,
        }}
        onChange={handleGeneralSettingsChange}
      />
      <LayoutSettings
        margins={customConfig.layout}
        onMarginChange={handleMarginChange}
      />
      <LegendSettings
        direction={customConfig.legends.direction}
        position={customConfig.legends.anchor}
        shape={customConfig.legends.symbolShape}
        offsetX={customConfig.legends.translateX}
        offsetY={customConfig.legends.translateY}
        symbolSize={customConfig.legends.symbolSize}
        itemWidth={customConfig.legends.itemWidth}
        itemHeight={customConfig.legends.itemHeight}
        onDirectionChange={(e) => handleLegendChange('direction', e.target.value)}
        onPositionChange={(e) => handleLegendChange('anchor', e.target.value)}
        onShapeChange={(e) => handleLegendChange('symbolShape', e.target.value)}
        onOffsetXChange={(value) => handleLegendChange('translateX', value)}
        onOffsetYChange={(value) => handleLegendChange('translateY', value)}
        onSymbolSizeChange={(value) => handleLegendChange('symbolSize', value)}
        onItemWidthChange={(value) => handleLegendChange('itemWidth', value)}
        onItemHeightChange={(value) => handleLegendChange('itemHeight', value)}
      />
      <ColorSettings
        numberOfColors={keys.length}
        onColorPaletteChange={handleColorPaletteChange}
        initialColorPalette={customizations.colorPalette}
      />
      <FooterActions
        visual_id={visual_id}
        report_id={report_id}
        updatedCustomizations={customConfig}
        chart_type={chart_type}
        chart_id={chart_id}
      />
    </Box>
  );
};

export default CustomGroupedBarChart;
