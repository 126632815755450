import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const PrivateRoute = ({ children }) => {
  const { authDetails } = useAuth();
  return (
    authDetails?.isAuthenticated ? children : <Navigate to="/login" />
  );
};

export default PrivateRoute;
