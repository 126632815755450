/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
  ChartPreview, GeneralSettings, LayoutSettings, LegendSettings,
} from './common';
import FooterActions from './common/FooterActions';
import { NivoPieChart } from './nivoComponent';
import { createPieChartConfig } from './common/chartsCustomConfig';
import ColorSettings from './common/ColorSettings';

const CustomPieChart = ({ chartDataDetails }) => {
  const {
    chart_data, customizations = {}, visual_id, chart_id, report_id, chart_type,
  } = chartDataDetails;
  const [customConfig, setCustomConfig] = useState(
    createPieChartConfig(chart_data, customizations),
  );

  useEffect(() => {
    const tempCustomConfig = createPieChartConfig(chart_data, customizations);
    setCustomConfig(tempCustomConfig);
  }, [customizations]);

  const handleGeneralSettingsChange = (key, value) => {
    if (key && value !== undefined) {
      setCustomConfig((prev) => ({
        ...prev,
        generalSettings: {
          ...prev.generalSettings,
          [key]: value,
        },
      }));
    }
  };

  const handleMarginChange = (side, value) => {
    setCustomConfig((prev) => ({
      ...prev,
      layout: { ...prev.layout, [side]: value },
    }));
  };

  const handleLegendChange = (key, value) => {
    setCustomConfig((prev) => ({
      ...prev,
      legends: { ...prev.legends, [key]: value },
    }));
  };

  const handleColorPaletteChange = (value) => {
    setCustomConfig((prev) => ({
      ...prev,
      colorPalette: value,
    }));
  };
  const font_family = customConfig.generalSettings.font;
  return (
    <Box>
      <ChartPreview
        chart_title={customConfig.generalSettings.title || chart_data.chart_title}
        data_dump={chart_data?.data_dump}
        font_family={font_family}
      >
        <NivoPieChart
          data={chart_data.data}
          customConfig={customConfig}
          chart_type={chart_type}
        />
      </ChartPreview>
      <GeneralSettings
        chartType="pie"
        settings={{
          ...customConfig.generalSettings,
          title: customConfig.generalSettings.title || chart_data.chart_title,
        }}
        onChange={handleGeneralSettingsChange}
      />
      <LayoutSettings
        margins={customConfig.layout}
        onMarginChange={handleMarginChange}
      />
      <LegendSettings
        direction={customConfig.legends.direction}
        position={customConfig.legends.anchor}
        shape={customConfig.legends.symbolShape}
        offsetX={customConfig.legends.translateX}
        offsetY={customConfig.legends.translateY}
        symbolSize={customConfig.legends.symbolSize}
        itemWidth={customConfig.legends.itemWidth}
        itemHeight={customConfig.legends.itemHeight}
        onDirectionChange={(e) => handleLegendChange('direction', e.target.value)}
        onPositionChange={(e) => handleLegendChange('anchor', e.target.value)}
        onShapeChange={(e) => handleLegendChange('symbolShape', e.target.value)}
        onOffsetXChange={(value) => handleLegendChange('translateX', value)}
        onOffsetYChange={(value) => handleLegendChange('translateY', value)}
        onSymbolSizeChange={(value) => handleLegendChange('symbolSize', value)}
        onItemWidthChange={(value) => handleLegendChange('itemWidth', value)}
        onItemHeightChange={(value) => handleLegendChange('itemHeight', value)}
      />
      <ColorSettings
        onColorPaletteChange={(value) => handleColorPaletteChange(value)}
        numberOfColors={chart_data.data.length}
        initialColorPalette={customizations.colorPalette}
      />
      <FooterActions
        visual_id={visual_id}
        updatedCustomizations={customConfig}
        chart_type={chart_type}
        chart_id={chart_id}
        report_id={report_id}
      />
    </Box>
  );
};

export default CustomPieChart;
