import React from 'react';
import {
  Grid, Typography, Box, Button,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import tivc_logo_5131_2x from '../assets/images/tivc_logo_5131_2x.png';
import UserPool from '../Auth/UserPool';
import { useAuth } from '../hooks/useAuth';

const TopBar = () => {
  const location = useLocation();
  const { authDetails, handleLogoutSuccess } = useAuth();

  const topNavOptions = [
    { title: 'Clients', pathName: '/dashboard' },
    { title: 'Data', pathName: '/client-data' },
  ];

  const getComputedNavStyle = (isActive) => ({
    fontWeight: 'bold',
    fontSize: '1rem',
    minWidth: '150px',
    color: isActive ? '#0B1E36' : '#00000099',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    borderBottom: isActive ? '2px solid #262626' : '2px solid transparent',
    textDecoration: 'none',
  });

  const handleLogout = () => {
    try {
      const cognitoUser = UserPool.getCurrentUser();
      if (cognitoUser) cognitoUser.signOut();
      handleLogoutSuccess();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <Grid
      container
      sx={{
        padding: '16px 80px',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      component="nav"
      aria-label="Top Navigation Bar"
    >
      <Grid item xs={2}>
        <Box
          component={Link}
          to="/dashboard"
          aria-label="TIVC Logo"
          style={{ marginRight: '1rem' }}
        >
          <img
            src={tivc_logo_5131_2x}
            alt="TIVC Main Logo"
            style={{
              width: '184px',
              height: '58px',
              display: 'block',
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Grid
          container
          alignItems="center"
          sx={{
            justifyContent: authDetails?.isAuthenticated
              ? 'space-between'
              : 'end',
          }}
        >

          {authDetails?.isAuthenticated && (
          <Box sx={{ marginLeft: '1rem' }}>
            <Box display="flex">
              {topNavOptions.map(({ title, pathName }) => {
                const currentPath = location.pathname;

                // Determine if the current tab is active
                const isActive = currentPath === pathName || currentPath.startsWith(`${pathName}/`);

                return (
                  <Box
                    key={pathName}
                    display="flex"
                    alignItems="flex-end"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ width: '100px', textAlign: 'center' }}
                    >
                      {isActive ? (
                        <Box component="span" style={getComputedNavStyle(isActive)}>
                          {title}
                        </Box>
                      ) : (
                        <Link to={pathName} style={getComputedNavStyle(isActive)}>
                          {title}
                        </Link>
                      )}
                    </Typography>
                  </Box>

                );
              })}
            </Box>
          </Box>
          )}

          {/* Log In or Log Out Button */}
          <Grid item>
            {authDetails?.isAuthenticated ? (
              <Button
                variant="text"
                onClick={handleLogout}
                sx={{
                  textTransform: 'none',
                  color: '#0B1E36',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                Log Out
              </Button>
            ) : (
              <Typography variant="subtitle1">
                <Link to="/login" style={getComputedNavStyle(true)}>
                  Log In
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TopBar;
