import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  useTheme,
  TextField,
  CircularProgress,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CustomButton from '../components/button/CustomButton';
import ClientListTable from '../components/dashboard/ClientListTable';
import AddClientDialog from '../components/dashboard/AddClientDialog';
import { AddIcon, SearchIcon } from '../icons';
import { useGetAllClientsQuery } from '../services/api/clientsApi';

const Dashboard = () => {
  const theme = useTheme();
  const [searchClientText, setSearchClientText] = useState('');
  const [openAddClientDialog, setOpenAddClientDialog] = useState(false);
  const [allClientListData, setAllClientListData] = useState([]);
  const {
    data: clients, isError, error, isLoading,
  } = useGetAllClientsQuery();

  // Sync local state with the data from the server
  // Filter clients based on search text
  useEffect(() => {
    if (clients) {
      const filtered = clients.data.filter(
        (client) => client.data.client_name.toLowerCase().includes(searchClientText.toLowerCase()),
      );
      setAllClientListData(filtered);
    }
  }, [searchClientText, clients]);

  const handleSearchClient = (e) => {
    setSearchClientText(e.target.value);
  };

  const handleAddClient = () => {
    setOpenAddClientDialog(true);
  };

  if (isLoading) {
    return <CircularProgress style={{ margin: '20% 50%' }} />;
  }
  if (isError) {
    return <Box>{error.message}</Box>;
  }
  return (
    <div>
      <Box
        style={{
          minHeight: '207px',
          margin: '47px 80px 80px 80px',
          backgroundColor: 'white',
          boxShadow: '0px 3px 6px #00000029',
          padding: '40px 40px 20px 40px',
          boxSizing: 'border-box',
        }}
      >
        <Grid container justifyContent="space-between">
          <Typography
            variant="h2"
            color={theme.palette.primary.main}
            style={{ font: 'normal normal bold 24px/32px' }}
          >
            Client List
          </Typography>
          <CustomButton
            buttonText="Add new client"
            onClickHandler={handleAddClient}
            id="add_new_client"
            customButtonStyle={{
              background: theme.palette.accent.red,
              color: 'white',
            }}
            startIcon={<AddIcon />}
          />
        </Grid>
        <TextField
          placeholder="Search Client List"
          onChange={handleSearchClient}
          style={{
            height: '3.5rem',
            width: '20.5rem',
            boxSizing: 'border-box',
            marginTop: '0.75rem',
            marginBottom: '1rem',
            color: '#00000099',
            textAlign: 'left',
            font: 'normal normal normal 16px/24px Roboto',
            letterSpacing: '0.15px',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {allClientListData && (
          <ClientListTable
            searchText={searchClientText}
            data={allClientListData}
            setClientListData={setAllClientListData}
          />
        )}
      </Box>
      {openAddClientDialog && (
        <AddClientDialog
          open={openAddClientDialog}
          setOpen={setOpenAddClientDialog}
          allClientData={allClientListData}
        />
      )}
    </div>
  );
};

export default Dashboard;
