import {
  Box, Button, Grid, Paper, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import SelectedVisualsTab from '../components/report-analysis/SelectedVisualsTab';
import VisualTab from '../components/report-analysis/VisualTab';
import {
  AddchartOutlinedIcon, ArrowBack, CheckCircleIcon, ExtensionIcon, SettingsIcon,
} from '../icons';

// Import the components corresponding to each tab
import DefaultVisualizationLibrary from '../components/report-analysis/DefaultVisualizationLibrary';
import CustomVisualizationLibrary from '../components/report-analysis/CustomVisualizationLibrary';
import AddCustomVisualization from '../components/report-analysis/AddCustomVisualization';
import SelectedVisualization from '../components/report-analysis/SelectedVisualization';
import {
  useGetAllVisualsByReportQuery,
} from '../services/api/visualsApi';

const visualTabItem = [
  {
    id: 'default',
    tab_name: 'Default Visualization Library',
    title: 'Default Visualization Library',
    icon: <SettingsIcon />,
  },
  {
    id: 'custom',
    tab_name: 'Custom Visualization Library',
    title: 'Custom Visualization Library',
    icon: <ExtensionIcon />,
  },
  {
    id: 'add',
    tab_name: 'Add a Custom Visualization',
    title: 'Add a Custom Visualization',
    icon: <AddchartOutlinedIcon />,
  },
  {
    id: 'selected',
    tab_name: 'Selected Visualization',
    title: 'Your Selected Visualizations',
    icon: <CheckCircleIcon />,
  },
];

const ReportAnalysis = () => {
  const navigate = useNavigate();
  const { report_id, client_id, analysis_name } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const {
    data: allVisuals,
    error: allVisualsError,
    isFetching: isAllVisualsFetching,
  } = useGetAllVisualsByReportQuery(report_id);

  const selectedTab = searchParams.get('tab') || 'selected';

  useEffect(() => {
    if (!searchParams.get('tab')) {
      setSearchParams({ tab: 'selected' });
    }
  }, [searchParams, setSearchParams]);

  const selectedVisuals = allVisuals?.data?.filter((visual) => visual.pinned) ?? [];
  const defaultVisuals = allVisuals?.data?.filter((visual) => visual.type === 'default') ?? [];
  const customVisuals = allVisuals?.data?.filter((visual) => visual.type === 'custom') ?? [];

  const selectedTabTitle = visualTabItem.find(
    (item) => item.id === selectedTab,
  )?.title;

  const handleTabChange = (tabId) => {
    setSearchParams({ tab: tabId });
  };

  const renderSelectedTabComponent = () => {
    switch (selectedTab) {
      case 'default':
        return (
          <DefaultVisualizationLibrary
            visualsData={defaultVisuals}
            isFetching={isAllVisualsFetching}
          />
        );
      case 'custom':
        return (
          <CustomVisualizationLibrary
            visualsData={customVisuals}
            isFetching={isAllVisualsFetching}
          />
        );
      case 'add':
        return <AddCustomVisualization visualsData={defaultVisuals} />;
      case 'selected':
        return (
          <SelectedVisualization
            selectedVisuals={selectedVisuals}
            isFetching={isAllVisualsFetching}
          />
        );
      default:
        return <AddCustomVisualization />;
    }
  };

  const goToClientData = () => {
    navigate(`/client-data/${client_id}`);
  };

  return (
    <Box>
      {/* Top Section */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0px 80px',
          margin: '2.5rem 0',
        }}
      >
        <ArrowBack
          sx={{
            marginRight: '1rem',
            cursor: 'pointer',
            '&:hover': { opacity: 0.7 },
          }}
          onClick={goToClientData}
        />
        <Typography variant="h4" fontWeight="700" style={{ fontSize: '32px' }}>
          {analysis_name || 'Unknow Analysis'}
        </Typography>
      </Box>

      {/* Main Content Section */}
      <Grid
        container
        sx={{
          padding: '0px 80px 32px 80px',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {/* Left: 30% width */}
          <Box sx={{ width: isCollapsed ? '5%' : '25%' }}>
            <VisualTab
              selectedTab={selectedTab}
              onTabChange={handleTabChange}
              visualTabItem={visualTabItem}
              isCollapsed={isCollapsed}
            />
            <SelectedVisualsTab
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
            />

          </Box>

          {/* Right: 70% width */}
          <Box sx={{ width: isCollapsed ? '92%' : '72%' }}>
            <Typography
              variant="h4"
              fontWeight="700"
              style={{ margin: '0 0 1rem 0', fontSize: '24px' }}
            >
              {selectedTabTitle}
            </Typography>
            {renderSelectedTabComponent()}
          </Box>
        </Grid>
      </Grid>
    </Box>

  );
};

export default ReportAnalysis;
