/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
/* eslint-disable new-cap */
import React from 'react';
import {
  Box, Typography, Button,
} from '@mui/material';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import theme from '../../styles/material_ui_theme';
import { DownloadIcon } from '../../icons';
import RenderChart from '../charts/RenderChart';
import DynamicTable from '../charts/nivoComponent/DynamicTable';
import VisualLoader from '../charts/common/VisualLoader';

const SelectedVisualization = ({
  selectedVisuals,
  isFetching,
  elementRef,
}) => {
  if (isFetching) {
    return <VisualLoader message="Loading selected visuals..." />;
  }

  const downloadAll = async () => {
    const chartContainers = Array.from(document.querySelectorAll('[data-chart-container]'));
    const pdf = new jsPDF('l', 'px');

    const padding = 40;

    for (let i = 0; i < chartContainers.length; i += 1) {
      try {
        const container = chartContainers[i];
        const canvas = await html2canvas(container, {
          scale: 2,
          backgroundColor: '#FFFFFF',
          logging: false,
        });

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const availableWidth = pageWidth - (2 * padding);
        const availableHeight = pageHeight - (2 * padding);

        let imgWidth = availableWidth;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (imgHeight > availableHeight) {
          imgHeight = availableHeight;
          imgWidth = (canvas.width * imgHeight) / canvas.height;
        }

        if (i > 0) {
          pdf.addPage();
        }

        const imgData = canvas.toDataURL('image/png');
        const xPos = padding + (availableWidth - imgWidth) / 2;
        const yPos = padding + (availableHeight - imgHeight) / 2;
        pdf.addImage(imgData, 'PNG', xPos, yPos, imgWidth, imgHeight);
      } catch (error) {
        console.error('Error processing chart:', error);
      }
    }

    pdf.save(`charts-${Date.now()}.pdf`);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        width: '100%',
        boxShadow: '0px 3px 6px 0px #00000029',
        boxSizing: 'border-box',
      }}
      display="flex"
      flexWrap="wrap"
    >
      {selectedVisuals.length === 0 ? (
        <Box
          sx={{
            backgroundColor: '#fff',
            width: '100%',
            boxShadow: '0px 3px 6px 0px #00000029',
            boxSizing: 'border-box',
            padding: '40px 24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              margin: '1.5rem 0',
            }}
          >
            <AddchartOutlinedIcon
              sx={{
                fontSize: '40px',
                color: '#A0A0A0',
                mb: 1,
              }}
            />
            <Typography
              sx={{
                color: '#00000099',
                fontSize: '16px',
              }}
            >
              Currently, there are no selected visuals.
              <br />
              Select visuals from the library that you want to download.
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: '#fff',
            width: '100%',
            boxShadow: '0px 3px 6px 0px #00000029',
            boxSizing: 'border-box',
          }}
          display="flex"
          flexWrap="wrap"
        >
          <Box
            sx={{
              padding: '40px 24px ',
              width: '100%',
            }}
            display="flex"
            flexWrap="wrap"
          >
            <Box
              sx={{
                overflowY: 'scroll',
                maxHeight: '1000px',
                padding: '0 2rem',
                width: '96%',
              }}
              ref={elementRef}
            >
              {/* Render the selected visuals */}
              {selectedVisuals.map((chart) => {
                const { chart_id, chart_title } = chart.chart_data;
                const { chart_type } = chart;
                const custom_title = chart?.customizations?.generalSettings?.title || chart_title;
                const fontFamily = chart?.customizations?.generalSettings?.font;

                return (
                  <Box
                    key={chart_id}
                    data-chart-container
                    sx={{
                      border: '1px solid #A0A0A0',
                      borderRadius: '8px',
                      backgroundColor: '#FFFFFF',
                      marginBottom: '2rem',
                      '@media print': {
                        margin: 0,
                        padding: '20px',
                        breakInside: 'avoid',
                        breakBefore: 'always',
                        breakAfter: 'always',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        padding: '1rem',
                        borderBottom: '1px solid #A0A0A0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 600, fontFamily }}
                      >
                        {custom_title}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderRadius: '4px',
                        width: '100%',
                        margin: '0.5rem auto',
                      }}
                    >
                      {chart_type?.includes('table') ? (
                        <DynamicTable
                          chartData={chart.chart_data}
                          customStyles={{
                            height: '100%',
                            width: '100%',
                            boxShadow: 'none',
                            maxHeight: '600px',
                          }}
                          custom_table_data={chart.customizations?.custom_table_data}
                          customConfig={chart.customizations}
                        />
                      ) : (
                        <RenderChart
                          chart_type={chart_type}
                          chartData={chart.chart_data}
                          customizations={chart.customizations}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        padding: '1rem',
                        color: '#262626',
                      }}
                    >
                      <Typography variant="body2" sx={{ fontFamily }}>
                        {chart.customizations?.generalSettings?.description}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>

          {/* INSIDE Download Button (calls the parent's function) */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              padding: '24px',
              paddingTop: 0,
            }}
          >
            <Button
              variant="outline"
              sx={{
                marginLeft: 'auto',
                display: 'flex',
                backgroundColor: theme.palette.accent.red,
                color: '#FFF',
                fontWeight: 500,
                '&:hover': {
                  backgroundColor: theme.palette.accent.red,
                },
              }}
              onClick={() => downloadAll()}
              startIcon={<DownloadIcon />}
            >
              Download All
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SelectedVisualization;
