import React from 'react';
import {
  Box, FormControl, InputLabel, Select, MenuItem, TextField,
} from '@mui/material';
import CollapsibleContainer from './CollapsibleContainer';

const LegendSettingsHeatMap = ({
  direction,
  position,
  offsetX,
  offsetY,
  length,
  thickness,
  tickSize,
  tickSpacing,
  onDirectionChange,
  onPositionChange,
  onOffsetXChange,
  onOffsetYChange,
  onLengthChange,
  onThicknessChange,
  onTickSizeChange,
  onTickSpacingChange,
}) => (
  <CollapsibleContainer title="Legends" defaultExpanded={false}>
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      {/* Legend Direction */}
      <FormControl sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}>
        <InputLabel>Legend Direction</InputLabel>
        <Select
          value={direction}
          onChange={onDirectionChange}
          label="Legend Direction"
          size="small"
        >
          <MenuItem value="row">Row</MenuItem>
          <MenuItem value="column">Column</MenuItem>
        </Select>
      </FormControl>

      {/* Legend Position */}
      <FormControl sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}>
        <InputLabel>Legend Position</InputLabel>
        <Select
          value={position}
          onChange={onPositionChange}
          label="Legend Position"
          size="small"
        >
          <MenuItem value="top-left">Top Left</MenuItem>
          <MenuItem value="top">Top</MenuItem>
          <MenuItem value="top-right">Top Right</MenuItem>
          <MenuItem value="right">Right</MenuItem>
          <MenuItem value="bottom-right">Bottom Right</MenuItem>
          <MenuItem value="bottom">Bottom</MenuItem>
          <MenuItem value="bottom-left">Bottom Left</MenuItem>
          <MenuItem value="left">Left</MenuItem>
          <MenuItem value="center">Center</MenuItem>
        </Select>
      </FormControl>

      {/* Legend Offset X */}
      <TextField
        label="Legend Offset X"
        variant="outlined"
        type="number"
        size="small"
        value={offsetX}
        onChange={(e) => onOffsetXChange(Number(e.target.value))}
        sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}
      />

      {/* Legend Offset Y */}
      <TextField
        label="Legend Offset Y"
        variant="outlined"
        type="number"
        size="small"
        value={offsetY}
        onChange={(e) => onOffsetYChange(Number(e.target.value))}
        sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}
      />

      {/* Legend Length */}
      <TextField
        label="Legend Length"
        variant="outlined"
        type="number"
        size="small"
        value={length}
        onChange={(e) => onLengthChange(Number(e.target.value))}
        sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}
      />

      {/* Legend Thickness */}
      <TextField
        label="Legend Thickness"
        variant="outlined"
        type="number"
        size="small"
        value={thickness}
        onChange={(e) => onThicknessChange(Number(e.target.value))}
        sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}
      />

      {/* Tick Size */}
      <TextField
        label="Tick Size"
        variant="outlined"
        type="number"
        size="small"
        value={tickSize}
        onChange={(e) => onTickSizeChange(Number(e.target.value))}
        sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}
      />

      {/* Tick Spacing */}
      <TextField
        label="Tick Spacing"
        variant="outlined"
        type="number"
        size="small"
        value={tickSpacing}
        onChange={(e) => onTickSpacingChange(Number(e.target.value))}
        sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}
      />
    </Box>
  </CollapsibleContainer>
);

export default LegendSettingsHeatMap;
