export const defaultDataColors = [
  '#93A01F', '#67C0A1', '#7A9DD4', '#AF92C3',
  '#C5869D', '#E27B7D', '#F4C389', '#8E9163',
  '#54746A', '#465679', '#5A3E66', '#6C4D56',
  '#814C4E', '#735641',
];

// Default single bar chart config
export const defaultSingleBarChartConfig = {
  generalSettings: {
    font: "Roboto', sans-serif",
    description: '',
    showBarLabel: true,
    graphDirection: 'vertical',
    showGridX: true,
    showGridY: true,
    padding: 0.5,
    innerPadding: 1,
  },
  layout: {
    top: 50,
    right: 50,
    bottom: 50,
    left: 50,
  },
  legends: {
    dataFrom: 'indexes',
    anchor: 'top-left',
    direction: 'column',
    justify: false,
    translateX: 120,
    translateY: 0,
    itemsSpacing: 2,
    itemWidth: 100,
    itemHeight: 20,
    symbolShape: 'circle',
    itemDirection: 'left-to-right',
    itemOpacity: 0.85,
    symbolSize: 20,
    effects: [
      {
        on: 'hover',
        style: {
          itemOpacity: 1,
        },
      },
    ],
  },
  colorPalette: [],
};

export const createSingleBarChartConfig = (chartData, customizations = {}) => {
  return {
    generalSettings: {
      title: chartData.chart_title,
      description: chartData.chart_description,
      minValue: chartData.chart_type === 'bar-topic' ? -1 : 0,
      maxValue: chartData.chart_type === 'bar-topic' ? 1 : undefined,
      ...defaultSingleBarChartConfig.generalSettings,
      ...customizations.generalSettings,
    },
    layout: {
      ...defaultSingleBarChartConfig.layout,
      ...customizations.layout,
    },
    legends: {
      ...defaultSingleBarChartConfig.legends,
      ...customizations.legends,
    },
    customData: chartData.data,
    colorPalette: customizations.colorPalette || [],
  };
};

// Default grouped bar chart config
export const defaultGroupedBarChartConfig = {
  generalSettings: {
    font: "Roboto', sans-serif",
    description: '',
    showBarLabel: true,
    graphDirection: 'vertical',
    showGridX: true,
    showGridY: true,
    padding: 0.5,
    innerPadding: 2,
    minValue: 0,
    maxValue: Infinity,
  },
  layout: {
    top: 50,
    right: 80,
    bottom: 50,
    left: 100,
  },
  legends: {
    dataFrom: 'indexBy',
    anchor: 'top-right',
    direction: 'column',
    justify: false,
    translateX: 120,
    translateY: 0,
    itemsSpacing: 2,
    itemWidth: 100,
    itemHeight: 20,
    symbolShape: 'circle',
    itemDirection: 'left-to-right',
    itemOpacity: 0.85,
    symbolSize: 20,
    effects: [
      {
        on: 'hover',
        style: {
          itemOpacity: 1,
        },
      },
    ],
  },
  colorPalette: [],
};

// Create grouped bar chart config
export const createGroupedBarChartConfig = (chartData, customizations = {}) => {
  return {
    generalSettings: {
      title: chartData.chart_title,
      description: chartData.chart_description,
      ...defaultGroupedBarChartConfig.generalSettings,
      ...customizations.generalSettings,
    },
    layout: {
      ...defaultGroupedBarChartConfig.layout,
      ...customizations.layout,
    },
    legends: {
      ...defaultGroupedBarChartConfig.legends,
      ...customizations.legends,
    },
    customData: chartData.data,
    colorPalette: customizations.colorPalette || [],
  };
};

// Default stacked bar chart config
export const defaultStackedBarConfig = {
  generalSettings: {
    font: "Roboto', sans-serif",
    description: '',
    showBarLabel: true,
    graphDirection: 'vertical',
    showGridX: true,
    showGridY: true,
    showTotals: true,
    padding: 0.5,
    innerPadding: 2,
    minValue: 0,
    maxValue: Infinity,
  },
  layout: {
    top: 50,
    right: 275,
    bottom: 75,
    left: 75,
  },
  legends: {
    dataFrom: 'indexBy',
    anchor: 'top-right',
    direction: 'column',
    justify: false,
    translateX: 120,
    translateY: 0,
    itemsSpacing: 2,
    itemWidth: 100,
    itemHeight: 20,
    symbolShape: 'circle',
    itemDirection: 'left-to-right',
    itemOpacity: 0.85,
    symbolSize: 20,
    effects: [
      {
        on: 'hover',
        style: {
          itemOpacity: 1,
        },
      },
    ],
  },
  colorPalette: [],
};

// Create stacked bar chart config
export const createStackedBarChartConfig = (chartData, customizations = {}) => {
  let customData = customizations.customData || [];

  // Only map the data if customData is empty
  if (!customData || customData.length === 0) {
    if (chartData.data && chartData.data.length > 0) {
      customData = chartData.data.map((item) => {
        return item;
      });
    }
  }

  return {
    generalSettings: {
      title: chartData.chart_title,
      description: chartData.chart_description,
      ...defaultStackedBarConfig.generalSettings,
      ...customizations.generalSettings,
    },
    layout: {
      ...defaultStackedBarConfig.layout,
      ...customizations.layout,
    },
    legends: {
      ...defaultStackedBarConfig.legends,
      ...customizations.legends,
    },
    customData,
    colorPalette: customizations.colorPalette || [],
  };
};

// Default heatmap config
export const defaultHeatmapConfig = {
  generalSettings: {
    font: "Roboto', sans-serif",
    description: '',
    enableLabels: true,
  },
  layout: {
    top: 50,
    right: 30,
    bottom: 50,
    left: 240,
  },
  legends: {
    anchor: 'bottom',
    translateX: 0,
    translateY: 30,
    length: 400,
    thickness: 8,
    direction: 'row',
    tickPosition: 'after',
    tickSize: 3,
    tickSpacing: 4,
    tickOverlap: false,
    title: 'Value →',
    titleAlign: 'start',
    titleOffset: 4,
  },
  colorPalette: [],
};

export const createHeatmapConfig = (chartData, customizations = {}) => ({
  generalSettings: {
    title: chartData?.chart_title,
    description: chartData?.chart_description,
    ...defaultHeatmapConfig.generalSettings,
    ...customizations.generalSettings,
  },
  layout: {
    ...defaultHeatmapConfig.layout,
    ...customizations.layout,
  },
  legends: {
    ...defaultHeatmapConfig.legends,
    ...customizations.legends,
  },
  colorPalette: customizations.colorPalette || [],
});

// Default Pie chart config
export const defaultPieChartConfig = {
  generalSettings: {
    font: "Roboto', sans-serif",
    description: '',
    innerRadius: 0,
    outerRadius: 100,
    showValues: true,
    sortByValue: false,
  },
  layout: {
    top: 80,
    right: 280,
    bottom: 80,
    left: 280,
  },
  legends: {
    anchor: 'top-left',
    direction: 'column',
    justify: false,
    translateX: -150,
    translateY: -50,
    itemsSpacing: 0,
    itemWidth: 100,
    itemHeight: 22,
    itemTextColor: '#999',
    itemDirection: 'left-to-right',
    itemOpacity: 1,
    symbolSize: 18,
    symbolShape: 'circle',
  },
  colorPalette: [],
};

export const createPieChartConfig = (chartData, customizations = {}) => ({
  generalSettings: {
    title: chartData.chart_title,
    description: chartData.chart_description,
    ...defaultPieChartConfig.generalSettings,
    ...customizations.generalSettings,
  },
  layout: {
    ...defaultPieChartConfig.layout,
    ...customizations.layout,
  },
  legends: {
    ...defaultPieChartConfig.legends,
    ...customizations.legends,
  },
  colorPalette: customizations.colorPalette || [],
});

// Default table chart config
export const defaultTableChartConfig = {
  generalSettings: {
    font: "Roboto', sans-serif",
    description: '',
  },
  layout: {},
  dataDisplay: {},
};

export const createTableChartConfig = (chartData, customizations = {}) => ({
  generalSettings: {
    title: chartData.chart_title,
    description: chartData.chart_description,
    ...defaultTableChartConfig.generalSettings,
    ...customizations.generalSettings,
  },
});

export default {
  defaultSingleBarChartConfig,
  defaultGroupedBarChartConfig,
  defaultStackedBarConfig,
  defaultHeatmapConfig,
};
