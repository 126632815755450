const getNivoCustomFontTheme = (selectedFont) => {
  const baseTheme = {
    fontFamily: selectedFont,
    axis: {
      ticks: {
        text: {
          fontFamily: selectedFont,
        },
      },
      legend: {
        text: {
          fontFamily: selectedFont,
        },
      },
    },
    legends: {
      text: {
        fontFamily: selectedFont,
      },
      title: {
        text: {
          fontFamily: selectedFont,
        },
      },
    },
    labels: {
      text: {
        fontFamily: selectedFont,
      },
    },
    markers: {
      text: {
        fontFamily: selectedFont,
      },
    },
    dots: {
      text: {
        fontFamily: selectedFont,
      },
    },
    tooltip: {
      container: {
        fontFamily: selectedFont,
      },
    },
  };
  return baseTheme;
};

export default getNivoCustomFontTheme;
