import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const ConfirmationDialog = ({
  description = '',
  title,
  open,
  setOpen,
  handleConfirmation,
  promptDialog = false,
}) => {
  const handleClose = (value) => {
    if (!promptDialog) {
      setOpen(false);
    }
    handleConfirmation(value);
  };

  return (
    <Dialog
      open={open}
      onClose={() => (_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      disableEscapeKeyDown
    >
      {description && (
        <DialogContent style={{ paddingBottom: 0 }}>
          {description}
        </DialogContent>
      )}
      <DialogContent id="alert-dialog-title">{title}</DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} sx={{ width: '40px' }}>
          Cancel
        </Button>
        <Button
          onClick={() => handleClose(true)}
          sx={{ width: '40px' }}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
