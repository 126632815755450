/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { ChromePicker } from 'react-color';
import CollapsibleContainer from './CollapsibleContainer';
import theme from '../../../styles/material_ui_theme';
import { InfoOutlinedIcon } from '../../../icons';

// Function to interpolate between two colors
const interpolateColor = (color1, color2, factor) => {
  const r1 = parseInt(color1.slice(1, 3), 16);
  const g1 = parseInt(color1.slice(3, 5), 16);
  const b1 = parseInt(color1.slice(5, 7), 16);

  const r2 = parseInt(color2.slice(1, 3), 16);
  const g2 = parseInt(color2.slice(3, 5), 16);
  const b2 = parseInt(color2.slice(5, 7), 16);

  const r = Math.round(r1 + (r2 - r1) * factor);
  const g = Math.round(g1 + (g2 - g1) * factor);
  const b = Math.round(b1 + (b2 - b1) * factor);

  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const ColorSettings = ({
  numberOfColors = 0,
  onColorPaletteChange,
  initialColorPalette = [],
}) => {
  const [showColorPicker, setShowColorPicker] = useState(null);
  const [startColor, setStartColor] = useState('#000000');
  const [endColor, setEndColor] = useState('#000000');
  const [showPalette, setShowPalette] = useState(
    initialColorPalette.length > 0,
  );
  const [disabledReset, setDisabledReset] = useState(initialColorPalette.length > 0);
  const [currentPalette, setCurrentPalette] = useState(initialColorPalette);
  const [isApplied, setIsApplied] = useState(false);

  // Initialize colors
  useEffect(() => {
    if (initialColorPalette.length > 0) {
      setCurrentPalette(initialColorPalette);
      setShowPalette(true);
    }
  }, [initialColorPalette]);

  // Generate color palette
  const generatePalette = () => {
    const colors = [];
    if (numberOfColors === 1) {
      colors.push(startColor);
    } else {
      for (let i = 0; i < numberOfColors; i += 1) {
        const factor = i / (numberOfColors - 1);
        colors.push(interpolateColor(startColor, endColor, factor));
      }
    }
    return colors;
  };

  // Handle generate palette button click
  const handleGeneratePalette = () => {
    const newPalette = generatePalette();
    setCurrentPalette(newPalette);
    onColorPaletteChange(newPalette);
    setShowPalette(true);
  };

  // Handle reset button click
  const handleReset = () => {
    if (initialColorPalette.length > 0) {
      setCurrentPalette(initialColorPalette);
      onColorPaletteChange(initialColorPalette);
      setShowPalette(true);
      setDisabledReset(true);
    } else {
      setCurrentPalette([]);
      onColorPaletteChange([]);
      setShowPalette(false);
      setStartColor('#000000');
      setEndColor('#000000');
    }
    setShowColorPicker(null);
  };

  // Handle individual color change in palette
  const handlePaletteColorChange = (color, index) => {
    const newPalette = [...currentPalette];
    newPalette[index] = color.hex;
    setCurrentPalette(newPalette);
    onColorPaletteChange(newPalette);
    setDisabledReset(false);
  };

  // Sync start and end colors if number of colors is 1
  useEffect(() => {
    if (numberOfColors === 1) {
      setEndColor(startColor);
    }
  }, [startColor, numberOfColors]);

  useEffect(() => {
    if (numberOfColors === 1) {
      setStartColor(endColor);
    }
  }, [endColor, numberOfColors]);

  return (
    <CollapsibleContainer title="Color Settings" defaultExpanded={false}>
      <Box sx={{ p: 2, pt: 0 }}>
        {/* Color Range Selection */}
        {!showPalette && (
          <>
            <Typography variant="h6" gutterBottom>
              {numberOfColors === 1 ? 'Set Color' : 'Set Color Range'}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 4,
                mt: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ mr: 2, width: '100px' }}>
                    {numberOfColors === 1 ? 'Color' : 'Starting Color'}
                  </Typography>
                  <Box sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
                    <Box
                      sx={{
                        width: 56,
                        height: 32,
                        bgcolor: startColor,
                        margin: '4px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                      }}
                      onClick={() => setShowColorPicker('start')}
                    />
                  </Box>
                  <Typography sx={{ ml: 2 }}>{startColor}</Typography>
                </Box>

                {numberOfColors > 1 && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ mr: 2, width: '100px' }}>
                      Ending Color
                    </Typography>
                    <Box sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
                      <Box
                        sx={{
                          width: 56,
                          height: 32,
                          bgcolor: endColor,
                          margin: '4px',
                          cursor: 'pointer',
                          borderRadius: '4px',
                        }}
                        onClick={() => setShowColorPicker('end')}
                      />
                    </Box>
                    <Typography sx={{ ml: 2 }}>{endColor}</Typography>
                  </Box>
                )}
              </Box>
              <Button
                variant="outlined"
                onClick={handleGeneratePalette}
                color="primary"
                sx={{
                  height: '42px',
                  borderRadius: '4px',
                  color: theme.palette.accent.blue,
                  borderColor: theme.palette.accent.blue,
                  '&:hover': {
                    borderColor: theme.palette.accent.blue,
                    backgroundColor: 'transparent',
                  },
                  minWidth: '120px',
                }}
              >
                {numberOfColors === 1 ? (isApplied ? 'Applied' : 'Apply') : 'Generate Palette'}
              </Button>
            </Box>
          </>
        )}

        {/* Generated Palette */}
        {showPalette && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Your Generated Palette
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                mb: 2,
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  border: '1px solid #ccc',
                  p: '4px',
                }}
              >
                {currentPalette.map((color, index) => (
                  <Box
                    key={`${color}-${index}`}
                    sx={{
                      width: 56,
                      height: 32,
                      bgcolor: color,
                      cursor: 'pointer',
                      borderRadius: '4px',
                    }}
                    onClick={() => setShowColorPicker(`palette-${index}`)}
                  />
                ))}
              </Box>
              <Button
                variant="outlined"
                onClick={handleReset}
                disabled={disabledReset}
                sx={{
                  height: '42px',
                  width: '100px',
                  marginLeft: '0.5rem',
                  borderRadius: '4px',
                  color: theme.palette.accent.blue,
                  borderColor: theme.palette.accent.blue,
                  '&:hover': {
                    borderColor: theme.palette.accent.blue,
                    backgroundColor: 'transparent',
                  },
                }}
              >
                Reset
              </Button>
            </Box>
          </Box>
        )}

        {/* Color Picker Popover */}
        {showColorPicker && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: 2,
              top: 'auto',
              bottom: '70%',
            }}
          >
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              onClick={() => setShowColorPicker(null)}
            />
            <ChromePicker
              color={
                showColorPicker.startsWith('palette-')
                  ? currentPalette[parseInt(showColorPicker.split('-')[1])]
                  : showColorPicker === 'start'
                    ? startColor
                    : endColor
              }
              onChange={(color) => {
                if (showColorPicker.startsWith('palette-')) {
                  handlePaletteColorChange(
                    color,
                    parseInt(showColorPicker.split('-')[1]),
                  );
                } else if (showColorPicker === 'start') {
                  setStartColor(color.hex);
                } else {
                  setEndColor(color.hex);
                }
              }}
            />
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            backgroundColor: '#E2F2FE',
            mt: 3,
            padding: '6px 8px',
            height: '40px',
            width: '100%',
            maxWidth: '600px',
            borderRadius: 1,
          }}
        >
          <InfoOutlinedIcon sx={{ color: '#275273' }} />
          <Typography sx={{ color: '#275273' }}>
            {showPalette
              ? 'Select the shade and customize it to your preference.'
              : 'Choose your preferred shades to apply to the visuals'}
          </Typography>
        </Box>
      </Box>
    </CollapsibleContainer>
  );
};

export default ColorSettings;
