import React from 'react';
import {
  Box, TextField, Typography, CircularProgress,
} from '@mui/material';
import CustomButton from '../button/CustomButton';
import { InfoOutlinedIcon } from '../../icons';

const ModelProcessingSection = ({
  textProcessingReady,
  handleTextProcessing,
  setModelName,
  modelName,
  modelNames,
}) => {
  const isDuplicateName = modelNames.includes(modelName.trim());
  const isButtonDisabled = !textProcessingReady || isDuplicateName;

  return (
    <>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#E7F3FF',
          padding: '10px 20px',
          borderRadius: '8px',
          marginTop: '1.5rem',
        }}
      >
        <Box display="flex" alignItems="center" style={{ marginRight: 'auto' }}>
          <InfoOutlinedIcon style={{ color: '#1976D2', marginRight: '8px' }} />
          <Typography variant="body2" style={{ color: '#0B1E36' }}>
            You can name your model before you save it, for easy reference
            later.
          </Typography>
        </Box>

        <TextField
          error={isDuplicateName}
          variant="outlined"
          placeholder="Enter Model Name"
          value={modelName}
          onChange={(e) => setModelName(e.target.value)}
          size="small"
          style={{
            marginRight: '20px',
            backgroundColor: '#FFFFFF',
            borderRadius: '4px',
          }}
        />

        <CustomButton
          buttonText="RUN TEXT PROCESSING MODEL"
          customButtonStyle={{
            backgroundColor: '#D32F2F',
            color: '#FFFFFF',
            padding: '8px 16px',
            borderRadius: '4px',
            textTransform: 'uppercase',
            opacity: isButtonDisabled ? 0.6 : 1,
            minWidth: '275px',
          }}
          disabled={isButtonDisabled}
          onClickHandler={handleTextProcessing}
        />
      </Box>
      <Box>
        <Typography
          variant="caption"
          style={{
            marginLeft: 'auto',
            display: 'flex',
            color: isDuplicateName ? '#D32F2F' : '#808080',
            width: 'fit-content',
            marginRight: '2.3rem',
          }}
        >
          {isDuplicateName
            ? 'Model name already exists'
            : 'Must select a Response type to run model.'}
        </Typography>
      </Box>
    </>
  );
};

export default ModelProcessingSection;
