/**
 * Handles the sorting request when a column header is clicked.
 *
 * @param {string} property - The property by which to sort the data.
 * @param {string} orderBy - The current property being sorted by.
 * @param {string} order - The current sort order ('asc' or 'desc').
 * @param {Function} setOrderBy - Function to set the orderBy state.
 * @param {Function} setOrder - Function to set the order state.
 */
export const handleRequestSort = (
  property,
  orderBy,
  order,
  setOrderBy,
  setOrder,
) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

/**
 * Handles the change in pagination page.
 *
 * @param {Event} event - The event object.
 * @param {number} newPage - The new page number.
 * @param {Function} setPage - Function to set the page state.
 */
export const handleChangePage = (event, newPage, setPage) => {
  setPage(newPage);
};

/**
 * Handles the change in number of rows per page.
 *
 * @param {Event} event - The event object.
 * @param {Function} setRowsPerPage - Function to set the rowsPerPage state.
 * @param {Function} setPage - Function to set the page state to 0.
 */
export const handleChangeRowsPerPage = (event, setRowsPerPage, setPage) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

export const getComparator = (order, orderBy) => {
  return (a, b) => {
    const valueA = a.data[orderBy];
    const valueB = b.data[orderBy];

    // Handle date comparison
    if (
      (!Number.isNaN(Date.parse(valueA))
        || valueA === null
        || valueA === undefined)
      && (!Number.isNaN(Date.parse(valueB))
        || valueB === null
        || valueB === undefined)
    ) {
      // Treat null or undefined values as "1970-01-01" for comparison purposes
      const dateA = new Date(valueA || '1970-01-01');
      const dateB = new Date(valueB || '1970-01-01');
      return order === 'desc' ? dateB - dateA : dateA - dateB;
    }

    // Otherwise, compare as strings
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return order === 'desc'
        ? valueB.localeCompare(valueA)
        : valueA.localeCompare(valueB);
    }

    // Fallback to numeric comparison if applicable
    return order === 'desc' ? valueB - valueA : valueA - valueB;
  };
};
