/* eslint-disable max-len */
const fileNameAlreadyExists = (data, file_name) => {
  const alreadyExists = data?.find((file) => file?.file_name === file_name);
  return alreadyExists !== undefined;
};

const createTimeStamp = (currentTime) => {
  const years = currentTime.getFullYear();
  let months = currentTime.getMonth() + 1;
  let days = currentTime.getDate();
  let hours = currentTime.getHours();
  let minutes = currentTime.getMinutes();
  let seconds = currentTime.getSeconds();

  if (months < 10) {
    months = `0${months}`;
  }
  if (days < 10) {
    days = `0${days}`;
  }

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  const timeStamp = `${years}${months}${days}_${hours}${minutes}${seconds}`;
  return timeStamp;
};

const addTimeStamp = (fileName) => {
  const currentTime = new Date(
    new Date().toLocaleString('en', { timeZone: 'america/new_york' }),
  );
  const currentTimeStamp = createTimeStamp(currentTime);

  const indexOfLastDot = fileName.lastIndexOf('.');
  const fileNameWithTimeStamp = `${fileName.substring(
    0,
    indexOfLastDot,
  )}_${currentTimeStamp}${fileName.substring(indexOfLastDot)}`;
  return fileNameWithTimeStamp;
};

const fileValidation = (file) => {
  const fileSize = file.size;
  const response = {
    isValidFile: true,
    errorMessage: null,
  };
  if (fileSize <= 0) {
    response.isValidFile = false;
    response.errorMessage = 'File Contains No Data';
  }
  if (fileSize >= 10485760) {
    response.isValidFile = false;
    response.errorMessage = 'File Size Too Large (max limit is 10MB)';
  }
  return response;
};

/**
 * Validates the client name based on specific criteria and sets the input error state accordingly.
 * The name must not be empty, can only contain letters, periods, and spaces,
 * and must be between 3 and 40 characters in length.
 *
 * @param {string} newName - The new client name to validate.
 * @param {Function} setInputError - The state setter function for managing input errors.
 * @returns {boolean} - Returns `true` if the name is valid, otherwise `false`.
 */
const validateClientName = (newName, setInputError) => {
  const validPattern = /^[A-Za-z0-9._\-\s]*$/;

  if (newName.trim() === '' || !validPattern.test(newName.trim())) {
    setInputError({ error: true, error_message: 'Invalid name' });
    return false;
  }

  if (newName.trim().length < 3 || newName.trim().length > 40) {
    setInputError({ error: true, error_message: 'Name must contain between 3 and 40 characters' });
    return false;
  }

  setInputError({ error: false, error_message: '' });
  return true;
};

/**
 * Checks if a client with the given name already exists in the client list.
 *
 * @param {Array} allClients - The array of all clients.
 * @param {string} newClientName - The name of the new client to check.
 * @returns {boolean} - Returns `true` if the client already exists, otherwise `false`.
 */
const isClientAlreadyExist = (allClients, newClientName) => {
  return allClients?.some(
    (client) => client?.data?.client_name?.toLowerCase().trim() === newClientName.toLowerCase().trim(),
  );
};

// sort array based on timeStamp of key
/**
 * @param {array} arr: array which needs to be sorted
 * @param {string} key: key which has the file name with time stamp.
 * @return {array} returns sorted array
 */

const sortByTimeStamp = (arr, key) => {
  const arrCopy = [...arr];
  // eslint-disable-next-line no-unused-vars
  const sample_time_stamp = '20221214_093358';
  // eslint-disable-next-line no-prototype-builtins
  const key_exist_in_object = arr[0]?.hasOwnProperty(key);
  arrCopy.sort((a, b) => {
    let file_a_name;
    let file_b_name;

    if (key_exist_in_object) {
      file_a_name = a[key];
      file_b_name = b[key];
    } else {
      file_a_name = a.data[key];
      file_b_name = b.data[key];
    }

    // last underscore of file name is always the underscore used in time stamp.
    const time_stamp_a_underscore_index = file_a_name.lastIndexOf('_');
    const time_stamp_b_underscore_index = file_b_name.lastIndexOf('_');

    // slicing the time stamp from file name,
    // (subtracting 8 and adding 7 because there are 8 chars before underscore and
    // 6 chars after underscore of timestamp)
    const file_a_time_stamp = file_a_name.slice(
      time_stamp_a_underscore_index - 8,
      time_stamp_a_underscore_index + 7,
    );
    const file_b_time_stamp = file_b_name.slice(
      time_stamp_b_underscore_index - 8,
      time_stamp_b_underscore_index + 7,
    );

    if (file_a_time_stamp > file_b_time_stamp) return 1;
    if (file_a_time_stamp < file_b_time_stamp) return -1;
    return 0;
  });

  return arrCopy;
};

/**
 * Extracts file names from all arrays in the given object.
 *
 * @param {Object} obj - The object containing arrays.
 * @returns {string[]} An array of file names.
 */
const extractFileNames = (obj) => {
  const fileNames = [];

  // Use Object.keys to iterate over the object's own properties
  Object.keys(obj).forEach((key) => {
    // Check if the property is an array
    if (Array.isArray(obj[key])) {
      // Loop through each item in the array
      obj[key].forEach((item) => {
        // Check if the item has a file_name property and add it to the array
        if (item.file_name) {
          fileNames.push(item.file_name);
        }
      });
    }
  });

  return fileNames;
};

const separateKeysAndColorsForStackedBar = (data) => {
  const keysSet = new Set();
  const colorsMap = new Map();

  data?.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (!key.includes('Color') && key !== 'filename') {
        const colorKey = `${key}Color`;
        keysSet.add(key); // Add key to the set
        if (obj[colorKey]) {
          colorsMap.set(key, obj[colorKey]); // Map key to its color
        }
      }
    });
  });

  const keysArray = Array.from(keysSet); // Convert keys to an array
  const colorsArray = keysArray.map((key) => colorsMap.get(key)); // Map keys to their colors
  return { keys: keysArray, colors: colorsArray };
};

export {
  fileNameAlreadyExists,
  addTimeStamp,
  fileValidation,
  createTimeStamp,
  validateClientName,
  isClientAlreadyExist,
  sortByTimeStamp,
  extractFileNames,
  separateKeysAndColorsForStackedBar,
};
