import React from 'react';
import { Box, TextField } from '@mui/material';
import CollapsibleContainer from './CollapsibleContainer';

const LayoutSettings = ({ margins, onMarginChange }) => (
  <CollapsibleContainer title="Layout Settings" defaultExpanded={false}>
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        justifyContent: 'space-between',
      }}
    >
      {['Top', 'Bottom', 'Left', 'Right'].map((side) => (
        <TextField
          key={side}
          label={`Margin ${side}`}
          variant="outlined"
          type="number"
          size="small"
          fullWidth
          value={margins[side.toLowerCase()]}
          onChange={(e) => onMarginChange(side.toLowerCase(), parseInt(e.target.value, 10) || 0)}
        />
      ))}
    </Box>
  </CollapsibleContainer>
);

export default LayoutSettings;
