import apiService from '../apiService';

export const clientsApi = apiService.injectEndpoints({
  tagTypes: ['clients'],
  endpoints: (builder) => ({
    getAllClients: builder.query({
      query: () => '/v1/clients',
      providesTags: ['clients'],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          // Handle successful response
        } catch (error) {
          console.error('Error fetching clients:', error);
          // Handle error here
        }
      },
    }),
    getClientById: builder.query({
      query: (client_id) => `/v1/clients/${client_id}`,
      providesTags: ['client'],
    }),
    createNewClient: builder.mutation({
      query: (client_name) => ({
        url: '/v1/clients',
        method: 'POST',
        body: { data: { client_name } },
      }),
      invalidatesTags: ['clients', 'client'],
    }),
    updateClientDetails: builder.mutation({
      query: ({ client_id, updatedClientData }) => ({
        url: `/v1/clients/${client_id}`,
        method: 'PUT',
        body: { data: updatedClientData },
      }),
      invalidatesTags: ['clients', 'client'],
    }),
    deleteClientById: builder.mutation({
      query: (client_id) => ({
        url: `/v1/clients/${client_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['clients'],
    }),
  }),
});

export const {
  useGetAllClientsQuery,
  useGetClientByIdQuery,
  useCreateNewClientMutation,
  useUpdateClientDetailsMutation,
  useDeleteClientByIdMutation,
} = clientsApi;
