import React from 'react';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import TopBar from '../pages/TopBar';
import HorizontalDivider from '../components/layout-design/HorizontalDivider';

const Layout = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TopBar />
      </Grid>
      <Grid item xs={12}>
        <HorizontalDivider />
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Layout;
