import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import CollapsibleContainer from './CollapsibleContainer';
import theme from '../../../styles/material_ui_theme';
import { InfoOutlinedIcon } from '../../../icons';

const heatmapColorSchemes = [
  'brown_blueGreen',
  'purpleRed_green',
  'pink_yellowGreen',
  'red_blue',
  'red_grey',
  'blues',
  'red_yellow_blue',
  'red_yellow_green',
  'yellow_green_blue',
  'yellow_orange_brown',
];

const ColorSettingsHeatmap = ({
  onColorPaletteChange,
  initialColorPalette = [],
}) => {
  const [selectedColorScheme, setSelectedColorScheme] = useState(initialColorPalette[0]);
  const [isApplied, setIsApplied] = useState(false);

  // Handle color scheme change
  const handleColorSchemeChange = (event) => {
    setSelectedColorScheme(event.target.value);
    setIsApplied(false);
  };

  // Apply the selected color scheme
  const handleApplyColorScheme = () => {
    setIsApplied(true);
    onColorPaletteChange([selectedColorScheme]);
  };

  return (
    <CollapsibleContainer title="Color Settings" defaultExpanded={false}>
      <Box sx={{ p: 2, pt: 0 }}>
        {/* Color Range Selection */}
        <Typography variant="h6" gutterBottom sx={{ fontSize: '20px', fontWeight: '600' }}>
          Set Color Scheme
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 4,
          }}
        >
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id="color-scheme-label">Color Scheme</InputLabel>
            <Select
              labelId="color-scheme-label"
              value={selectedColorScheme}
              placeholder="Select Color Scheme"
              label="Color Scheme"
              onChange={handleColorSchemeChange}
              sx={{ height: '46px' }}
            >
              {heatmapColorSchemes.map((scheme) => (
                <MenuItem key={scheme} value={scheme}>
                  {scheme}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={handleApplyColorScheme}
            color="primary"
            sx={{
              height: '46px',
              borderRadius: '4px',
              color: theme.palette.accent.blue,
              borderColor: theme.palette.accent.blue,
              '&:hover': {
                borderColor: theme.palette.accent.blue,
                backgroundColor: 'transparent',
              },
              marginTop: 1,
              width: '120px',
            }}
          >
            {isApplied
              ? 'Applied'
              : 'Apply'}

          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            backgroundColor: '#E2F2FE',
            mt: 3,
            padding: '6px 8px',
            height: '40px',
            width: '100%',
            maxWidth: '600px',
            borderRadius: 1,
          }}
        >
          <InfoOutlinedIcon sx={{ color: '#275273' }} />
          <Typography sx={{ color: '#275273' }}>
            Choose your preferred color scheme to apply to the visuals
          </Typography>
        </Box>
      </Box>
    </CollapsibleContainer>
  );
};

export default ColorSettingsHeatmap;
