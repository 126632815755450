import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ChartPreview, GeneralSettings } from './common';
import FooterActions from './common/FooterActions';
import DynamicTable from './nivoComponent/DynamicTable';
import { createTableChartConfig } from './common/chartsCustomConfig';

const CustomTableChart = ({ chartDataDetails }) => {
  const {
    chart_data, customizations = {}, visual_id, chart_id, report_id, chart_type,
  } = chartDataDetails;

  const [customConfig, setCustomConfig] = useState(
    createTableChartConfig(chart_data, customizations),
  );

  const handleGeneralSettingsChange = (key, value) => {
    if (key && value !== undefined) {
      setCustomConfig((prev) => ({
        ...prev,
        generalSettings: {
          ...prev.generalSettings,
          [key]: value,
        },
      }));
    }
  };
  const font_family = customConfig.generalSettings.font;

  return (
    <Box>
      <ChartPreview
        chart_title={customConfig.generalSettings.title || chart_data.chart_title}
        data_dump={chart_data?.data_dump}
        font_family={font_family}
        isTable
        chart_data={chart_data}
      >
        <DynamicTable
          chartData={chart_data}
          customConfig={customConfig}
          custom_table_data={customizations?.custom_table_data}
        />
      </ChartPreview>
      <GeneralSettings
        chartType="table"
        settings={{
          ...customConfig.generalSettings,
          title: customConfig.generalSettings.title || chart_data.chart_title,
        }}
        onChange={handleGeneralSettingsChange}
      />
      <FooterActions
        visual_id={visual_id}
        updatedCustomizations={customConfig}
        chart_type={chart_type}
        chart_id={chart_id}
        report_id={report_id}
        custom_table_data={chartDataDetails.chart_data.data}
      />
    </Box>
  );
};

export default CustomTableChart;
