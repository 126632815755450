import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ClientSelector = ({ clientLists, client_data, onClientChange }) => {
  const [selectedClient, setSelectedClient] = useState(
    client_data?.client_id || '',
  );

  useEffect(() => {
    // Set the initial client ID when the component mounts or when client_data changes
    if (client_data?.client_id) {
      setSelectedClient(client_data.client_id);
    }
  }, [client_data]);

  const handleClientSelection = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    onClientChange(clientId);
    const clientData = clientLists.find(
      (client) => client.data.client_id === clientId,
    )?.data;
    if (clientData) {
      window.history.pushState({}, '', `/client-data/${clientData.client_id}`);
    }
  };

  return (
    <FormControl sx={{ minWidth: 200 }} size="small">
      <InputLabel id="client-select-label">Select Client</InputLabel>
      <Select
        labelId="client-select-label"
        id="client-select"
        label="Select Client"
        value={selectedClient}
        onChange={handleClientSelection}
      >
        <MenuItem value="">
          <em>Select Client</em>
        </MenuItem>
        {clientLists.map((clientDetails) => {
          const clientData = clientDetails.data;
          return (
            <MenuItem key={clientData.client_id} value={clientData.client_id}>
              {clientData.client_name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ClientSelector;
