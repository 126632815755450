import { Box, Typography } from '@mui/material';
import React from 'react';

const VisualLoader = ({ message = 'Fetching Data... ' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        width: '-webkit-fill-available',
        padding: '16px',
        borderRadius: '8px 8px 0 0',
        marginBottom: '24px',
        justifyContent: 'center',
        boxShadow: '0px 3px 6px 0px #00000029',
        backgroundColor: '#FFFFFF',
      }}
    >
      <Typography sx={{ color: '#A0A0A0' }}>{message}</Typography>
    </Box>
  );
};

export default VisualLoader;
