import React, { memo, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import theme from '../../../styles/material_ui_theme';
import { downloadDataAsCSV, downloadVisualAsImage } from '../../../utils/downloadVisualsData';
import { useLazyGetPreSignedUrlQuery } from '../../../services/api/filesApi';
import useDownloadCsv from '../../../hooks/useDownloadCsv';

const accentBlue = theme.palette.accent.blue;

// Consolidated common button styles
const commonButtonStyles = {
  border: `1px solid ${accentBlue}`,
  borderRadius: '4px',
  fontWeight: 500,
  minWidth: 'fit-content',
};

const styles = {
  downloadVisualBtn: {
    ...commonButtonStyles,
    backgroundColor: accentBlue,
    '&:hover': {
      ...commonButtonStyles,
      background: accentBlue,
    },
  },
  downloadDataBtn: {
    ...commonButtonStyles,
    color: accentBlue,
    '&:hover': {
      ...commonButtonStyles,
      background: 'transparent',
    },
  },
};

const ChartPreview = memo(({
  chart_title,
  children,
  data_dump,
  font_family,
  isTable = false,
  chart_data,
}) => {
  const chartRef = useRef(null);
  const [getPreSignedUrl] = useLazyGetPreSignedUrlQuery();
  const { downloadCsv } = useDownloadCsv();

  const handleDownloadChartData = async (e) => {
    const { data: preSignedUrl } = await getPreSignedUrl({
      file_key: data_dump,
      type: 'fetch',
    });
    e.preventDefault();
    downloadDataAsCSV(preSignedUrl);
  };

  return (
    <Box sx={{
      p: 5, bgcolor: 'common.white', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', mb: '1px',
    }}
    >
      <Box sx={{ border: '1px solid #A0A0A0', pb: 2, borderRadius: 1 }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          borderBottom: '1px solid #A0A0A0',
        }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 20,
                textWrap: 'balance',
                fontFamily: font_family,
              }}
            >
              {chart_title}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="outlined"
              sx={styles.downloadDataBtn}
              onClick={(e) => handleDownloadChartData(e)}
              size="small"
            >
              Download Chart Data
            </Button>
            <Button
              variant="contained"
              sx={styles.downloadVisualBtn}
              onClick={() => (isTable ? downloadCsv(chart_data.data, chart_title, '') : downloadVisualAsImage(chartRef, chart_title))}
              size="small"
            >
              {isTable ? 'Download Table Data as CSV' : 'Download Visual as Image'}
            </Button>
          </Box>
        </Box>
        <Box sx={{ width: '100%', height: 380, margin: '0 auto' }} ref={chartRef}>
          {children}
        </Box>
      </Box>
    </Box>
  );
});

ChartPreview.displayName = 'ChartPreview';

export default ChartPreview;
