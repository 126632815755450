import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ChromePicker } from 'react-color';
import CollapsibleContainer from './CollapsibleContainer';
import theme from '../../../styles/material_ui_theme';
import { InfoOutlinedIcon } from '../../../icons';

// TODO: Change the color palette to the new one
const stackedBarColorPalette = [
  {
    '#ACE1AF': '#ACE1AF', // Positive
    '#C0C0C0': '#C0C0C0', // Neutral
    '#E07A5F': '#E07A5F', // Negative
  },
];

const getColorName = (colorCode) => {
  switch (colorCode) {
    case '#ACE1AF':
      return 'Positive';
    case '#C0C0C0':
      return 'Neutral';
    case '#E07A5F':
      return 'Negative';
    default:
      return '';
  }
};

const ColorSettingsStackedBar = ({
  onColorPaletteChange,
  colorPalette = [],
  initialColorPalette = [],
  uniqueColors = [],
}) => {
  const [showColorPicker, setShowColorPicker] = useState(null);
  const [stackedColors, setStackedColors] = useState({});
  const [isApplied, setIsApplied] = useState(false);
  // Initialize stacked colors
  useEffect(() => {
    const defaultPalette = colorPalette?.length > 0 ? colorPalette[0] : stackedBarColorPalette[0];

    const colors = {};
    uniqueColors.forEach((color) => {
      if (defaultPalette[color]) {
        colors[color] = defaultPalette[color];
      } else if (Object.keys(stackedBarColorPalette[0]).includes(color)) {
        colors[color] = stackedBarColorPalette[0][color];
      }
    });
    setStackedColors(colors);
  }, [uniqueColors, colorPalette]);

  // Handle color change for stacked bar
  const handleStackedColorChange = (color, colorKey) => {
    const newColors = { ...stackedColors };
    newColors[colorKey] = color.hex;
    setStackedColors(newColors);
    setIsApplied(false);
  };

  // Handle reset button click
  const handleApplied = () => {
    onColorPaletteChange([stackedColors]);
    setIsApplied(true);
  };

  return (
    <CollapsibleContainer title="Color Settings" defaultExpanded={false}>
      <Box sx={{ p: 2, pt: 0 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            alignItems: 'end',
          }}
        >
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              gap: 2,
              flexDirection: 'column',
            }}
          >
            {uniqueColors.map((colorKey) => (
              <Box
                key={colorKey}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography sx={{ mr: 2, width: '100px' }}>
                  {getColorName(colorKey)}
                </Typography>
                <Box sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
                  <Box
                    sx={{
                      width: 56,
                      height: 32,
                      bgcolor: stackedColors[colorKey] || colorKey,
                      margin: '4px',
                      cursor: 'pointer',
                      borderRadius: '4px',
                    }}
                    onClick={() => setShowColorPicker(colorKey)}
                  />
                </Box>
                <Typography sx={{ ml: 2 }}>
                  {stackedColors[colorKey] || colorKey}
                </Typography>
              </Box>
            ))}
          </Box>

          <Button
            variant="outlined"
            onClick={handleApplied}
            sx={{
              height: '42px',
              width: '100px',
              borderRadius: '4px',
              color: theme.palette.accent.blue,
              borderColor: theme.palette.accent.blue,
              '&:hover': {
                borderColor: theme.palette.accent.blue,
                backgroundColor: 'transparent',
              },
            }}
          >
            {isApplied ? 'Applied' : 'Apply'}
          </Button>
        </Box>

        {/* Color Picker Popover */}
        {showColorPicker && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: 2,
              top: 'auto',
              bottom: '70%',
            }}
          >
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
              onClick={() => setShowColorPicker(null)}
            />
            <ChromePicker
              color={stackedColors[showColorPicker] || showColorPicker}
              onChange={(color) => handleStackedColorChange(color, showColorPicker)}
            />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            backgroundColor: '#E2F2FE',
            mt: 3,
            padding: '6px 8px',
            height: '40px',
            width: '100%',
            maxWidth: '600px',
            borderRadius: 1,
          }}
        >
          <InfoOutlinedIcon sx={{ color: '#275273' }} />
          <Typography sx={{ color: '#275273' }}>
            Choose your preferred shades to apply to the visuals
          </Typography>
        </Box>
      </Box>
    </CollapsibleContainer>
  );
};

export default ColorSettingsStackedBar;
