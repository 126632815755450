/* eslint-disable max-len */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  TablePagination,
  Typography,
  IconButton,
  Collapse,
  TextField,
} from '@mui/material';
import {
  SaveIcon,
  CloseIcon,
  EditIcon,
  DeleteIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
} from '../../icons';
import StatusBadge from '../elementaryComponents/StatusBadge';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import {
  useUpdateReportByReportIdMutation,
  useDeleteModelByReportIdMutation,
} from '../../services/api/reportsApi';
import { extractFileNames } from '../../utils/commonFunctions';

const PaginatedAnalysis = ({
  uniqueModels,
  model_status_arr,
  MODEL_STATUS_MAP_OBJ,
  PROCESSING_SUCCESSFULL,
  FAILED,
  setUniqueModels,
  setClientReports,
  clientReports,
}) => {
  const { client_id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [editingModelId, setEditingModelId] = useState(null);
  const [editedModelName, setEditedModelName] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [currentModelId, setCurrentModelId] = useState(null);
  const [dialogDetails, setDialogDetails] = useState({
    title: '',
    description: '',
  });
  const [deleteModelByReportId] = useDeleteModelByReportIdMutation();
  const [updateReportByReportId] = useUpdateReportByReportIdMutation();
  const modelNames = uniqueModels.map((item) => item.data.model_name);
  const [modelNameError, setModelNameError] = useState('');

  const navigate = useNavigate();

  // Pagination
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  // Handle change in the number of rows per page for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate paginated data based on the current page and rows per page
  const paginatedData = [...uniqueModels]
    .sort((a, b) => new Date(b.data.created_at) - new Date(a.data.created_at))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Toggle the expansion of rows in the table
  const toggleRowExpansion = (index) => {
    const updatedExpandedRows = new Set(expandedRows);
    if (updatedExpandedRows.has(index)) {
      updatedExpandedRows.delete(index);
    } else {
      updatedExpandedRows.add(index);
    }
    setExpandedRows(updatedExpandedRows);
  };

  //  Model name Edit
  const handleEditModelName = (model_id, model_name) => {
    setEditingModelId(model_id);
    setEditedModelName(model_name);
  };

  // Update the model's name after editing
  const handleUpdateModelName = async (report_id) => {
    const updatedModels = uniqueModels.map((model) => {
      if (model?.data?.report_id === report_id) {
        return {
          ...model,
          data: {
            ...model.data,
            model_name: editedModelName,
          },
        };
      }
      return model;
    });
    await updateReportByReportId({
      report_id,
      updatedReport: { model_name: editedModelName },
    });
    setUniqueModels(updatedModels);
    setEditingModelId(null);
  };

  // Cancel the edit operation
  const handleCancelEdit = () => {
    setEditingModelId(null);
    setEditedModelName('');
  };

  //  Model Deletion
  const handleModelDelete = (current_model_id) => {
    setDialogDetails({
      title: 'Are you sure you want to delete this model?',
      description: '',
    });
    setCurrentModelId(current_model_id);
    setDeleteConfirmation(true);
  };

  // Handle the deletion of a model after confirmation
  const handleDeleteConfirmation = async (delete_confirmed) => {
    if (delete_confirmed) {
      try {
        await deleteModelByReportId(currentModelId).unwrap();
        const updatedUniqueModels = uniqueModels.filter(
          (uniqueModel) => uniqueModel?.data?.report_id !== currentModelId,
        );
        setUniqueModels(updatedUniqueModels);

        const updatedClientReports = clientReports.filter(
          (clientReport) => clientReport?.data?.report_id !== currentModelId,
        );
        setClientReports(updatedClientReports);
      } catch (error) {
        console.error('Failed to delete the model:', error);
      }
    }
  };

  const goToVisuals = (report_id, analysis_name) => {
    navigate(`/client-data/${client_id}/report-analysis/${report_id}/${analysis_name}`);
  };

  return (
    <>
      {/* Display message if no models are found */}
      {!uniqueModels || uniqueModels.length === 0 ? (
        <Typography>No reports found</Typography>
      ) : (
        <>
          {/* Header for the table */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{
              borderBottom: '1px solid #ccc',
              padding: '6px 1rem',
            }}
          >
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 500, fontSize: '14px', width: '40%' }}
            >
              Name
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 500, fontSize: '14px', width: '20%' }}
            >
              Status
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 500, fontSize: '14px', width: '20%' }}
            >
              Created On
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 500,
                fontSize: '14px',
                width: '20%',
                textAlign: 'center',
              }}
            >
              Actions
            </Typography>
          </Box>

          {/* Render each report in the paginated data */}
          {paginatedData.map((clientReport, index) => {
            const {
              model_id = null, model_name = 'Sample Modal', report_id, model_status,
            } = clientReport?.data || {};
            const modelStatus = model_status_arr.find(
              (status) => status === clientReport?.data?.model_status,
            );
            const model_status_display_text = MODEL_STATUS_MAP_OBJ[modelStatus];
            const activeVisualizationButton = modelStatus === PROCESSING_SUCCESSFULL;
            const activeDeleteButton = [
              PROCESSING_SUCCESSFULL,
              FAILED,
            ].includes(modelStatus);
            const model_input_data = clientReport?.data?.model_input_data || [];
            const fileNamesUsedInModal = extractFileNames(model_input_data);

            return (
              <Box
                key={model_id}
                display="flex"
                flexDirection="column"
                style={{
                  backgroundColor: index % 2 === 0 ? '#F8F8F8' : 'transparent',
                  color: '#262626',
                  borderRadius: '8px',
                  padding: '0 1rem 0.5rem 1rem',
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box width="40%">
                    {((editingModelId === model_id) && activeDeleteButton) ? (
                      <>
                        <TextField
                          value={editedModelName}
                          onChange={(e) => {
                            setEditedModelName(e.target.value);
                            // Clear error when user starts typing
                            setModelNameError('');
                            // Show error if name already exists (excluding current model)
                            if (modelNames.filter((name) => name !== model_name).includes(e.target.value.trim())) {
                              setModelNameError('Model name already exists');
                            }
                          }}
                          size="small"
                          variant="standard"
                          style={{ minWidth: '250px' }}
                          error={!!modelNameError}
                          helperText={modelNameError}
                        />
                        <IconButton
                          onClick={() => handleUpdateModelName(clientReport?.data?.report_id)}
                          disabled={!!modelNameError || !editedModelName.trim()}
                        >
                          <SaveIcon style={{ color: modelNameError || !editedModelName.trim() ? '#00000061' : '#3988C6' }} />
                        </IconButton>
                        <IconButton onClick={handleCancelEdit}>
                          <CloseIcon />
                        </IconButton>
                      </>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          style={{ color: '#000000DE', fontSize: '14px' }}
                        >
                          {model_name}
                        </Typography>
                        <IconButton
                          onClick={() => handleEditModelName(model_id, model_name)}
                          aria-label="Edit model"
                          sx={{
                            '&:hover': {
                              color: '#3988C6',
                            },
                          }}
                          disabled={!activeDeleteButton}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                  <Box width="20%">
                    <StatusBadge status={model_status_display_text} />
                  </Box>
                  <Typography
                    width="20%"
                    style={{ color: '#3E3E3E', fontSize: '14px' }}
                  >
                    {new Intl.DateTimeFormat('en-US').format(
                      new Date(clientReport?.data?.created_at || clientReport?.data?.data?.created_at),
                    )}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    width="20%"
                  >
                    <Typography
                      onClick={() => goToVisuals(report_id, model_name)}
                      style={{
                        cursor: 'pointer',
                        color: '#3988C6',
                        pointerEvents: !activeVisualizationButton && 'none',
                        opacity: activeVisualizationButton ? '1' : '0.4',
                        fontSize: '14px',
                        fontWeight: 500,
                        minWidth: 'max-content',
                        marginRight: '1rem',
                      }}
                    >
                      VISUALIZATIONS
                    </Typography>
                    <IconButton
                      onClick={() => handleModelDelete(clientReport?.data?.report_id)}
                      aria-label="Delete model"
                      disabled={!activeDeleteButton}
                    >
                      <DeleteIcon
                        style={{
                          color: activeDeleteButton ? '#3988C6' : '#00000061',
                        }}
                      />
                    </IconButton>
                  </Box>
                </Box>

                {/* Modal Files */}
                <Box paddingRight="60%" mt="-4px">
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    {activeDeleteButton ? (
                      <Typography variant="body2" sx={{ fontSize: '12px' }}>
                        {fileNamesUsedInModal.length}
                        {' '}
                        Documents used in this
                        analysis:
                      </Typography>
                    ) : (
                      <Typography variant="body2" sx={{ fontSize: '12px' }}>
                        Document details will be displayed here once the model is processed.
                      </Typography>
                    )}
                    <IconButton
                      onClick={() => toggleRowExpansion(index)}
                      style={{ padding: 0 }}
                    >
                      {expandedRows.has(index) ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse
                    in={expandedRows.has(index)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box paddingLeft="0.25rem">
                      <ul
                        style={{
                          margin: 0,
                          padding: '0.5rem 1rem',
                          maxHeight: '50px',
                          overflow: 'scroll',
                          width: '100%',
                        }}
                      >
                        {fileNamesUsedInModal.map((file_name) => (
                          <li key={file_name} style={{ fontSize: '12px' }}>
                            {file_name}
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Collapse>
                </Box>
              </Box>
            );
          })}

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={uniqueModels.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      {/* Delete confirmation dialog */}
      {deleteConfirmation && (
        <ConfirmationDialog
          description={dialogDetails?.description}
          title={dialogDetails?.title}
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          handleConfirmation={handleDeleteConfirmation}
        />
      )}
    </>
  );
};

export default PaginatedAnalysis;
