import { ResponsivePie } from '@nivo/pie';
import React from 'react';
import getNivoCustomFontTheme from '../common/CustomFontsTheme';

const sentimentColors = ['#ACE1AF', '#C0C0C0', '#E07A5F'];
const emotionColors = [
  '#9bcc9b', // joy
  '#fff6cf', // optimism
  '#a3c9f5', // trust
  '#f7b0d1', // curiosity
  '#c8a3d1', // anticipation
  '#b8c3cc', // confusion
  '#f78a8a', // anger
  '#a3b0c3', // disapproval
  '#b8b0a8', // disappointment
  '#b8d1c3', // anxiety
];

const NivoPieChart = ({ data, customConfig = {}, chart_type }) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <p>No data available for the chart.</p>;
  }
  const selectedFont = customConfig.generalSettings.font;
  const colors = (customConfig.colorPalette.length > 0 && customConfig.colorPalette) || (chart_type === 'donut-pie-sentiment' ? sentimentColors : emotionColors);

  const {
    innerRadius = '0.1',
    outerRadius = '0.5',
    showValues = true,
    sortByValue = true,
  } = customConfig.generalSettings;

  return (
    <ResponsivePie
      data={data}
      colors={colors}
      theme={getNivoCustomFontTheme(selectedFont)}
      sortByValue={sortByValue}
      enableArcLabels={showValues}
      margin={customConfig.layout}
      legends={[customConfig.legends]}
      innerRadius={innerRadius / 10}
      padAngle={0.7}
      activeOuterRadiusOffset={outerRadius / 10}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsThickness={4}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={18}
      arcLabel={(d) => `${d.value}%`}
    />
  );
};

export default NivoPieChart;
