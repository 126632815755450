import React from 'react';
import { ResponsiveHeatMap } from '@nivo/heatmap';
import getNivoCustomFontTheme from '../common/CustomFontsTheme';

const NivoHeatMap = ({ data, customConfig, data_source = 'survey' }) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <p>No data available for the heatmap.</p>;
  }
  const selectedFont = customConfig.generalSettings.font;
  return (
    <ResponsiveHeatMap
      data={data}
      theme={getNivoCustomFontTheme(selectedFont)}
      margin={customConfig.layout}
      valueFormat=".2f"
      enableLabels={customConfig.generalSettings.enableLabels}
      axisTop={{
        tickSize: 5,
        tickPadding: 15,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      colors={{
        type: 'diverging',
        scheme: customConfig.colorPalette[0] || 'red_yellow_blue',
        divergeAt: 0.4,
        minValue: data_source === 'Survey' ? -1 : 0,
        maxValue: 1,
      }}
      emptyColor="#555555"
      legends={[customConfig.legends]}
    />
  );
};

export default NivoHeatMap;
