import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import VisualCard from './VisualCard';
import RenderChart from '../charts/RenderChart';
import DynamicTable from '../charts/nivoComponent/DynamicTable';
import VisualizationLibraryFilter from '../charts/common/VisualizationLibraryFilter';
import VisualLoader from '../charts/common/VisualLoader';

const DefaultVisualizationLibrary = ({ visualsData, isFetching }) => {
  const [filteredVisuals, setFilteredVisuals] = useState(visualsData);

  useEffect(() => {
    setFilteredVisuals(visualsData);
  }, [visualsData]);

  if (isFetching) return <VisualLoader message="Loading default visuals..." />;

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
      >
        <VisualizationLibraryFilter
          data={visualsData}
          setFilteredVisuals={setFilteredVisuals}
        />
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        width="100%"
      >
        {filteredVisuals.map((visual) => {
          const {
            chart_id, chart_type, chart_data, pinned, visual_id, report_id, customizations,
          } = visual;
          if (!chart_data) return null;
          const { chart_title = '', description = '' } = chart_data || {};
          const font_family = customizations?.generalSettings?.font;

          return (
            <VisualCard
              key={chart_id}
              title={chart_title}
              isSelected={false}
              type="default"
              chart_type={chart_type}
              report_id={report_id}
              visual_id={visual_id}
              pinned={pinned}
              font_family={font_family}
              chart_data={chart_data}
              chart_description={description}
            >
              {chart_type === 'table' ? (
                <DynamicTable
                  chartData={visual.chart_data}
                />
              ) : (
                <RenderChart
                  chartData={visual.chart_data}
                  chart_type={chart_type}
                  customizations={visual.customizations}
                />
              )}
            </VisualCard>
          );
        })}
      </Box>
    </>
  );
};

export default DefaultVisualizationLibrary;
