import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import FilterBar from '../charts/common/FilterBar';
import { CustomHeatMap, CustomPieChart } from '../charts';
import CustomTableChart from '../charts/CustomTableChart';
import CustomSingleBarChart from '../charts/CustomSingleBarChart';
import CustomGroupedBarChart from '../charts/CustomGroupedBarChart';
import CustomStackedBarChart from '../charts/CustomStackedBarChart';

const AddCustomVisualization = ({ visualsData, isEditing = false }) => {
  const [selectedVisual, setSelectedVisual] = useState({});
  const [isTableSelected, setIsTableSelected] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (isTableSelected) return;
    if (isEditing && visualsData) {
      setSelectedVisual(visualsData[0]);
    } else {
      const visualId = searchParams.get('visual_id');
      if (visualId && visualsData?.length) {
        const foundVisual = visualsData.find((visual) => visual.visual_id === visualId);
        if (foundVisual) {
          setSelectedVisual(foundVisual);
        }
      }
    }
  }, [isEditing, visualsData]);

  const handleVisualSelection = (visual) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('visual_id', visual.visual_id);
    setSearchParams(newParams);
    setSelectedVisual(visual);
    if (visual.chart_type === 'table') {
      setIsTableSelected(true);
    }
  };

  const renderChart = () => {
    if (!selectedVisual || Object.keys(selectedVisual).length === 0) return null;
    switch (selectedVisual.chart_type) {
      case 'bar-topic':
      case 'bar-count':
        return (
          <CustomSingleBarChart
            chartDataDetails={selectedVisual}
            barType="bar"
          />
        );
      case 'stacked':
        return (
          <CustomStackedBarChart
            chartDataDetails={selectedVisual}
            barType={selectedVisual?.chart_type}
          />
        );
      case 'grouped':
        return (
          <CustomGroupedBarChart
            chartDataDetails={selectedVisual}
            barType={selectedVisual?.chart_type}
          />
        );
      case 'heatmap-intensity':
      case 'heatmap-correlation':
        return (
          <CustomHeatMap
            chartDataDetails={selectedVisual}
          />
        );
      case 'donut-pie-sentiment':
      case 'donut-pie-emotion':
        return (
          <CustomPieChart
            chartDataDetails={selectedVisual}
          />
        );
      case 'table':
        return (
          <CustomTableChart
            chartDataDetails={selectedVisual}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      {!isEditing && (
        <FilterBar
          data={visualsData}
          setSelectedVisual={handleVisualSelection}
          selectedVisual={selectedVisual}
        />
      )}
      {renderChart()}
    </Box>
  );
};

export default AddCustomVisualization;
