import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import UploadFeedback from './UploadFeedback';
import {
  UPLOADING,
  UPLOADED,
  FAILED,
  PROCESSING,
  SUCCESSFULL,
} from '../../data/const';
import { sortByTimeStamp } from '../../utils/commonFunctions';
import theme from '../../styles/material_ui_theme';
import { DeleteIcon } from '../../icons';

const accentBlue = theme.palette.accent.blue;

const DataDisplayWithCheckbox = ({
  data,
  titleId,
  rowSelectedStatus,
  actions = [],
  showEmailCount = false,
}) => {
  const removeFiles = actions.find((action) => action.actionType === 'remove');
  const files = sortByTimeStamp(data?.files || [], 'file_name');
  const [rowCheckedStatus, setRowCheckedStatus] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const removeableFileStatuses = [
    UPLOADED,
    FAILED,
    SUCCESSFULL,
    PROCESSING,
    null,
  ];

  useEffect(() => {
    if (removeFiles) {
      const checkedBoxes = [];
      files.forEach((file) => {
        checkedBoxes.push({
          isChecked: false,
          ...file,
        });
      });
      setRowCheckedStatus(checkedBoxes);
    }
    // eslint-disable-next-line
  }, [data?.files]);

  useEffect(() => {
    if (!removeFiles) {
      const checkedBoxes = [];
      files.forEach((file) => {
        checkedBoxes.push({
          isChecked: false,
          ...file,
        });
      });
      setRowCheckedStatus(checkedBoxes);
    }
    // eslint-disable-next-line
  }, [data?.files?.length]);

  const handleFileDelete = (file_s3_url, file_name, file_id) => {
    if (removeFiles.callBackFunction) {
      removeFiles.callBackFunction(file_s3_url, file_name, file_id);
    }
  };

  const areAllRowsChecked = (rowCheckedArr) => {
    const successfulUpload = rowCheckedArr?.filter(
      (row) => row?.file_status !== FAILED,
    );
    return successfulUpload.every(
      (rowDetails) => rowDetails.isChecked === true,
    );
  };

  const handleAllCheckboxChange = () => {
    const updatedRowChecked = rowCheckedStatus.map((row) => ({
      ...row,
      isChecked: !allChecked && row.file_status !== FAILED,
    }));
    setRowCheckedStatus(updatedRowChecked);
    setAllChecked(!allChecked);
    rowSelectedStatus(updatedRowChecked, titleId);
  };

  const handleCheckboxChange = (file, isChecked) => {
    const updatedRowChecked = rowCheckedStatus.map((row) => (row.file_s3_url === file.file_s3_url
      ? { ...row, isChecked: !isChecked }
      : row));

    if (areAllRowsChecked(updatedRowChecked)) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
    setRowCheckedStatus(updatedRowChecked);
    rowSelectedStatus(updatedRowChecked, titleId);
  };

  return (
    <Box style={{ margin: '1rem 0' }}>
      {rowCheckedStatus.length === files.length && files?.length > 0 && (
        <Typography
          variant="h3"
          component="span"
          style={{
            cursor: 'pointer',
            font: 'normal normal normal 12px/32px Helvetica Neue',
            letterSpacing: '0px',
            color: theme.palette.primary.dark,
            textAlign: 'center',
            textDecoration: 'underline',
            marginLeft: '10px',
          }}
          onClick={() => handleAllCheckboxChange()}
        >
          {allChecked ? 'Deselect All' : 'Select all'}
        </Typography>
      )}
      <FormGroup>
        {rowCheckedStatus.length === files.length
          && files?.length > 0
          && sortByTimeStamp(files, 'file_name').map((file, index) => {
            const {
              file_name,
              file_s3_url,
              file_status,
              file_id,
              upload_status,
              upload_status_message,
              error_message,
            } = file;
            const isChecked = rowCheckedStatus?.[index].isChecked;

            return (
              <Box
                style={{
                  backgroundColor:
                    files.length === 1
                      ? '#F8F8F8'
                      : index % 2 !== 0
                        ? '#F8F8F8'
                        : 'transparent',
                  color: '#262626',
                  borderRadius: '8px',
                  font: 'normal normal normal 16px/32px Helvetica Neue',
                  padding: '0 8px 0 16px',
                  boxSizing: 'border-box',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                key={`${file_id}-${file_name}`}
              >
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isChecked || false}
                      style={{ marginRight: '0.5rem' }}
                      disabled={
                        file_status === UPLOADING || file_status === FAILED
                      }
                    />
                  )}
                  label={file_name}
                  key={file_id}
                  onChange={() => handleCheckboxChange(file, isChecked)}
                />
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <UploadFeedback
                    file={file}
                    file_status={file_status}
                    upload_status={upload_status}
                    upload_status_message={upload_status_message}
                    error_message={error_message}
                  />
                  {removeFiles
                    && removeableFileStatuses.includes(file_status) && (
                      <IconButton
                        aria-label="delete"
                        style={{ color: accentBlue }}
                        onClick={() => handleFileDelete(file_s3_url, file_name, file_id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                  )}
                </Box>
              </Box>
            );
          })}
      </FormGroup>
    </Box>
  );
};

export default DataDisplayWithCheckbox;
