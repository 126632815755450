import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { InfoOutlinedIcon, UploadFileIcon } from '../../icons';

const CustomUploadButton = ({
  buttonText, customButtonStyle = {}, onClickHandler, disabled = false, acceptedFileTypes,
}) => {
  const allAcceptedFileTypes = acceptedFileTypes.map((fileType) => fileType.toUpperCase());
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      onClickHandler(e.target.files);
    }
    e.target.value = null;
  };

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#F8F8F8',
        borderRadius: '8px',
        padding: '0 0.5rem 0rem 1rem',
      }}
    >
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <InfoOutlinedIcon />
        <Typography
          style={{
            color: '#0B1E36',
            marginLeft: '1rem',
            fontSize: '14px',
          }}
        >
          Please make sure the file type is
          {' '}
          {allAcceptedFileTypes?.join(', ')}
          {' '}
          and max size is 10MB.
        </Typography>
      </Box>
      <Button
        component="label"
        variant="text"
        disabled={disabled}
        startIcon={<UploadFileIcon style={{ color: '#D32F2F' }} />}
        style={{
          padding: '0px 8px',
          boxSizing: 'border-box',
          font: 'normal normal medium 14px/16px Roboto',
          color: '#D32F2F',
          letterSpacing: '1.25px',
          height: '35px',
          borderRadius: '4px',
          textTransform: 'uppercase',
          minWidth: 'max-content',
          opacity: disabled ? '0.6' : '1',
          cursor: disabled ? 'not-allowed' : 'pointer',
          ...customButtonStyle,
        }}
      >
        {buttonText}
        <input
          type="file"
          onChange={(e) => handleFileChange(e)}
          accept={acceptedFileTypes?.join(',')}
          hidden
        />
      </Button>
    </Box>
  );
};

export default CustomUploadButton;
