/* eslint-disable no-shadow */
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import getNivoCustomFontTheme from '../common/CustomFontsTheme';
import { DEFAULT_DATA_COLORS } from '../../../data/const';

const NivoGroupedBarChart = ({
  chart_type,
  customConfig = {},
  indexBy = 'filename',
  keys = [],
}) => {
  const data = customConfig.customData || [];
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <p>No data available for the chart.</p>;
  }
  const selectedFont = customConfig.generalSettings.font;
  const { graphDirection } = customConfig.generalSettings;
  const customColors = customConfig.colorPalette.length > 0
    ? customConfig.colorPalette
    : DEFAULT_DATA_COLORS;

  // Merge default configuration with provided config
  const defaultConfig = {
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: graphDirection === 'vertical' ? 10 : 0,
      legend: graphDirection === 'vertical' ? indexBy : 'Values',
      legendPosition: 'middle',
      legendOffset: 50,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: graphDirection === 'vertical' ? 'Values' : indexBy,
      legendPosition: 'middle',
      legendOffset: -50,
    },
    labelTextColor: { from: 'color', modifiers: [['darker', 2]] },
  };

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      colors={customColors}
      theme={getNivoCustomFontTheme(selectedFont)}
      indexBy={indexBy}
      layout={customConfig.generalSettings?.graphDirection}
      margin={customConfig.layout}
      padding={0.2}
      enableTotals={customConfig.generalSettings?.showTotals}
      valueFormat={(value) => Number(value)}
      axisBottom={defaultConfig.axisBottom}
      axisLeft={defaultConfig.axisLeft}
      enableLabel={customConfig.generalSettings?.showBarLabel}
      enableGridY={customConfig.generalSettings?.showGridY}
      enableGridX={customConfig.generalSettings?.showGridX}
      labelSkipWidth={3}
      labelSkipHeight={4}
      labelTextColor={defaultConfig.labelTextColor}
      legends={customConfig.legends ? [customConfig.legends] : []}
      groupMode={chart_type}
      borderRadius={customConfig.generalSettings?.borderRadius || 0}
      borderWidth={customConfig.generalSettings?.borderWidth || 0}
      borderColor={
        defaultConfig.borderColor || {
          from: 'color',
          modifiers: [['darker', 1.6]],
        }
      }
      role="application"
      ariaLabel="Nivo bar chart"
      barAriaLabel={(d) => `${d.id}: ${d.formattedValue} in ${d.indexValue}`}
    />
  );
};

export default NivoGroupedBarChart;
