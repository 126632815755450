import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  TablePagination,
} from '@mui/material';

import {
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  getComparator,
} from '../../utils/tableUtils';
import theme from '../../styles/material_ui_theme';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { useDeleteClientByIdMutation } from '../../services/api/clientsApi';
import { DeleteIcon, VisibilityIcon } from '../../icons';

const accentBlue = theme.palette.accent.blue;

const ClientListTable = ({ data, setClientListData }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openConfirmationDailog, setOpenConfirmationDailog] = useState(false);
  const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('');
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [deleteClientById] = useDeleteClientByIdMutation();

  const navigate = useNavigate();

  const handleClientRemove = (client) => {
    setOpenConfirmationDailog(true);
    setSelectedClientId(client.client_id);
    setConfirmationDialogTitle(`Are you sure you want to remove ${client.client_name} ?`);
  };

  const handleConfirmation = async (action_response) => {
    if (!action_response) {
      return;
    }

    const optimisticUpdate = data.filter((client) => client.data.client_id !== selectedClientId);
    setClientListData(optimisticUpdate);

    try {
      await deleteClientById(selectedClientId).unwrap();
    } catch (error) {
      setClientListData(data);
      console.error('Error deleting client. Please try again later.');
    }
  };

  const sortedData = [...data].sort(getComparator(order, orderBy));
  const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                tabIndex={0}
                sx={{ borderBottom: 'none' }}
                style={{ minWidth: '200px' }}
              >
                <TableSortLabel
                  active={orderBy === 'client_name'}
                  direction={orderBy === 'client_name' ? order : 'asc'}
                  onClick={() => handleRequestSort('client_name', orderBy, order, setOrderBy, setOrder)}
                >
                  Client Name
                </TableSortLabel>
              </TableCell>
              <TableCell tabIndex={0} sx={{ borderBottom: 'none' }} align="center" style={{ width: '160px' }}>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? order : 'asc'}
                  onClick={() => handleRequestSort('created_at', orderBy, order, setOrderBy, setOrder)}
                >
                  Created
                </TableSortLabel>
              </TableCell>
              <TableCell tabIndex={0} sx={{ borderBottom: 'none' }} align="center" style={{ width: '160px' }}>
                <TableSortLabel
                  active={orderBy === 'modified_at'}
                  direction={orderBy === 'modified_at' ? order : 'asc'}
                  onClick={() => handleRequestSort('modified_at', orderBy, order, setOrderBy, setOrder)}
                >
                  Last Updated
                </TableSortLabel>
              </TableCell>
              <TableCell tabIndex={0} sx={{ borderBottom: 'none' }} align="center" style={{ width: '160px' }}>
                <TableSortLabel
                  active={orderBy === 'last_accessed'}
                  direction={orderBy === 'last_accessed' ? order : 'asc'}
                  onClick={() => handleRequestSort('last_accessed_at', orderBy, order, setOrderBy, setOrder)}
                >
                  Last Accessed
                </TableSortLabel>
              </TableCell>
              <TableCell tabIndex={0} sx={{ borderBottom: 'none' }} align="center" style={{ width: '120px' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((client, index) => (
              <TableRow key={client.data.client_id} style={{ backgroundColor: index % 2 ? 'white' : '#F8F8F8', outline: 'none' }}>
                <TableCell tabIndex={0} sx={{ borderBottom: 'none' }} component="th" scope="row" style={{ minWidth: '200px' }}>
                  {client.data.client_name}
                </TableCell>
                <TableCell tabIndex={0} sx={{ borderBottom: 'none' }} align="center" style={{ width: '160px' }}>
                  {new Date(client.data.created_at).toLocaleDateString()}
                </TableCell>
                <TableCell tabIndex={0} sx={{ borderBottom: 'none' }} align="center" style={{ width: '160px' }}>
                  {client.data.modified_at ? new Date(client.data.modified_at).toLocaleDateString() : '-'}
                </TableCell>
                <TableCell tabIndex={0} sx={{ borderBottom: 'none' }} align="center" style={{ width: '160px' }}>
                  {client.data.last_accessed_at ? new Date(client.data.last_accessed_at).toLocaleDateString() : '-'}
                </TableCell>
                <TableCell sx={{ borderBottom: 'none' }} align="center" style={{ width: '120px', display: 'flex', justifyContent: 'space-around' }}>
                  <IconButton
                    aria-label="view"
                    onClick={() => navigate(`/client-data/${client.data.client_id}`)}
                    style={{ color: accentBlue }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    style={{ color: accentBlue }}
                    onClick={() => handleClientRemove(client.data)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => handleChangePage(event, newPage, setPage)}
          onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setRowsPerPage, setPage)}
        />
      </TableContainer>
      {openConfirmationDailog && (
      <ConfirmationDialog
        title={confirmationDialogTitle}
        open={openConfirmationDailog}
        setOpen={(value) => setOpenConfirmationDailog(value)}
        handleConfirmation={(value) => handleConfirmation(value)}
      />
      )}
    </>
  );
};

export default ClientListTable;
