/* eslint-disable max-len */
import React from 'react';
import {
  Box, Select, MenuItem, FormControl, InputLabel, Button,
  Typography, Checkbox, ListItemText,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { InfoOutlinedIcon } from '../../../icons';

const FilterBar = ({ data, setSelectedVisual, selectedVisual }) => {
  const location = useLocation();

  const [selectedDataSource, setSelectedDataSource] = React.useState('');
  const [selectedChartType, setSelectedChartType] = React.useState('');
  const [selectedTopic, setSelectedTopic] = React.useState('');
  const [selectedUserIds, setSelectedUserIds] = React.useState([]);

  const handleDataSourceChange = (value) => {
    setSelectedDataSource(value);
    setSelectedChartType('');
    setSelectedTopic('');
    setSelectedUserIds([]);
  };

  const handleChartTypeChange = (value) => {
    setSelectedChartType(value);
    setSelectedTopic('');
    setSelectedUserIds([]);
  };

  const handleTopicChange = (value) => {
    setSelectedTopic(value);
  };

  const isTableChart = React.useMemo(() => selectedChartType === 'table', [selectedChartType]);
  const isDonutChart = React.useMemo(() => selectedChartType?.includes('donut-pie'), [selectedChartType]);

  const availableDataSources = React.useMemo(() => {
    return [...new Set(data.map((item) => item.chart_data?.data_source).filter(Boolean))];
  }, [data]);

  const availableChartTypes = React.useMemo(() => {
    if (!selectedDataSource) return [];
    return [...new Set(data
      .filter((item) => item.chart_data?.data_source === selectedDataSource)
      .map((item) => item.chart_type)
      .filter(Boolean))];
  }, [selectedDataSource, data]);

  const availableTopics = React.useMemo(() => {
    if (!selectedChartType) return [];
    return [...new Set(data
      .filter((item) => item.chart_type === selectedChartType
        && (!selectedDataSource || item.chart_data?.data_source === selectedDataSource))
      .map((item) => item.topic)
      .filter(Boolean))];
  }, [selectedChartType, selectedDataSource, data]);

  const availableUserIDs = React.useMemo(() => {
    if (!isTableChart || !selectedChartType) return [];
    const tableData = data.find((item) => item.chart_type === 'table'
      && (!selectedDataSource || item.chart_data?.data_source === selectedDataSource));
    return tableData ? [...new Set(tableData.chart_data.data.map((row) => row.user_id))] : [];
  }, [isTableChart, selectedChartType, selectedDataSource, data]);

  const handleUserIdsChange = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      setSelectedUserIds(selectedUserIds.length === availableUserIDs.length ? [] : availableUserIDs);
      return;
    }
    setSelectedUserIds(value);
  };

  const handleCreate = React.useCallback(() => {
    const selectedVisualToCreate = data.find((item) => {
      const matchesType = item.chart_type === selectedChartType;
      const matchesSource = !selectedDataSource || item.chart_data?.data_source === selectedDataSource;
      const matchesTopic = !selectedTopic || item.topic === selectedTopic;
      const matchesUserIds = !selectedUserIds.length || (isTableChart && item.chart_data?.data.some((row) => selectedUserIds.includes(row.user_id)));

      return matchesType && matchesSource && matchesTopic && matchesUserIds;
    });

    if (selectedVisualToCreate) {
      if (isTableChart && selectedUserIds.length > 0) {
        const filteredData = {
          ...selectedVisualToCreate,
          chart_data: {
            ...selectedVisualToCreate.chart_data,
            data: selectedVisualToCreate.chart_data.data.filter((row) => selectedUserIds.includes(row.user_id)),
          },
        };
        setSelectedVisual(filteredData);
      } else {
        setSelectedVisual(selectedVisualToCreate);
      }
    }
  }, [data, selectedChartType, selectedDataSource, selectedTopic, selectedUserIds, isTableChart, setSelectedVisual]);

  const isCreateDisabled = React.useMemo(() => {
    return !selectedChartType
           || (isDonutChart && !selectedTopic)
           || (isTableChart && !selectedUserIds.length);
  }, [selectedChartType, isDonutChart, isTableChart, selectedTopic, selectedUserIds]);

  // Effect to load filter states from URL if chart_id is present
  React.useEffect(() => {
    const currentParams = new URLSearchParams(location.search);
    const chartIdFromUrl = currentParams.get('chart_id');

    if (chartIdFromUrl) {
      const matchedItem = data.find((item) => item.chart_id === chartIdFromUrl);

      if (matchedItem) {
        const dataSource = matchedItem.data_source;
        const chartType = matchedItem.chart_type;
        setSelectedDataSource(dataSource);
        setSelectedChartType(chartType);

        // If donut chart, set topic if available
        if (chartType?.includes('donut') && matchedItem.topic) {
          setSelectedTopic(matchedItem.topic);
        }

        // If table chart, set user_id if available
        if (chartType === 'table' && matchedItem.data?.length > 0) {
          setSelectedUserIds([matchedItem.data[0].user_id]);
        }

        // After setting the state, update the visuals
        Promise.resolve().then(handleCreate);
      }
    }
  }, [data, location.search]);

  return (
    <Box
      sx={{
        gap: 2,
        padding: '16px',
        borderRadius: '8px 8px 0 0',
        marginBottom: '1px',
        boxShadow: '0px 3px 6px 0px #00000029',
        backgroundColor: '#FFFFFF',
      }}
    >
      {(!selectedVisual || Object.keys(selectedVisual).length === 0) && (
        <Box style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '12px',
          background: '#E2F2FE',
          padding: '0.5rem',
          marginBottom: '1rem',
          borderRadius: '4px',
        }}
        >
          <InfoOutlinedIcon />
          <Typography
            style={{
              color: '#0B1E36',
              marginLeft: '1rem',
              fontSize: '14px',
            }}
          >
            Choose from the options below to get started with creating your custom visual.
          </Typography>
        </Box>
      )}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 2,
      }}
      >
        {/* Data Source (Single Select) */}
        <FormControl sx={{ width: '20%' }}>
          <InputLabel>Data Source</InputLabel>
          <Select
            variant="standard"
            value={selectedDataSource}
            onChange={(e) => handleDataSourceChange(e.target.value)}
            size="small"
            label="Data Source"
          >
            {availableDataSources.map((source) => (
              <MenuItem key={source} value={source}>
                {source}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Chart Type (Single Select; disabled if no data source) */}
        <FormControl sx={{ width: '20%' }} disabled={!selectedDataSource}>
          <InputLabel>Chart Type</InputLabel>
          <Select
            variant="standard"
            value={selectedChartType}
            onChange={(e) => handleChartTypeChange(e.target.value)}
            size="small"
            label="Chart Type"
          >
            {availableChartTypes.map((chartType) => (
              <MenuItem key={chartType} value={chartType}>
                {chartType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Topics (Single Select; Only if donut chart) */}
        <FormControl sx={{ width: '20%' }} disabled={!selectedChartType || !isDonutChart || availableTopics.length === 0}>
          <InputLabel>Topics</InputLabel>
          <Select
            variant="standard"
            value={selectedTopic}
            onChange={(e) => handleTopicChange(e.target.value)}
            size="small"
            label="Topics"
          >
            {availableTopics.map((topic) => (
              <MenuItem key={topic} value={topic}>
                {topic}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* User IDs (Single Select; Only if table chart) */}
        <FormControl sx={{ width: '20%' }} disabled={!selectedChartType || !isTableChart || availableUserIDs.length === 0}>
          <InputLabel>User IDs</InputLabel>
          <Select
            variant="standard"
            multiple
            value={selectedUserIds}
            onChange={handleUserIdsChange}
            size="small"
            label="User IDs"
            renderValue={(selected) => selected.join(', ')}
          >
            <MenuItem value="all">
              <Checkbox
                checked={selectedUserIds.length === availableUserIDs.length}
                indeterminate={selectedUserIds.length > 0 && selectedUserIds.length < availableUserIDs.length}
              />
              <ListItemText primary="Select All" />
            </MenuItem>
            {availableUserIDs.map((userID) => (
              <MenuItem key={userID} value={userID}>
                <Checkbox checked={selectedUserIds.includes(userID)} />
                <ListItemText primary={userID} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Update Button */}
        <Button
          variant="contained"
          color="error"
          size="small"
          sx={{
            padding: '8px 24px',
            fontWeight: 'bold',
            borderColor: '#CC342E',
            '&:hover': {
              borderColor: '#FFF5F5',
              backgroundColor: '#CC342E',
            },
          }}
          onClick={handleCreate}
          disabled={isCreateDisabled}
        >
          CREATE
        </Button>
      </Box>
    </Box>
  );
};

export default FilterBar;
