import React from 'react';
import { Box } from '@mui/material';

const StatusBadge = ({ status }) => {
  // Define color mapping for different statuses
  const statusColors = {
    processed: '#2E7D32',
    failed: '#D32F2F',
  };

  // Get the color based on the status
  const color = statusColors[status.toLowerCase()] || '#000';

  const styles = {
    display: 'inline-block',
    padding: '2px 6px',
    borderRadius: '15px',
    border: `1px solid ${color}`,
    color,
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '14px',
    minWidth: '100px',
  };

  return <Box style={styles}>{status}</Box>;
};

export default StatusBadge;
