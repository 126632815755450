import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: async (headers) => {
    const id_token = localStorage.getItem('id_token');
    if (id_token) {
      headers.set('Authorization', `Bearer ${id_token}`);
    }
    return headers;
  },
});

const apiService = createApi({
  reducerPath: 'apiService',
  baseQuery,
  endpoints: () => ({}),
});

export default apiService;
