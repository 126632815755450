import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import getNivoCustomFontTheme from '../common/CustomFontsTheme';

const CustomTooltip = ({ id, value, indexValue }) => (
  <div
    style={{
      padding: '8px 12px',
      background: 'white',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    }}
  >
    {id}
    -
    {indexValue}
    :
    {' '}
    <strong>{value}</strong>
    %
  </div>
);

const NivoStackedBarChart = ({
  chart_type,
  customConfig = {},
  indexBy = 'filename',
  keys = [],
  colors = [],
}) => {
  const data = customConfig.customData || [];
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <p>No data available for the chart.</p>;
  }

  const graphDirection = customConfig.generalSettings?.graphDirection;
  const selectedFont = customConfig.generalSettings.font;
  // Merge default configuration with provided config
  const defaultConfig = {
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: graphDirection === 'vertical' ? 10 : 0,
      legend: graphDirection === 'horizontal' ? 'Percentage' : indexBy,
      legendPosition: 'middle',
      legendOffset: 50,
      ...(graphDirection === 'vertical' && {
        format: (value) => (typeof value === 'string' ? value?.substring(0, value.length - 21) : value),
      }),
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: graphDirection === 'vertical' ? 0 : 80,
      legend: graphDirection === 'vertical' ? 'Percentage' : indexBy,
      legendPosition: 'middle',
      legendOffset: -50,
      ...(graphDirection === 'horizontal' && {
        format: (value) => (typeof value === 'string' ? value?.substring(0, value.length - 21) : value),
      }),
    },
  };

  const customColors = colors.map((color) => {
    const colorPalette = customConfig?.colorPalette[0] || {};
    return colorPalette[color] || color;
  });

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      theme={getNivoCustomFontTheme(selectedFont)}
      indexBy={indexBy}
      colors={customColors}
      layout={customConfig.generalSettings?.graphDirection}
      margin={customConfig.layout}
      padding={0.2}
      innerPadding={1}
      enableTotals={customConfig.generalSettings?.showTotals}
      enableGridX={customConfig.generalSettings?.showGridX}
      enableGridY={customConfig.generalSettings?.showGridY}
      valueFormat={(value) => Number(value).toFixed(2)}
      axisBottom={defaultConfig.axisBottom}
      axisLeft={defaultConfig.axisLeft}
      enableLabel={customConfig.generalSettings?.showBarLabel}
      label={(d) => [
        `${d.value}%`,
        ` (${d.id.split(' ').map((word) => word.charAt(0)).join('')})`,
      ]}
      labelSkipWidth={3}
      labelSkipHeight={4}
      legends={customConfig.legends ? [customConfig.legends] : []}
      groupMode={chart_type}
      borderRadius={customConfig.generalSettings?.borderRadius || 0}
      borderWidth={customConfig.generalSettings?.borderWidth || 1}
      role="application"
      ariaLabel="Nivo bar chart"
      barAriaLabel={(d) => `${d.id}: ${d.formattedValue} in ${d.indexValue}`}
      tooltip={CustomTooltip}
    />
  );
};

export default NivoStackedBarChart;
