/* eslint-disable no-shadow */
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import getNivoCustomFontTheme from '../common/CustomFontsTheme';
import { DEFAULT_DATA_COLORS } from '../../../data/const';

const NivoSingleBarChart = ({
  customConfig,
  indexBy,
  keys = [],
}) => {
  const data = customConfig.customData || [];
  if (!data || !Array.isArray(data) || data.length === 0) {
    return <p>No data available for the chart.</p>;
  }
  const selectedFont = customConfig.generalSettings.font;
  const customColors = customConfig.colorPalette.length > 0
    ? customConfig.colorPalette
    : DEFAULT_DATA_COLORS;

  // default configuration
  const defaultConfig = {
    axisBottom: {
      tickSize: 15,
      tickPadding: 5,
      tickRotation: 10,
      legend: indexBy,
      legendPosition: 'middle',
      legendOffset: -80,
      legendOrientation: 'horizontal',
    },
    axisLeft: {
      tickSize: 15,
      tickPadding: 5,
      tickRotation: 0,
      legend: keys[0],
      legendPosition: 'middle',
      legendOffset: 20,
      legendOrientation: 'vertical',
    },
    labelTextColor: { from: 'color', modifiers: [['darker', 2]] },
  };
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      theme={getNivoCustomFontTheme(selectedFont)}
      indexBy={indexBy}
      layout={customConfig.generalSettings.graphDirection}
      margin={customConfig.layout}
      enableGridX={customConfig.generalSettings.showGridX}
      enableGridY={customConfig.generalSettings.showGridY}
      minValue={customConfig.generalSettings.minValue}
      maxValue={customConfig.generalSettings.maxValue}
      padding={customConfig.generalSettings.padding}
      innerPadding={customConfig.generalSettings.innerPadding}
      axisBottom={{
        ...defaultConfig.axisBottom,
      }}
      axisLeft={{
        ...defaultConfig.axisLeft,
      }}
      enableLabel={customConfig.generalSettings.showBarLabel}
      labelTextColor={defaultConfig.labelTextColor}
      colors={({ _, index }) => customColors[index]}
      legends={[customConfig.legends]}
      groupMode="grouped"
      borderColor={
        defaultConfig.borderColor || {
          from: 'color',
          modifiers: [['darker', 1.6]],
        }
      }
      role="application"
      ariaLabel="Nivo bar chart"
      barAriaLabel={(d) => `${d.id}: ${d.formattedValue} in ${d.indexValue}`}
    />
  );
};

export default NivoSingleBarChart;
