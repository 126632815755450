/* eslint-disable max-len */
import apiService from '../apiService';

export const visualsApi = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getAllVisualsByReport: builder.query({
      query: (report_id) => `/v1/visuals/report/${report_id}`,
      providesTags: (result, error, report_id) => [
        { type: 'Visuals', id: 'LIST' },
        { type: 'Visuals', id: report_id },
        { type: 'SelectedVisuals', id: report_id },
        ...(result?.data?.map(({ id }) => ({ type: 'Visual', id })) ?? []),
      ],
    }),

    createVisual: builder.mutation({
      query: ({ visualData }) => ({
        url: '/v1/visuals',
        method: 'POST',
        body: { data: visualData },
      }),
      async onQueryStarted({ visualData, report_id }, { dispatch, queryFulfilled }) {
        const tempId = `temp-${Date.now()}`;

        const optimisticVisual = {
          id: tempId,
          visual_id: tempId,
          chart_id: visualData.chart_id,
          report_id: visualData.report_id,
          chart_type: visualData.chart_type,
          type: 'custom',
          pinned: false,
          customizations: visualData.customizations || {},
          chart_data: {
            chart_title: visualData.customizations?.generalSettings?.title || '',
          },
        };

        const patchResult = dispatch(
          visualsApi.util.updateQueryData(
            'getAllVisualsByReport',
            report_id,
            (draft) => {
              if (!draft) {
                draft = { data: [] };
              }
              if (!draft.data) {
                draft.data = [];
              }
              draft.data.push(optimisticVisual);
            },
          ),
        );

        try {
          const { data: newVisual } = await queryFulfilled;

          dispatch(
            visualsApi.util.updateQueryData(
              'getAllVisualsByReport',
              report_id,
              (draft) => {
                const index = draft.data.findIndex((v) => v.id === tempId);
                if (index !== -1) {
                  draft.data[index] = newVisual.data;
                }
              },
            ),
          );
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, { visualData }) => [
        { type: 'Visuals', id: 'LIST' },
        { type: 'Visuals', id: visualData.report_id },
      ],
    }),

    updateVisual: builder.mutation({
      query: ({ visual_id, updatedCustomizations }) => ({
        url: `/v1/visuals/${visual_id}`,
        method: 'PUT',
        body: {
          data: {
            customizations: updatedCustomizations,
          },
        },
      }),
      async onQueryStarted({ visual_id, updatedCustomizations, report_id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          visualsApi.util.updateQueryData('getAllVisualsByReport', report_id, (draft) => {
            // Find visual by checking all possible ID fields
            const visual = draft.data.find((v) => (
              v.id === visual_id
              || v.visual_id === visual_id
              || v.chart_id === visual_id
            ));
            if (visual) {
              visual.customizations = updatedCustomizations;
              // Update chart title if it exists in customizations
              if (updatedCustomizations?.generalSettings?.title) {
                visual.chart_data = {
                  ...visual.chart_data,
                  chart_title: updatedCustomizations.generalSettings.title,
                };
              }
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, { visual_id, report_id }) => [
        { type: 'Visual', id: visual_id },
        { type: 'Visuals', id: report_id },
      ],
    }),

    selectOrUnselectVisual: builder.mutation({
      query: ({
        visual_id,
        pin,
        report_id,
        type,
      }) => ({
        url: `/v1/visuals/${visual_id}/pin`,
        method: 'PUT',
        params: { pin },
        body: { type },
      }),
      async onQueryStarted(
        {
          visual_id, pin, type, report_id,
        },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          visualsApi.util.updateQueryData('getAllVisualsByReport', report_id, (draft) => {
            const visual = draft.data.find((visuals) => (visuals.visual_id === visual_id));

            if (visual) {
              visual.pinned = pin;
              visual.type = type;
            }
          }),
        );

        try {
          const result = await queryFulfilled;
          return [
            { type: 'Visual', id: visual_id },
            { type: 'SelectedVisuals', id: report_id },
            { type: 'Visuals', id: report_id },
          ];
        } catch {
          patchResult.undo();
          return [
            { type: 'Visual', id: visual_id },
            { type: 'SelectedVisuals', id: report_id },
            { type: 'Visuals', id: report_id },
          ];
        }
      },
    }),

    deleteVisual: builder.mutation({
      query: ({ visual_id, report_id }) => ({
        url: `/v1/visuals/${visual_id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ visual_id, report_id }, { dispatch, queryFulfilled }) {
        // Optimistically update the cache
        const patchResult = dispatch(
          visualsApi.util.updateQueryData('getAllVisualsByReport', report_id, (draft) => {
            const index = draft.data.findIndex((v) => (
              v.id === visual_id
              || v.visual_id === visual_id
              || v.chart_id === visual_id
            ));
            if (index !== -1) {
              draft.data.splice(index, 1);
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          return [
            { type: 'Visual', id: visual_id },
            { type: 'Visuals', id: report_id },
            { type: 'Visuals', id: 'LIST' },
            { type: 'SelectedVisuals', id: report_id },
          ];
        }
        return [];
      },
    }),
  }),
});

export const {
  useGetAllVisualsByReportQuery,
  useSelectOrUnselectVisualMutation,
  useCreateVisualMutation,
  useUpdateVisualMutation,
  useDeleteVisualMutation,
} = visualsApi;
