// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import apiService from '../services/apiService';
import { clientsApi } from '../services/api/clientsApi';
import { reportsApi } from '../services/api/reportsApi';
import { filesApi } from '../services/api/filesApi';
import { visualsApi } from '../services/api/visualsApi';

const store = configureStore({
  reducer: {
    [apiService.reducerPath]: apiService.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [visualsApi.reducerPath]: visualsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    apiService.middleware,
    clientsApi.middleware,
    reportsApi.middleware,
    filesApi.middleware,
    visualsApi.middleware,
  ),
});

export default store;
