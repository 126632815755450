/* eslint-disable no-shadow */
import React from 'react';
import { Typography, Box } from '@mui/material';
import axios from 'axios';
import CustomUploadButton from './button/CustomUploadButton';
import { useAddClientInputFilesMutation, useLazyGetPreSignedUrlQuery } from '../services/api/filesApi';
import { addTimeStamp, fileValidation } from '../utils/commonFunctions';
import { updateInputDataAfterFileUpload } from '../utils/updateClientData';
import {
  UPLOADING, UPLOADED, SUCCESS, FAILED,
} from '../data/const';
import CustomTooltip from './customMuiComponent/CustomTooltip';
import { InfoIcon } from '../icons';

const UploadFileToS3 = ({
  clientInputData = [],
  setClientInputData,
  clientId,
  fileCopy,
  disabled,
  infoToolTip,
}) => {
  const {
    title_id,
    title,
    buttonText,
    acceptedFileTypes,
    parentFolder,
    validFileCondition = [],
  } = fileCopy;
  const [getPreSignedUrl] = useLazyGetPreSignedUrlQuery();
  const [addClientInputFiles] = useAddClientInputFilesMutation();

  const handleUploadClientData = async (
    file_name,
    title_id,
    file_status,
    file_s3_url,
    status_message,
  ) => {
    const clientInputDataCopy = { ...clientInputData };
    const updatedClientInputData = updateInputDataAfterFileUpload(
      clientInputDataCopy,
      file_s3_url,
      file_name,
      title_id,
      file_status,
      status_message,
    );

    if (file_status === UPLOADING || file_status === FAILED) {
      setClientInputData(updatedClientInputData);
      return;
    }

    if (file_status === SUCCESS) {
      try {
        const res = await addClientInputFiles({ file_s3_url }).unwrap();
        const response = { ...res.data };
        response.upload_status = response?.file_status === UPLOADED ? SUCCESS : FAILED;
        response.upload_status_message = response?.file_status === UPLOADED
          ? 'Upload successful'
          : response?.error_message || 'Invalid file';

        clientInputDataCopy[title_id] = [
          ...(clientInputDataCopy[title_id] || []).filter(
            (item) => item.file_status !== FAILED,
          ),
          response,
        ];
        setClientInputData(clientInputDataCopy);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handleFileUploadToS3 = async (file) => {
    const file_name = addTimeStamp(file.name);
    const filePath = `${clientId}/input_data/${parentFolder}/${file_name}`;

    // Display file in frontend without uploading into S3 bucket
    handleUploadClientData(file_name, title_id, UPLOADING, filePath);

    const { isValidFile, errorMessage } = fileValidation(file);
    if (!isValidFile) {
      handleUploadClientData(file_name, title_id, FAILED, filePath, errorMessage);
      return;
    }

    try {
      const { data: preSignedUrl } = await getPreSignedUrl({
        file_key: filePath,
        type: 'upload',
      });

      await axios.put(preSignedUrl, file, {
        headers: { 'Content-Type': file.type },
      });

      handleUploadClientData(
        file_name,
        title_id,
        SUCCESS,
        filePath,
        'Upload Successful',
      );
    } catch (error) {
      handleUploadClientData(file_name, title_id, FAILED, filePath, 'Upload failed');
      console.error('Error uploading to S3:', error);
    }
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <Typography
          variant="subtitle1"
          style={{
            marginBottom: '1rem',
            fontWeight: 'bold',
            fontSize: '1.5rem',
          }}
        >
          {title}
        </Typography>
        {infoToolTip && (
          <CustomTooltip
            placement="top-start"
            title="Valid File Rule"
            message={validFileCondition}
          >
            <Box
              display="flex"
              alignItems="center"
              style={{ marginLeft: '0.5rem' }}
            >
              <InfoIcon style={{ color: '#1976D2' }} />
              <Typography
                variant="body2"
                style={{ marginLeft: '0.5rem', color: '#1976D2' }}
              >
                Please make sure the file type is .docx and max size is xyz, max
                no of files in...
              </Typography>
            </Box>
          </CustomTooltip>
        )}
      </Box>
      <CustomUploadButton
        buttonText={buttonText}
        onClickHandler={(files) => handleFileUploadToS3(files[0])}
        acceptedFileTypes={acceptedFileTypes}
        disabled={disabled}
        style={{
          color: '#D32F2F',
          fontWeight: 'bold',
          padding: '0.75rem 1rem',
          backgroundColor: '#F5F5F5',
        }}
      />
    </Box>
  );
};

export default UploadFileToS3;
