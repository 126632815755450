import React from 'react';
import { Button, Paper, Box } from '@mui/material';

const styles = {
  filterContainer: (isCollapsed) => ({
    minWidth: isCollapsed ? '62px' : '320px',
    width: isCollapsed ? '62px' : '320px',
  }),
  tabButton: (isSelected, index) => ({
    fontSize: '18px',
    width: '100%',
    justifyContent: 'left',
    textTransform: 'none',
    padding: '16px 24px',
    minHeight: '64px',
    color: isSelected ? '#BF1736' : '#0B1E36',
    backgroundColor: isSelected ? '#F7FAFC' : '#FFFFFF',
    border: 'none',
    borderBottom: index === 3 ? 'unset' : '1px solid #A0A0A0',
    borderRadius: 'unset',
    position: 'relative',
    zIndex: 1,
    '&:hover': {
      backgroundColor: isSelected ? '#F7FAFC' : '#FFFFFF',
    },
  }),
  diamondBox: (isCollapsed) => ({
    position: 'absolute',
    right: isCollapsed ? '-24px' : '-22px',
    top: '50%',
    transform: 'translateY(-50%) rotate(45deg)',
    width: '45px',
    height: '45px',
    backgroundColor: '#F7FAFC',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  }),
};

const VisualTab = ({
  selectedTab,
  onTabChange,
  visualTabItem,
  isCollapsed,
}) => {
  return (
    <Paper sx={styles.filterContainer(isCollapsed)}>
      {visualTabItem.map((tab, index) => (
        <Box key={tab.id} position="relative">
          <Button
            startIcon={tab.icon}
            disableRipple
            sx={styles.tabButton(selectedTab === tab.id, index)}
            onClick={() => onTabChange(tab.id)}
          >
            {!isCollapsed && tab.tab_name}
          </Button>
          {selectedTab === tab.id && (
            <Box sx={styles.diamondBox(isCollapsed)} />
          )}
        </Box>
      ))}
    </Paper>
  );
};

export default VisualTab;
