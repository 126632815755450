import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
  ChartPreview, GeneralSettings, LayoutSettings,
} from './common';
import FooterActions from './common/FooterActions';
import { NivoHeatmap } from './nivoComponent';
import LegendSettingsHeatMap from './common/LegendSettingsHeatMap';
import { createHeatmapConfig } from './common/chartsCustomConfig';
import ColorSettings from './common/ColorSettings';
import ColorSettingsHeatmap from './common/ColorSettingsHeatmap';

const CustomHeatmap = ({ chartDataDetails }) => {
  const {
    chart_data, customizations = {}, visual_id, chart_id, report_id, chart_type,
  } = chartDataDetails;

  const [customConfig, setCustomConfig] = useState(
    createHeatmapConfig(chart_data, customizations),
  );
  useEffect(() => {
    const tempCustomConfig = createHeatmapConfig(chart_data, customizations);
    setCustomConfig(tempCustomConfig);
  }, [customizations]);

  const handleMarginChange = (side, value) => {
    setCustomConfig((prev) => ({
      ...prev,
      layout: { ...prev.layout, [side]: (value) },
    }));
  };
  const handleGeneralSettingsChange = (key, value) => {
    if (key && value !== undefined) {
      setCustomConfig((prev) => ({
        ...prev,
        generalSettings: {
          ...prev.generalSettings,
          [key]: value,
        },
      }));
    }
  };
  const handleLegendChange = (key, value) => {
    setCustomConfig((prev) => ({
      ...prev,
      legends: { ...prev.legends, [key]: value },
    }));
  };
  const font_family = customConfig.generalSettings.font;
  const handleColorPaletteChange = (value) => {
    setCustomConfig((prev) => ({
      ...prev,
      colorPalette: value,
    }));
  };

  return (
    <Box>
      <ChartPreview
        chart_title={customConfig.generalSettings.title || chart_data.chart_title}
        data_dump={chart_data?.data_dump}
        font_family={font_family}
      >
        <NivoHeatmap
          data={chart_data.data}
          customConfig={customConfig}
          data_source={chart_data.data_source}
        />
      </ChartPreview>
      <GeneralSettings
        chartType="heatmap"
        settings={{
          ...customConfig.generalSettings,
          title: customConfig.generalSettings.title || chart_data.chart_title,
        }}
        onChange={handleGeneralSettingsChange}
      />
      <LayoutSettings
        margins={customConfig.layout}
        onMarginChange={handleMarginChange}
      />
      <LegendSettingsHeatMap
        direction={customConfig.legends.direction}
        position={customConfig.legends.anchor}
        offsetX={customConfig.legends.translateX}
        offsetY={customConfig.legends.translateY}
        length={customConfig.legends.length}
        thickness={customConfig.legends.thickness}
        tickSize={customConfig.legends.tickSize}
        tickSpacing={customConfig.legends.tickSpacing}
        onDirectionChange={(e) => handleLegendChange('direction', e.target.value)}
        onPositionChange={(e) => handleLegendChange('anchor', e.target.value)}
        onOffsetXChange={(value) => handleLegendChange('translateX', value)}
        onOffsetYChange={(value) => handleLegendChange('translateY', value)}
        onLengthChange={(value) => handleLegendChange('length', value)}
        onThicknessChange={(value) => handleLegendChange('thickness', value)}
        onTickSizeChange={(value) => handleLegendChange('tickSize', value)}
        onTickSpacingChange={(value) => handleLegendChange('tickSpacing', value)}
      />
      <ColorSettingsHeatmap
        onColorPaletteChange={handleColorPaletteChange}
        initialColorPalette={customizations.colorPalette}
      />
      <FooterActions
        visual_id={visual_id}
        updatedCustomizations={customConfig}
        chart_type={chart_type}
        chart_id={chart_id}
        report_id={report_id}
      />
    </Box>
  );
};

export default CustomHeatmap;
