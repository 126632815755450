import React from 'react';
import {
  Box, FormControl, InputLabel, Select, MenuItem, TextField,
} from '@mui/material';
import CollapsibleContainer from './CollapsibleContainer';

const LegendSettings = ({
  direction,
  position,
  shape,
  offsetX,
  offsetY,
  symbolSize,
  itemWidth,
  itemHeight,
  onDirectionChange,
  onPositionChange,
  onShapeChange,
  onOffsetXChange,
  onOffsetYChange,
  onSymbolSizeChange,
  onItemWidthChange,
  onItemHeightChange,
}) => (
  <CollapsibleContainer title="Legends" defaultExpanded={false}>
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      {/* Legend Direction */}
      <FormControl sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}>
        <InputLabel>Legend Direction</InputLabel>
        <Select
          value={direction}
          onChange={onDirectionChange}
          label="Legend Direction"
          size="small"
        >
          <MenuItem value="row">Row</MenuItem>
          <MenuItem value="column">Column</MenuItem>
        </Select>
      </FormControl>

      {/* Legend Position */}
      <FormControl sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}>
        <InputLabel>Legend Position</InputLabel>
        <Select
          value={position}
          onChange={onPositionChange}
          label="Legend Position"
          size="small"
        >
          <MenuItem value="top-left">Top Left</MenuItem>
          <MenuItem value="top">Top</MenuItem>
          <MenuItem value="top-right">Top Right</MenuItem>
          <MenuItem value="right">Right</MenuItem>
          <MenuItem value="bottom-right">Bottom Right</MenuItem>
          <MenuItem value="bottom">Bottom</MenuItem>
          <MenuItem value="bottom-left">Bottom Left</MenuItem>
          <MenuItem value="left">Left</MenuItem>
          <MenuItem value="center">Center</MenuItem>
        </Select>
      </FormControl>

      {/* Legend Offset X */}
      <TextField
        label="Legend Offset X"
        variant="outlined"
        type="number"
        size="small"
        value={offsetX}
        onChange={(e) => onOffsetXChange(Number(e.target.value))}
        sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}
      />

      {/* Legend Offset Y */}
      <TextField
        label="Legend Offset Y"
        variant="outlined"
        type="number"
        size="small"
        value={offsetY}
        onChange={(e) => onOffsetYChange(Number(e.target.value))}
        sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}
      />

      {/* Legend Shape */}
      <FormControl sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}>
        <InputLabel>Legend Shape</InputLabel>
        <Select
          value={shape}
          onChange={onShapeChange}
          label="Legend Shape"
          size="small"
        >
          <MenuItem value="square">Square</MenuItem>
          <MenuItem value="circle">Circle</MenuItem>
        </Select>
      </FormControl>

      {/* Legend Symbol Size */}
      <TextField
        label="Legend Symbol Size"
        variant="outlined"
        type="number"
        size="small"
        value={symbolSize}
        onChange={(e) => onSymbolSizeChange(Number(e.target.value))}
        sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}
      />

      {/* Legend Item Width */}
      <TextField
        label="Legend Item Width"
        variant="outlined"
        type="number"
        size="small"
        value={itemWidth}
        onChange={(e) => onItemWidthChange(Number(e.target.value))}
        sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}
      />

      {/* Legend Item Height */}
      <TextField
        label="Legend Item Height"
        variant="outlined"
        type="number"
        size="small"
        value={itemHeight}
        onChange={(e) => onItemHeightChange(Number(e.target.value))}
        sx={{ flex: '1 1 calc(25% - 16px)', minWidth: '120px' }}
      />
    </Box>
  </CollapsibleContainer>
);

export default LegendSettings;
