import apiService from '../apiService';

export const reportsApi = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getReportsByClientId: builder.query({
      query: (client_id) => ({
        url: '/v1/reports',
        params: { client_id },
      }),
    }),
    runTextProcessingModel: builder.mutation({
      query: ({ selectedFilesForModel, client_id, model_name }) => ({
        url: '/v1/reports',
        method: 'POST',
        body: { data: { client_id, model_name, input_data: selectedFilesForModel } },
      }),
    }),
    getReportByReportId: builder.query({
      query: (report_id) => `/v1/reports/${report_id}`,
    }),
    updateReportByReportId: builder.mutation({
      query: ({ report_id, updatedReport }) => ({
        url: `/v1/reports/${report_id}`,
        method: 'PUT',
        body: { data: updatedReport },
      }),
    }),
    deleteModelByReportId: builder.mutation({
      query: (report_id) => ({
        url: `/v1/reports/${report_id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetReportsByClientIdQuery,
  useRunTextProcessingModelMutation,
  useGetReportByReportIdQuery,
  useUpdateReportByReportIdMutation,
  useDeleteModelByReportIdMutation,
} = reportsApi;
