/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import UserPool from '../Auth/UserPool';

const AuthContext = createContext();

/**
 * AuthProvider component that provides authentication context to the entire app.
 * It handles user sessions, login, and logout functionality.
 *
 * @param {object} props - The props for the component.
 * @param {React.ReactNode} props.children - The child components that require authentication context.
 * @returns {JSX.Element} - The AuthProvider component.
 */
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [userSessions, setUserSessions] = useState({ isAuthenticated: false, idToken: null });
  const [authDetails, setAuthDetails] = useState(() => {
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          return {
            isAuthenticated: false,
            idToken: null,
          };
        }
        if (session.isValid()) {
          setUserSessions({
            isAuthenticated: true,
            idToken: session.idToken.jwtToken,
          });
        }
      });
    } else {
      return {
        isAuthenticated: false,
        idToken: null,
      };
    }
  });

  useEffect(() => {
    setAuthDetails(userSessions);
  }, [userSessions]);

  /**
   * Handles successful login by setting authentication details and navigating to the dashboard.
   *
   * @param {object} param0 - The parameter object containing the idToken.
   * @param {string} param0.idToken - The ID token of the authenticated user.
   */
  const handleLoginSuccess = async ({ idToken }) => {
    setAuthDetails({
      isAuthenticated: true,
      idToken,
    });
    navigate('/dashboard');
  };

  /**
   * Handles successful logout by resetting authentication details and navigating to the login page.
   */
  const handleLogoutSuccess = () => {
    setAuthDetails({ isAuthenticated: false, idToken: null });
    navigate('/login');
  };

  const authUserDetails = useMemo(
    () => ({
      authDetails,
      handleLoginSuccess,
      handleLogoutSuccess,
    }),
    [authDetails],
  );

  return (
    <>
      {authUserDetails?.authDetails
      && <AuthContext.Provider value={authUserDetails}>{children}</AuthContext.Provider>}
    </>
  );
};

/**
 * Custom hook to access the authentication context.
 *
 * @returns {object} - The authentication details and handlers.
 */
export const useAuth = () => {
  return useContext(AuthContext);
};
