const downloadVisualAsImage = (elementRef, title, padding = 40) => {
  if (!elementRef.current) return;

  import('html-to-image')
    .then(({ toCanvas }) => {
      toCanvas(elementRef.current, { backgroundColor: null })
        .then((canvas) => {
          // Create a new canvas with padding
          const paddedCanvas = document.createElement('canvas');
          const context = paddedCanvas.getContext('2d');

          paddedCanvas.width = canvas.width + padding * 2;
          paddedCanvas.height = canvas.height + padding * 2;

          // Fill background with white
          context.fillStyle = '#fff';
          context.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height);

          // Draw the original canvas on the new canvas with padding
          context.drawImage(canvas, padding, padding);

          // Convert to image and download
          const dataUrl = paddedCanvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = title;
          link.click();
        });
    })
    .catch((error) => {
      console.error('Error downloading visual:', error);
      throw error;
    });
};

// const downloadDataAsCSV = (data_dump) => {
//   const link = document.createElement('a');
//   link.href = data_dump;
//   link.download = data_dump.split('/').pop(); // Extracts the filename from the URL
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);

const downloadDataAsCSV = (preSignedUrl, event) => {
  if (event) event.preventDefault(); // Prevent default behavior (e.g., form submission)
  const link = document.createElement('a');
  link.href = preSignedUrl;
  link.download = ''; // Add a filename if necessary
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export { downloadDataAsCSV, downloadVisualAsImage };
