import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import {
  ChartPreview,
  DataDisplaySettings, GeneralSettings, LayoutSettings, LegendSettings,
} from './common';
import FooterActions from './common/FooterActions';
import { NivoSingleBarChart } from './nivoComponent';
import { createSingleBarChartConfig } from './common/chartsCustomConfig';
import ColorSettings from './common/ColorSettings';

const defaultDataColors = [
  '#93A01F',
  '#67C0A1',
  '#7A9DD4',
  '#AF92C3',
  '#C5869D',
  '#E27B7D',
  '#F4C389',
  '#8E9163',
  '#54746A',
  '#465679',
  '#5A3E66',
  '#6C4D56',
  '#814C4E',
  '#735641',
];

const CustomSingleBarChart = ({ chartDataDetails, barType }) => {
  const {
    chart_data,
    customizations = {},
    visual_id,
    chart_id,
    report_id,
    chart_type,
  } = chartDataDetails;
  const indexBy = chart_type === 'bar-topic' ? 'topic' : chart_type === 'bar-count' ? 'sentiment' : '';
  const keys = Object.keys(chart_data.data[0]).filter((key) => key !== indexBy);
  const [customConfig, setCustomConfig] = useState(
    createSingleBarChartConfig(chart_data, customizations),
  );

  useEffect(() => {
    const tempCustomConfig = createSingleBarChartConfig(chart_data, customizations);
    setCustomConfig(tempCustomConfig);
  }, [customizations]);

  useEffect(() => {
    setCustomConfig((prev) => ({
      ...prev,
      generalSettings: {
        ...prev.generalSettings,
        minValue: chart_type === 'bar-topic' ? -1 : 0,
        maxValue: chart_type === 'bar-topic' ? 1 : undefined,
      },
    }));
  }, [chart_type, chart_data, indexBy]);

  const handleGeneralSettingsChange = (key, value) => {
    if (key && value !== undefined) {
      setCustomConfig((prev) => ({
        ...prev,
        generalSettings: {
          ...prev.generalSettings,
          [key]: value,
        },
      }));
    }
  };

  const handleMarginChange = (side, value) => {
    setCustomConfig((prev) => ({
      ...prev,
      layout: { ...prev.layout, [side]: value },
    }));
  };

  const handleLegendChange = (key, value) => {
    setCustomConfig((prev) => ({
      ...prev,
      legends: { ...prev.legends, [key]: value },
    }));
  };
  const handleColorPaletteChange = (palette) => {
    setCustomConfig((prev) => ({
      ...prev,
      colorPalette: palette,
    }));
  };
  const font_family = customConfig.generalSettings.font;

  return (
    <Box>
      <ChartPreview
        chart_title={customConfig.generalSettings.title || chart_data.chart_title}
        data_dump={chart_data?.data_dump}
        font_family={font_family}
      >
        <NivoSingleBarChart
          chart_type="grouped"
          customConfig={customConfig}
          indexBy={indexBy}
          keys={keys}
          barType={barType}
        />
      </ChartPreview>
      <GeneralSettings
        chartType="bar"
        settings={{
          ...customConfig.generalSettings,
          title: customConfig.generalSettings.title || chart_data.chart_title,
        }}
        onChange={handleGeneralSettingsChange}
      />
      <LayoutSettings
        margins={customConfig.layout}
        onMarginChange={handleMarginChange}
      />
      <LegendSettings
        direction={customConfig.legends.direction}
        position={customConfig.legends.anchor}
        shape={customConfig.legends.symbolShape}
        offsetX={customConfig.legends.translateX}
        offsetY={customConfig.legends.translateY}
        symbolSize={customConfig.legends.symbolSize}
        itemWidth={customConfig.legends.itemWidth}
        itemHeight={customConfig.legends.itemHeight}
        onDirectionChange={(e) => handleLegendChange('direction', e.target.value)}
        onPositionChange={(e) => handleLegendChange('anchor', e.target.value)}
        onShapeChange={(e) => handleLegendChange('symbolShape', e.target.value)}
        onOffsetXChange={(value) => handleLegendChange('translateX', value)}
        onOffsetYChange={(value) => handleLegendChange('translateY', value)}
        onSymbolSizeChange={(value) => handleLegendChange('symbolSize', value)}
        onItemWidthChange={(value) => handleLegendChange('itemWidth', value)}
        onItemHeightChange={(value) => handleLegendChange('itemHeight', value)}
      />
      <ColorSettings
        numberOfColors={chart_data.data.length}
        onColorPaletteChange={handleColorPaletteChange}
        initialColorPalette={customizations.colorPalette}
      />
      <FooterActions
        visual_id={visual_id}
        report_id={report_id}
        updatedCustomizations={customConfig}
        chart_type={chart_type}
        chart_id={chart_id}
      />
    </Box>
  );
};

export default CustomSingleBarChart;
