import React, { useRef, useState, useEffect } from 'react';
import {
  Box, Typography, IconButton, Paper, Button,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  EditIcon,
  DownloadIcon,
  DeleteIcon,
  CheckIcon,
} from '../../icons/index';
import theme from '../../styles/material_ui_theme';
import { useDeleteVisualMutation, useSelectOrUnselectVisualMutation } from '../../services/api/visualsApi';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import { downloadVisualAsImage } from '../../utils/downloadVisualsData';
import useDownloadCsv from '../../hooks/useDownloadCsv';

const accentBlue = theme.palette.accent.blue;

const styles = {
  selectionBtn: {
    color: accentBlue,
    border: `2px solid ${accentBlue}`,
    borderRadius: '4px',
    fontWeight: 500,
    '&:hover': {
      border: `2px solid ${accentBlue}`,
      borderRadius: '4px',
      color: accentBlue,
      background: 'transparent',
    },
  },
};

const confirmationDialogTitle = 'Are you sure you want to delete this visual?';

const VisualCard = ({
  title = 'Visual Title',
  type = 'default',
  children,
  chart_type,
  report_id,
  visual_id,
  pinned,
  font_family,
  chart_data,
  chart_description,
  noInteractions = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const chartRef = useRef(null);
  const [isPinned, setIsPinned] = useState(pinned);
  const { downloadCsv } = useDownloadCsv();
  const [
    selectOrUnselectVisual,
  ] = useSelectOrUnselectVisualMutation();
  const [
    deleteVisual,
  ] = useDeleteVisualMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    setIsPinned(pinned);
  }, [pinned]);

  const handleDeleteVisual = async () => {
    try {
      await deleteVisual({ visual_id, report_id });
    } catch (err) {
      console.error('Failed to delete visual:', err);
    }
  };

  const handleCustomizeVisual = () => {
    if (type === 'custom') {
      const currentParams = new URLSearchParams(location.search);
      currentParams.set('visual_id', visual_id);

      // Navigate to the updated URL
      navigate({
        pathname: location.pathname,
        search: currentParams.toString(),
      });
    } else {
      const currentParams = new URLSearchParams(location.search);

      // Update 'tab' to 'add'
      currentParams.set('tab', 'add');
      // Add or update the 'visual_id'
      currentParams.set('visual_id', visual_id);

      // Navigate to the updated URL
      navigate({
        pathname: location.pathname,
        search: currentParams.toString(),
      });
    }
  };

  const handlePinToggle = async () => {
    try {
      setIsPinned(!isPinned);

      await selectOrUnselectVisual({
        visual_id,
        report_id,
        pin: !isPinned,
        type,
      });
    } catch (error) {
      setIsPinned(isPinned);
      console.error('Failed to toggle pin:', error);
    }
  };

  const handleConfirmation = (value) => {
    if (value) {
      handleDeleteVisual();
    }
    setShowDeleteModal(false);
  };

  // Download table data as pdf
  const handleDownloadTableCsv = () => {
    downloadCsv(
      chart_data.data,
      `${title}.csv`,
      chart_description,
    );
  };
  return (
    <Paper
      style={{
        border: isPinned ? '2px solid #3988C6' : '2px solid transparent',
        padding: '1rem',
        position: 'relative',
        height: '520px',
        width: '100%',
        borderRadius: '4px',
        marginBottom: '1rem',
        boxSizing: 'border-box',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        mb={2}
        justifyContent="space-between"
      >
        <Typography variant="body1" sx={{ fontWeight: 700, fontFamily: font_family }}>
          {title}
        </Typography>
        <Button
          variant="standard"
          onClick={handleCustomizeVisual}
          disabled={noInteractions}
          startIcon={<EditIcon />}
          sx={{
            color: accentBlue,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          size="small"
        >
          {type === 'custom' ? 'Edit' : 'Customize'}
        </Button>
      </Box>

      {/* Render children dynamically */}
      <Box
        ref={chartRef}
        sx={{
          border: '1px solid #A0A0A0', borderRadius: '4px',
        }}
      >
        {children}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <Box>
          <Button
            variant="outlined"
            sx={styles.selectionBtn}
            onClick={handlePinToggle}
            startIcon={isPinned ? <CheckIcon /> : ''}
            size="small"
            disabled={noInteractions}
          >
            {isPinned ? 'Added' : 'Add to Selection'}
          </Button>
        </Box>
        <Box>
          <IconButton
            onClick={() => (chart_type === 'table' ? handleDownloadTableCsv() : downloadVisualAsImage(chartRef, title))}
            sx={{
              color: accentBlue,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <DownloadIcon />
          </IconButton>
          {type === 'custom' && (
            <>
              <IconButton
                onClick={() => setShowDeleteModal(true)}
                sx={{
                  color: accentBlue,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>

              <ConfirmationDialog
                title={confirmationDialogTitle}
                open={showDeleteModal}
                setOpen={(value) => setShowDeleteModal(value)}
                handleConfirmation={(value) => handleConfirmation(value)}
              />
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default VisualCard;
