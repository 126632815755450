/* eslint-disable max-len */
import {
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Button,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { AddchartOutlinedIcon, ArrowForwardIcon, ArrowBackIcon } from '../../icons';
import theme from '../../styles/material_ui_theme';
import { useGetAllVisualsByReportQuery, useSelectOrUnselectVisualMutation } from '../../services/api/visualsApi';

const styles = {
  filterContainer: (isCollapsed) => ({
    padding: '20px',
    backgroundColor: '#FFFFFF',
    minWidth: isCollapsed ? '64px' : '320px',
    width: isCollapsed ? '64px' : '320px',
    boxSizing: 'border-box',
    borderRadius: 'unset',
    position: 'relative',
    minHeight: '260px',
    paddingBottom: '80px',
  }),
  list: {
    padding: '4px',
    maxHeight: '150px',
    overflowY: 'scroll',
  },
  listItem: {
    padding: '0px 12px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bullet: {
    width: '4px',
    height: '4px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '50%',
    marginRight: '8px',
  },
  header: {
    fontWeight: 500,
    fontSize: '1.2rem',
    marginBottom: '8px',
  },
  emptyMessage: {
    color: theme.palette.primary.light,
  },
  downloadButton: {
    marginTop: '1rem',
    marginLeft: 'auto',
    display: 'flex',
    backgroundColor: theme.palette.accent.red,
    color: '#FFF',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: theme.palette.accent.red,
    },
  },
};

const SelectedVisualsTab = ({ isCollapsed, setIsCollapsed }) => {
  const { report_id } = useParams();
  const [selectOrUnselectVisual] = useSelectOrUnselectVisualMutation();
  const {
    data: allVisuals,
    error: allVisualsError,
    isLoading: isAllVisualsLoading,
    isFetching: isAllVisualsFetching,
  } = useGetAllVisualsByReportQuery(report_id);

  const selectedVisuals = allVisuals?.data?.filter((visual) => visual.pinned) ?? [];
  const customLibrary = selectedVisuals.filter((visual) => visual.type === 'custom') || [];
  const defaultLibrary = selectedVisuals.filter((visual) => visual.type === 'default') || [];

  const onRemove = async (visual_id, type) => {
    try {
      await selectOrUnselectVisual({
        visual_id,
        report_id,
        pin: false,
        type,
      });
    } catch (error) {
      console.error('Failed to remove visual:', error);
    }
  };

  const renderLibrarySection = (library, title, type) => {
    if (library.length === 0) return null;
    return (
      <>
        <Typography sx={styles.header}>
          {title}
          {' '}
          (
          {library.length}
          )
        </Typography>
        <List sx={styles.list}>
          {library.map((visual) => {
            const customTitle = visual.customizations?.generalSettings?.title || visual.chart_data?.chart_title;
            return (
              <ListItem key={visual.visual_id} sx={styles.listItem}>
                <Box display="flex" alignItems="center">
                  <span style={styles.bullet} />
                  <ListItemText primary={customTitle} />
                </Box>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  size="small"
                  onClick={() => onRemove(visual.visual_id, type)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </ListItem>
            );
          })}
        </List>
      </>
    );
  };

  return (
    <Paper sx={styles.filterContainer(isCollapsed)} elevation={1}>
      {isAllVisualsFetching && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          margin: '1.5rem 0',
        }}
      >
        <Typography
          sx={{
            color: '#00000099',
            fontSize: '16px',
          }}
        >
          Loading Selected Visuals...
        </Typography>
      </Box>
      )}
      { !isCollapsed && !isAllVisualsFetching && (
        <Box>
          {customLibrary.length === 0 && defaultLibrary.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                margin: '1.5rem 0',
              }}
            >
              <AddchartOutlinedIcon
                sx={{
                  fontSize: '40px',
                  color: '#A0A0A0',
                  mb: 1,
                }}
              />
              <Typography
                sx={{
                  color: '#00000099',
                  fontSize: '16px',
                }}
              >
                You don&apos;t have any selected visuals
              </Typography>
            </Box>
          ) : (
            <>
              {renderLibrarySection(customLibrary, 'Custom Library', 'custom')}
              {renderLibrarySection(defaultLibrary, 'Default Library', 'default')}
            </>
          )}
        </Box>
      )}
      <Button
        startIcon={isCollapsed
          ? (
            <ArrowForwardIcon
              sx={{
                color: '#3988C6',
                backgroundColor: '#E2F2FE',
                borderRadius: '50%',
              }}
            />
          )
          : (
            <ArrowBackIcon
              sx={{
                color: '#3988C6',
                backgroundColor: '#E2F2FE',
                borderRadius: '50%',
              }}
            />
          )}
        disableRipple
        sx={{
          position: 'absolute',
          bottom: '0px',
          right: isCollapsed ? '20px' : '0',
          left: isCollapsed ? '5px' : '',
          padding: '16px 24px',
          boxSizing: 'border-box',
          color: '#E2F2FE',
          border: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        onClick={() => setIsCollapsed(!isCollapsed)}
      />
    </Paper>
  );
};

export default SelectedVisualsTab;
