// src/api/filesApi.js
import apiService from '../apiService';

export const filesApi = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getPreSignedUrl: builder.query({
      query: ({ file_key, type }) => ({
        url: '/v1/fileurl',
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('id_token'),
        },
        params: {
          file_key,
          type,
        },
      }),
      transformResponse: (response) => response?.signed_url,
    }),
    addClientInputFiles: builder.mutation({
      query: ({ file_s3_url }) => ({
        url: '/v1/files',
        method: 'POST',
        params: { file_s3_url },
      }),
    }),
    deleteInputFileById: builder.mutation({
      query: (file_id) => ({
        url: `/v1/files/${file_id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetPreSignedUrlQuery,
  useAddClientInputFilesMutation,
  useDeleteInputFileByIdMutation,
} = filesApi;
