const UPLOADING = 'uploading';
const UPLOADED = 'uploaded';
const SUCCESS = 'success';
const FAILED = 'failed';
const PROCESSING = 'processing';
const PROCESSED = 'processed';
const COMPLETED = 'completed';
const SUCCESSFULL = 'successful';
const NOT_INITIATED = 'not initiated';
const IN_PROCESSING = 'in processing';
const PROCESSING_SUCCESSFULL = 'processing successful';
const MODEL_STATUS_MAP_OBJ = {
  undefined: 'Processing',
  'not initiated': 'Processing',
  'in processing': 'Processing',
  'processing successful': 'Processed',
  failed: 'failed',
};

const ALL_GOOGLE_FONTS = [
  // Group 1: Sans Serif
  { label: 'Inter', value: "'Inter', sans-serif" },
  { label: 'Roboto', value: "'Roboto', sans-serif" },
  { label: 'Roboto Condensed', value: "'Roboto Condensed', sans-serif" },
  { label: 'Open Sans', value: "'Open Sans', sans-serif" },
  { label: 'Source Sans Pro', value: 'Source Sans Pro' },
  { label: 'Source Sans 3', value: "'Source Sans 3', sans-serif" },
  { label: 'Lato', value: "'Lato', sans-serif" },
  { label: 'Poppins', value: "'Poppins', sans-serif" },

  // Group 2: Serif
  { label: 'PT Serif', value: "'PT Serif', serif" },
  { label: 'Playfair Display', value: "'Playfair Display', serif" },
  { label: 'Merriweather', value: "'Merriweather', serif" },
  { label: 'Frank Ruhl Libre', value: "'Frank Ruhl Libre', serif" },

  // Group 3: Slab Serif
  { label: 'Roboto Slab', value: "'Roboto Slab', serif" },
  { label: 'Aleo', value: "'Aleo', serif" },
  { label: 'Crete Round', value: "'Crete Round', serif" },
];

const DEFAULT_DATA_COLORS = [
  '#93A01F',
  '#67C0A1',
  '#7A9DD4',
  '#AF92C3',
  '#C5869D',
  '#E27B7D',
  '#F4C389',
  '#8E9163',
  '#54746A',
  '#465679',
  '#5A3E66',
  '#6C4D56',
  '#814C4E',
  '#735641',
];

export {
  UPLOADING,
  UPLOADED,
  PROCESSING,
  PROCESSED,
  COMPLETED,
  SUCCESS,
  SUCCESSFULL,
  FAILED,
  NOT_INITIATED,
  IN_PROCESSING,
  PROCESSING_SUCCESSFULL,
  MODEL_STATUS_MAP_OBJ,
  ALL_GOOGLE_FONTS,
  DEFAULT_DATA_COLORS,
};
