import React from 'react';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import CollapsibleContainer from './CollapsibleContainer';
import customGeneralConfig from './customGeneralConfig';
import { ALL_GOOGLE_FONTS } from '../../../data/const';

const GeneralSettings = ({
  chartType,
  settings,
  onChange,
}) => {
  const renderInput = (field) => {
    const {
      type, label, key, options,
    } = field;

    const commonStyles = {
      flex: '0 1 calc(25% - 16px)',
      minWidth: '150px',
    };

    switch (type) {
      case 'select':
        return (
          <FormControl key={key} sx={commonStyles}>
            <InputLabel>{label}</InputLabel>
            <Select
              value={settings[key] || ''}
              onChange={(e) => onChange(key, e.target.value)}
              label={key}
              size="small"
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case 'number':
        return (
          <TextField
            key={key}
            label={label}
            variant="outlined"
            type="number"
            size="small"
            value={settings[key] || ''}
            onChange={(e) => onChange(key, Number(e.target.value))}
            inputProps={{
              min: 0,
              max: 10,
              step: 0.1,
            }}
            sx={commonStyles}
          />
        );
      case 'checkbox':
        return (
          <FormControlLabel
            key={key}
            control={(
              <Checkbox
                checked={settings[key] || false}
                onChange={(e) => onChange(key, e.target.checked)}
              />
            )}
            label={label}
            sx={{
              ...commonStyles,
              m: 0,
              alignItems: 'center',
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <CollapsibleContainer title="General" defaultExpanded>
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2,
      }}
      >
        {/* Title */}
        <TextField
          label="Enter Title of Chart here"
          variant="outlined"
          size="small"
          value={settings.title}
          onChange={(e) => onChange('title', e.target.value)}
          sx={{ flex: '0 1 calc(50% - 16px)', minWidth: '200px' }}
        />

        {/* Font */}
        {chartType !== 'table' && (
        <FormControl
          sx={{ flex: '0 1 calc(50% - 16px)', minWidth: '150px' }}
        >
          <InputLabel>Font</InputLabel>
          <Select
            labelId="font-select-label"
            label="Font"
            value={settings.font}
            size="small"
            onChange={(e) => onChange('font', e.target.value)}
          >
            {ALL_GOOGLE_FONTS.map((font) => (
              <MenuItem key={font.label} value={font.value}>
                {font.label}
              </MenuItem>
            ))}
          </Select>

        </FormControl>
        )}

        {/* Description */}
        <TextField
          label="Add Description"
          variant="outlined"
          size="small"
          fullWidth
          multiline
          rows={3}
          value={settings.description || ''}
          onChange={(e) => onChange('description', e.target.value)}
          sx={{ flex: '1 1 100%' }}
        />

        {/* Dynamic Inputs */}
        {customGeneralConfig[chartType]?.map(renderInput)}
      </Box>
    </CollapsibleContainer>
  );
};

export default GeneralSettings;
