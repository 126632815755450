import React, { useState } from 'react';
import {
  Box, Button, Accordion, AccordionDetails,
} from '@mui/material';
import {
  useLocation, useParams, useSearchParams, useNavigate,
} from 'react-router-dom';
import { useCreateVisualMutation, useUpdateVisualMutation } from '../../../services/api/visualsApi';

const FooterActions = ({
  visual_id,
  updatedCustomizations,
  chart_type,
  chart_id,
  report_id,
  custom_table_data,
}) => {
  const { client_id, analysis_name } = useParams();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [updateVisual] = useUpdateVisualMutation();
  const [createVisual] = useCreateVisualMutation();
  const [searchParams] = useSearchParams();
  const tabValue = searchParams.get('tab');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleCancel = () => {
    navigate(`/client-data/${client_id}/report-analysis/${report_id}/${analysis_name}?tab=${tabValue}`);
  };

  const handleCreateVisual = async () => {
    setIsLoading(true);
    setMessage('Creating visualization...');
    try {
      const visualData = {
        client_id,
        chart_id,
        report_id,
        chart_type,
        customizations: chart_type === 'table'
          ? { ...updatedCustomizations, custom_table_data }
          : updatedCustomizations,
      };

      const response = await createVisual({
        visualData,
        report_id,
      }).unwrap();

      setMessage('Visualization created successfully!');
      setTimeout(() => setMessage(''), 3000);
      navigate(`/client-data/${client_id}/report-analysis/${report_id}/${analysis_name}?tab=${tabValue}`);
    } catch (err) {
      console.error('Failed to create visual:', err);
      setMessage('Failed to create visualization. Please try again.');
      setTimeout(() => setMessage(''), 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateVisual = async () => {
    setIsLoading(true);
    setMessage('Updating visualization...');
    try {
      await updateVisual({
        visual_id,
        report_id,
        updatedCustomizations,
      }).unwrap();

      setMessage('Visualization updated successfully!');
      setTimeout(() => setMessage(''), 3000);
      navigate(`/client-data/${client_id}/report-analysis/${report_id}/${analysis_name}?tab=${tabValue}`);
    } catch (error) {
      console.error('Failed to update visual:', error);
      setMessage('Failed to update visualization. Please try again.');
      setTimeout(() => setMessage(''), 3000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{
        borderRadius: 'unset',
        boxShadow: '0px 3px 6px 0px #00000029',
      }}
      disableGutters
    >
      <AccordionDetails
        sx={{
          padding: '16px 24px',
          backgroundColor: '#fff',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            variant="text"
            onClick={handleCancel}
            sx={{
              color: '#000',
              textTransform: 'uppercase',
              marginRight: '1rem',
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={tabValue === 'custom' ? handleUpdateVisual : handleCreateVisual}
            disabled={isLoading}
            sx={{
              backgroundColor: '#CC342E',
              color: '#fff',
              textTransform: 'uppercase',
              '&:hover': {
                backgroundColor: '#b52d28',
              },
            }}
          >
            {message || (tabValue === 'custom' ? 'Update Visualization' : 'Add to Library')}
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default FooterActions;
