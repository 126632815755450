import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import EditableClientName from '../components/elementaryComponents/EditableClientName';
import UploadFileToS3 from '../components/UploadFileToS3';
import DisplayModels from '../components/client-data/DisplayModels';
import DataDisplayWithCheckbox from '../components/data-display/DataDisplayWithCheckbox';
import { updateInputDataAfterFileDelete } from '../utils/updateClientData';
import { uploadReportFilesData } from '../data/reportDataCopy';
import { UPLOADING } from '../data/const';
import ModelProcessingSection from '../components/client-data/ModelProcessingSection';
import { useDeleteInputFileByIdMutation } from '../services/api/filesApi';
import {
  useGetReportsByClientIdQuery,
  useRunTextProcessingModelMutation,
} from '../services/api/reportsApi';
import {
  useGetAllClientsQuery,
  useGetClientByIdQuery,
} from '../services/api/clientsApi';
import ClientSelector from '../components/elementaryComponents/ClientSelector';
import { addTimeStamp, createTimeStamp } from '../utils/commonFunctions';

const response_type_file = ['surveys', 'focus_groups', 'interviews'];

/**
 * The ReportData component is responsible for fetching and displaying client data,
 * allowing users to upload files, run text processing models, and manage generated reports.
 *
 * @component
 * @returns {React.Component} The rendered ReportData component.
 */
const ReportData = () => {
  const params = useParams();
  const { client_id } = params;
  const [clientData, setClientData] = useState(null);
  const [modelName, setModelName] = useState('');
  const [selectedClientId, setSelectedClientId] = useState('');
  const [clientInputData, setClientInputData] = useState([]);
  const [textProcessingReady, setTextProcessingReady] = useState(false);
  const [allSelectedRows, setAllSelectedRows] = useState(null);
  const [clientReports, setClientReports] = useState([]);
  const [uniqueModels, setUniqueModels] = useState([]);
  const [fetchingReport, setFetchingReports] = useState(true);
  const [selectedFilesForModel, setSelectedFilesForModel] = useState({
    surveys: [],
    focus_groups: [],
    interviews: [],
  });
  const [deleteInputFileById] = useDeleteInputFileByIdMutation();
  const [runTextProcessingModel] = useRunTextProcessingModelMutation();
  const { data: clientDataById } = useGetClientByIdQuery(selectedClientId);
  const {
    data: reportsDataByClientId,
    isFetching: isFetchingReports,
  } = useGetReportsByClientIdQuery(selectedClientId);
  const modelNames = uniqueModels.map((item) => item.data.model_name);

  useEffect(() => {
    if (client_id) {
      setSelectedClientId(client_id);
    }
  }, [client_id]);

  const handleClientChange = (clientId) => {
    setSelectedClientId(clientId);
    // Push new clientId to the URL
    window.history.pushState({}, '', `/client-data/${clientId}`);
    setFetchingReports(true);
  };

  /**
   * Fetches the client data and reports when the component is mounted or the client_id changes.
   * Filters unique models from the fetched reports.
   */
  useEffect(() => {
    if (clientDataById) {
      setClientData(clientDataById?.data || []);
      const input_data = clientDataById?.data?.input_data || [];
      setClientInputData(input_data);
    }
    if (reportsDataByClientId) {
      const reportsData = reportsDataByClientId?.data;
      // Get all reports by client id
      const publishedReport = reportsData.filter(
        (report) => report?.data?.report_key,
      );
      setClientReports(publishedReport);

      // filtering uniqueModels
      const uniqueModelsArr = [];
      reportsData?.map((report) => {
        const alreadyPresent = uniqueModelsArr.findIndex(
          (model) => model?.data?.model_id === report?.data?.model_id,
        );
        if (alreadyPresent === -1) {
          uniqueModelsArr.push(report);
        }
        return report;
      });
      setUniqueModels(uniqueModelsArr);
      if (uniqueModels) {
        setFetchingReports(false);
      }
    }
    setTextProcessingReady((text_processing_ready) => text_processing_ready);
  }, [selectedClientId, reportsDataByClientId, clientDataById]);

  /**
   * Initializes the selected rows for file sections when the component is mounted.
   */
  useEffect(() => {
    const initialSelectedRows = [];
    uploadReportFilesData?.forEach((fileSection) => {
      initialSelectedRows.push({
        title_id: fileSection.title_id,
        selectedRowCount: 0,
      });
    });
    setAllSelectedRows(initialSelectedRows);
    // eslint-disable-next-line
  }, [uploadReportFilesData]);

  /**
   * Handles the removal of an input file.
   *
   * @param {string} file_s3_url - The S3 URL of the file.
   * @param {string} file_name - The name of the file.
   * @param {string} file_id - The ID of the file.
   * @param {string} title_id - The title ID associated with the file.
   * @param {function} deleteInputFileById - The RTK Query mutation function to delete the file.
   * @returns {Promise<void>}
   */
  const handleReportDataFileRemove = async (
    file_s3_url,
    file_name,
    file_id,
    title_id,
  ) => {
    const clientInputDataCopy = JSON.parse(JSON.stringify(clientInputData));
    const updatedClientInputData = updateInputDataAfterFileDelete(
      clientInputDataCopy,
      file_s3_url,
      title_id,
    );
    setClientInputData(updatedClientInputData);

    try {
      const response = await deleteInputFileById(file_id).unwrap();
      if (response) {
        console.log('File deleted successfully!');
      }
    } catch (error) {
      console.error('Failed to delete the file:', error);
      setClientInputData(clientInputDataCopy);
    }
  };

  /**
   * Handles the selection of rows for file sections and updates the total selected row count.
   *
   * @param {Array} updatedRowsChecked - The array of updated rows with checked status.
   * @param {string} title_id - The title ID associated with the file section.
   */
  const handleTotalSelectedRows = (updatedRowsChecked, title_id) => {
    let isTextProcessingReady = false;
    const updatedSelectedRows = allSelectedRows;
    const selectedRows = updatedRowsChecked.filter(
      (rowDetails) => rowDetails.isChecked,
    );

    setSelectedFilesForModel((selected_files_forModel) => ({
      ...selected_files_forModel,
      [title_id]: selectedRows,
    }));

    updatedSelectedRows.map((group) => {
      if (group.title_id === title_id) {
        group.selectedRowCount = selectedRows.length;
      }
      return group;
    });
    setAllSelectedRows(updatedSelectedRows);

    isTextProcessingReady = updatedSelectedRows.some(
      (group) => (group.title_id === 'focus_groups'
          || group.title_id === 'interviews')
        && group.selectedRowCount > 0,
    );
    if (isTextProcessingReady) {
      setTextProcessingReady(true);
    } else {
      setTextProcessingReady(false);
    }
  };

  /**
   * Initiates the text processing model and updates the unique models list with the new model.
   *
   * @returns {Promise<void>}
   */
  const handleTextProcessing = async () => {
    const currentTime = new Date(
      new Date().toLocaleString('en', { timeZone: 'america/new_york' }),
    );
    const currentTimeStamp = createTimeStamp(currentTime);
    const newModel = await runTextProcessingModel({
      selectedFilesForModel,
      client_id: selectedClientId,
      model_name: modelName.trim() || `Model_${currentTimeStamp}`,
    });
    if (newModel) {
      setUniqueModels((unique_models) => [...unique_models, newModel.data]);
    }
  };

  const {
    data: clientsData,
    isError,
    error,
    isFetching,
  } = useGetAllClientsQuery();

  if (isFetching) {
    return <CircularProgress style={{ margin: '20% 50%' }} />;
  }
  if (isError) {
    return <Box>{error.message}</Box>;
  }

  return (
    <Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '60px',
          margin: '2.5rem 5rem',
          alignItems: 'center',
        }}
      >
        {selectedClientId ? (
          <EditableClientName client_id={client_id} client_data={clientData} />
        ) : (
          <Typography variant="h4" fontWeight="bold">
            Select a Client
          </Typography>
        )}
        <ClientSelector
          clientLists={clientsData?.data}
          client_data={clientData}
          onClientChange={handleClientChange}
        />
      </Box>
      {isFetchingReports ? (
        <CircularProgress style={{ margin: '10% 50%' }} />
      ) : selectedClientId ? (
        <Box>
          <DisplayModels
            uniqueModels={uniqueModels}
            setUniqueModels={setUniqueModels}
            clientReports={clientReports}
            setClientReports={setClientReports}
            client_id={client_id}
            fetchingReport={fetchingReport}
          />
          <Box
            style={{
              minHeight: '467px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              margin: '0 5rem 2.75rem 5rem',
              backgroundColor: 'white',
              boxShadow: '0px 3px 6px #00000029',
              padding: '40px',
              boxSizing: 'border-box',
            }}
          >
            {uploadReportFilesData?.map((fileCopy, index) => {
              const { title_id } = fileCopy;
              const data = clientInputData && clientInputData[title_id];
              const uploadInProgress = data?.filter((file) => file.file_status === UPLOADING) || [];

              return (
                <div key={title_id} style={{ marginTop: index !== 0 ? '40px' : '0' }}>
                  <UploadFileToS3
                    clientData={clientData}
                    setClientData={setClientData}
                    clientInputData={clientInputData}
                    setClientInputData={setClientInputData}
                    clientId={client_id}
                    fileCopy={fileCopy}
                    disabled={uploadInProgress.length > 0 || fetchingReport}
                  />
                  {fetchingReport && (
                    <CircularProgress
                      style={{ margin: ' 1rem 50%' }}
                      disableShrink
                    />
                  )}
                  {clientData && !fetchingReport && (
                    <DataDisplayWithCheckbox
                      data={{ files: data }}
                      titleId={title_id}
                      rowSelectedStatus={handleTotalSelectedRows}
                      actions={[
                        {
                          actionType: 'remove',
                          callBackFunction:
                          (file_s3_url, file_name, file_id) => handleReportDataFileRemove(
                            file_s3_url,
                            file_name,
                            file_id,
                            title_id,
                          ),
                        },
                      ]}
                    />
                  )}
                </div>
              );
            })}
            <ModelProcessingSection
              textProcessingReady={textProcessingReady}
              handleTextProcessing={handleTextProcessing}
              modelName={modelName}
              setModelName={setModelName}
              modelNames={modelNames}
            />
          </Box>
        </Box>
      ) : (
        <Typography
          variant="body1"
          sx={{ textAlign: 'center', marginTop: '4rem', fontSize: '1.25rem' }}
        >
          No client is selected. Choose one above, or on the
          {' '}
          <Link
            href="/dashboard"
            sx={{
              color: '#3988C6',
              fontWeight: '700',
              textDecorationColor: '#3988C6',
            }}
          >
            Client Page.
          </Link>
        </Typography>
      )}
    </Box>
  );
};

export default ReportData;
