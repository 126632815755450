const customGeneralConfig = {
  bar: [
    { type: 'checkbox', label: 'Show Bar Label', key: 'showBarLabel' },
    { type: 'checkbox', label: 'Show Y-Grid', key: 'showGridY' },
    { type: 'checkbox', label: 'Show X-Grid', key: 'showGridX' },
    {
      type: 'select',
      label: 'Graph Direction',
      key: 'graphDirection',
      options: [
        { value: 'horizontal', label: 'Horizontal' },
        { value: 'vertical', label: 'Vertical' },
      ],
    },
  ],
  pie: [
    { type: 'number', label: 'Inner Radius', key: 'innerRadius' },
    { type: 'number', label: 'Outer Radius', key: 'outerRadius' },
    { type: 'checkbox', label: 'Show Values', key: 'showValues' },
    { type: 'checkbox', label: 'Sort by Value', key: 'sortByValue' },
  ],
  stacked: [
    { type: 'checkbox', label: 'Show Bar Label', key: 'showBarLabel' },
    { type: 'checkbox', label: 'Show Totals', key: 'showTotals' },
    { type: 'checkbox', label: 'Show Y-Grid', key: 'showGridY' },
    { type: 'checkbox', label: 'Show X-Grid', key: 'showGridX' },
    {
      type: 'select',
      label: 'Graph Direction',
      key: 'graphDirection',
      options: [
        { value: 'horizontal', label: 'Horizontal' },
        { value: 'vertical', label: 'Vertical' },
      ],
    },
  ],
  heatmap: [
    { type: 'checkbox', label: 'Show Labels', key: 'enableLabels' },
  ],
  grouped: [
    { type: 'checkbox', label: 'Show Bar Label', key: 'showBarLabel' },
    { type: 'checkbox', label: 'Show Y-Grid', key: 'showGridY' },
    { type: 'checkbox', label: 'Show X-Grid', key: 'showGridX' },
    { type: 'checkbox', label: 'Show Totals', key: 'showTotals' },
    {
      type: 'select',
      label: 'Graph Direction',
      key: 'graphDirection',
      options: [
        { value: 'horizontal', label: 'Horizontal' },
        { value: 'vertical', label: 'Vertical' },
      ],
    },
  ],
};

export default customGeneralConfig;
