import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

const DynamicTable = ({ chartData, customStyles = {}, custom_table_data }) => {
  const data = custom_table_data || chartData?.data;
  const [rows, setRows] = useState(data);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  // Update rows when chartData.data changes
  useEffect(() => {
    setRows(data);
  }, [data]);

  // Dynamically extract headers
  const headers = data?.length > 0 ? Object.keys(data[0]) : [];

  // Sorting function
  const handleSort = (key) => {
    const isAsc = sortConfig.key === key && sortConfig.direction === 'asc';
    const direction = isAsc ? 'desc' : 'asc';
    setSortConfig({ key, direction });

    const sortedRows = [...rows].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setRows(sortedRows);
  };

  return (
    <TableContainer
      component="div"
      sx={{
        height: 'auto',
        maxHeight: 380,
        overflowX: 'auto',
        overflowY: 'auto',
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
        borderRadius: '4px',
        ...customStyles,
      }}
    >
      <Table
        stickyHeader={false}
        sx={{
          tableLayout: 'fixed',
          width: '100%',
          fontSize: '0.6rem',
        }}
      >
        <TableHead>
          <TableRow>
            {/* Dynamic Headers */}
            {headers.map((header) => (
              <TableCell
                key={header}
                align="center"
                sx={{
                  fontSize: '0.5rem',
                  lineHeight: '1.1',
                  paddingLeft: 0,
                  paddingRight: 0,
                  alignItems: 'center',
                }}
              >
                <TableSortLabel
                  active={sortConfig.key === header}
                  direction={sortConfig.key === header ? sortConfig.direction : 'asc'}
                  onClick={() => handleSort(header)}
                >
                  {header.replace(/_/g, ' ')}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow key={row.user_id}>
              {/* Dynamic Cells */}
              {headers.map((header) => (
                <TableCell key={header} align="center" sx={{ fontSize: '0.8rem' }}>
                  {row[header]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DynamicTable;
