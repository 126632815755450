import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useGetReportsByClientIdQuery } from '../../services/api/reportsApi';
import {
  FAILED,
  IN_PROCESSING,
  PROCESSING_SUCCESSFULL,
  MODEL_STATUS_MAP_OBJ,
} from '../../data/const';
import PaginatedAnalysis from './PaginatedAnalysis';

const DisplayModels = ({
  uniqueModels,
  setUniqueModels,
  clientReports,
  setClientReports,
  client_id,
  fetchingReport,
}) => {
  const model_status_arr = [IN_PROCESSING, PROCESSING_SUCCESSFULL, FAILED];

  // Fetch reports using the RTK Query hook
  const { data: reports, isFetching } = useGetReportsByClientIdQuery(client_id);

  // Update uniqueModels whenever reports change
  useEffect(() => {
    if (reports?.length > 0) {
      const tempUniqueModels = [];
      reports.forEach((report) => {
        const alreadyPresent = tempUniqueModels.findIndex(
          (model) => model?.data?.model_id === report?.data?.model_id,
        );
        if (alreadyPresent === -1) {
          tempUniqueModels.push(report);
        }
      });
      setUniqueModels(tempUniqueModels);
    }
  }, [reports, setUniqueModels]);

  // Check for models with IN_PROCESSING status and refetch if needed
  useEffect(() => {
    const updateRequired = uniqueModels?.some(
      (clientReport) => clientReport?.data?.model_status === IN_PROCESSING,
    );

    if (updateRequired && !isFetching) {
      // Refetch is handled implicitly by RTK Query, no manual fetch needed
    }
  }, [uniqueModels, isFetching]);

  return (
    <Box
      style={{
        minHeight: '149px',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 80px 2.5rem 80px',
        backgroundColor: 'white',
        boxShadow: '0px 3px 6px #00000029',
        padding: '40px',
      }}
    >
      <Typography
        style={{ marginBottom: '1rem', fontWeight: 'bold', fontSize: '1.5rem' }}
      >
        Analyses
      </Typography>

      {(fetchingReport || isFetching) && <CircularProgress sx={{ margin: '1rem auto' }} />}

      {!fetchingReport && !isFetching && uniqueModels?.length === 0 && (
        <Typography
          style={{
            font: 'normal normal normal 16px/32px',
            letterSpacing: '0px',
            color: '#262626',
            opacity: 0.72,
            marginTop: '12px',
          }}
        >
          No Models
        </Typography>
      )}

      {!fetchingReport && !isFetching && uniqueModels?.length > 0 && (
        <PaginatedAnalysis
          uniqueModels={uniqueModels}
          model_status_arr={model_status_arr}
          MODEL_STATUS_MAP_OBJ={MODEL_STATUS_MAP_OBJ}
          PROCESSING_SUCCESSFULL={PROCESSING_SUCCESSFULL}
          FAILED={FAILED}
          setUniqueModels={setUniqueModels}
          setClientReports={setClientReports}
          clientReports={clientReports}
        />
      )}
    </Box>
  );
};

export default DisplayModels;
