import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  TextField,
  Typography,
  useTheme,
  Paper,
} from '@mui/material';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { object, string } from 'yup';
import CustomButton from '../components/button/CustomButton';
import UserPool from './UserPool';
import HorizontalDivider from '../components/layout-design/HorizontalDivider';
import Footer from '../components/layout-design/Footer';
import CustomTooltip from '../components/customMuiComponent/CustomTooltip';
import { CheckCircleOutlineIcon, InfoIcon } from '../icons';

const emailValidationSchema = object({
  email: string().email().required('Please enter a valid email address'),
});

const passwordValidationSchema = object({
  newPassword: string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Not a valid password, see the criteria on the right icon',
    ),
});

const forgotPasswordSteps = [
  {
    step: 'send_verification_code',
    title: 'Reset Password.',
    desc: 'Enter the email address associated with your account.',
    buttonText: 'Send Verification Code',
  },
  {
    step: 'create_password',
    title: 'Create New Password',
    desc: 'Enter the Verification Code we just sent you on your email address.',
    buttonText: 'Submit',
  },
  {
    step: 'password_reset_success',
    title: 'Your password has been reset successfully!',
    desc: 'Page will be redirected to the login page soon!',
  },
];

const ForgotPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordUpdateError, setPasswordUpdateError] = useState({
    isError: false,
    errorMessage: '',
  });
  const [inputError, setInputError] = useState({});
  const [currentStep, setCurrentStep] = useState('send_verification_code');

  useEffect(() => {
    if (passwordUpdateError.isError) {
      setTimeout(() => {
        setPasswordUpdateError({ isError: false, errorMessage: '' });
      }, 5000);
    }
  }, [passwordUpdateError]);

  const handleEmailValidation = async (value) => {
    try {
      await emailValidationSchema.validate({ email: value });
      setInputError((prev) => ({ ...prev, email: '' }));
    } catch {
      setInputError((prev) => ({
        ...prev,
        email: 'Please enter a valid email address',
      }));
    }
  };

  const handlePasswordValidation = async (value) => {
    try {
      await passwordValidationSchema.validate({ newPassword: value });
      setInputError((prev) => ({ ...prev, newPassword: '' }));
    } catch (error) {
      setInputError((prev) => ({ ...prev, newPassword: error.message }));
    }
  };

  const handlePasswordChange = () => {
    const cognitoUser = new CognitoUser({
      Username: email.trim(),
      Pool: UserPool,
    });

    if (currentStep === 'send_verification_code') {
      cognitoUser.forgotPassword({
        onSuccess: () => setCurrentStep('create_password'),
        onFailure: () => {
          setPasswordUpdateError({
            isError: true,
            errorMessage: 'This email id does not exist',
          });
        },
      });
    } else if (currentStep === 'create_password') {
      const isPasswordValid = inputError.newPassword === ''
        && confirmNewPassword === newPassword
        && verificationCode.trim().length > 0;

      if (isPasswordValid) {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
          onSuccess: () => {
            setCurrentStep('password_reset_success');
            setTimeout(() => navigate('/login'), 3000);
          },
          onFailure: (err) => {
            setPasswordUpdateError({
              isError: true,
              errorMessage: err.message,
            });
          },
        });
      } else {
        if (confirmNewPassword !== newPassword) {
          setInputError((prev) => ({
            ...prev,
            confirmNewPassword: 'Password do not match',
          }));
        }
        if (verificationCode.trim().length === 0) {
          setInputError((prev) => ({
            ...prev,
            verificationCode: 'Please enter verification code',
          }));
        }
      }
    }
  };

  const currentStepDetails = forgotPasswordSteps.find(
    (step) => step.step === currentStep,
  );

  return (
    <Box
      style={{
        minHeight: '320px',
        width: '420px',
        margin: 'auto',
        marginTop: currentStep === 'create_password' ? '5vh' : '15vh',
        marginBottom: '10vh',
        backgroundColor: 'white',
        boxShadow: '0px 3px 6px #00000029',
        padding: '80px 40px 20px 40px',
        boxSizing: 'border-box',
        position: 'relative',
      }}
    >
      <Grid container>
        {passwordUpdateError.isError && (
        <Paper
          elevation={0}
          style={{
            padding: '5px 10px',
            width: '330px',
            color: '#5F2120',
            backgroundColor: '#FCEDEC',
            position: 'absolute',
            top: 15,
          }}
        >
          {passwordUpdateError.errorMessage}
        </Paper>
        )}

        {currentStep === 'password_reset_success' && (
        <CheckCircleOutlineIcon
          color="success"
          style={{ fontSize: '4rem', margin: '-3rem auto 1rem auto' }}
        />
        )}

        <Grid item>
          <Typography
            variant="h2"
            color={theme.palette.primary.main}
            style={{ fontWeight: 'bold', fontSize: '24px' }}
          >
            {currentStepDetails.title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            color={theme.palette.primary.main}
            style={{ fontSize: '1rem', marginTop: '0.5rem' }}
          >
            {currentStepDetails.desc}
          </Typography>
        </Grid>

        {currentStep === 'send_verification_code' && (
        <TextField
          placeholder="Enter Email"
          onChange={(e) => {
            setEmail(e.target.value);
            handleEmailValidation(e.target.value);
          }}
          value={email}
          style={{ height: '3.5rem', width: '20.5rem', marginTop: '0.75rem' }}
          error={!!inputError.email}
          helperText={inputError.email}
        />
        )}

        {currentStep === 'create_password' && (
        <>
          <TextField
            placeholder="Verification Code"
            onChange={(e) => {
              setVerificationCode(e.target.value);
              setInputError((prev) => ({
                ...prev,
                verificationCode: e.target.value.trim() ? '' : 'Please enter verification code',
              }));
            }}
            value={verificationCode}
            style={{ height: '3.5rem', width: '20.5rem', marginTop: '1rem' }}
            error={!!inputError.verificationCode}
            helperText={inputError.verificationCode}
          />
          <TextField
            placeholder="Enter New Password"
            onChange={(e) => {
              setNewPassword(e.target.value);
              handlePasswordValidation(e.target.value);
            }}
            value={newPassword}
            type="password"
            style={{ height: '3.5rem', width: '20.5rem', marginTop: '2rem' }}
            error={!!inputError.newPassword}
            helperText={inputError.newPassword}
          />
          <CustomTooltip
            title="Password Must Contain"
            message={[
              'One Uppercase',
              'One Lowercase',
              'One Number',
              'One Special Character',
              '8 Characters',
            ]}
            placement="left-start"
          >
            <InfoIcon
              style={{
                position: 'absolute',
                right: 20,
                bottom: 210,
                color: 'gray',
              }}
            />
          </CustomTooltip>
          <TextField
            placeholder="Re-enter New Password"
            onChange={(e) => {
              setConfirmNewPassword(e.target.value);
              setInputError((prev) => ({
                ...prev,
                confirmNewPassword: e.target.value === newPassword ? '' : 'Passwords do not match',
              }));
            }}
            value={confirmNewPassword}
            type="password"
            style={{ height: '3.5rem', width: '20.5rem', marginTop: '2rem' }}
            error={!!inputError.confirmNewPassword}
            helperText={inputError.confirmNewPassword}
          />
        </>
        )}

        {currentStep !== 'password_reset_success' && (
        <Grid
          item
          container
          style={{
            marginTop: '2rem',
            width: '20.5rem',
            marginBottom: '1rem',
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <CustomButton
              buttonText={currentStepDetails.buttonText}
              onClickHandler={handlePasswordChange}
            />
          </Grid>
        </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
